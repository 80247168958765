import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Switch,
    TextField,
    Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Strings from "../../assets/strings";
import { LoadingProgress } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { agregarEditarArticulo, eliminarArticulos, mostrarArticulosById } from "../../redux/reducers/articulosReducers";
import { IArticulo } from "../../redux/types/IArticulo";

import ConfirmDialog from "../../components/ConfirmDialog";
import { hasPermissionTo, nullToEmptyString, PERSMISOS } from '../../helpers';
import { TextValidatorCustom, ValidatorFormCustom } from "../../helpers/form-validator";
import TopAppBar from "../../components/TopAppBar";
import SelectSearchMarca from "../../components/selects/SelectSearchMarca";
import SelectSearchCategoria from "../../components/selects/SelectSearchCategoria";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('lg')]: {
            "& img":{
                borderRadius: "50%",
            }
        }

    },
    submitBtn:{
        margin: 20,
    },
}));
let initialState:IArticulo = {
    id: 0,
    artCodigo: "",
    artDescripcion:"",
    artNombre: "",
    marcaId:undefined,
    marca:"",
    artIndicadorDesc:false,
    artIndicadorPushmoney:false,
    artInactivo: false,
    categoriaId: undefined,
    artCodigoBarra:"",
    artPrecio:0,
    artPuntos:0,
}
function CrearArticulo(){
    const classes = useStyles();
    const navigate = useNavigate();
    let { id:urlCliId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();



    const Dispatch = useAppDispatch();
    const {byId:articulo,status} = useAppSelector(state => state.articulos)

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlCliId) Dispatch(mostrarArticulosById(parseInt(urlCliId)));
    },[urlCliId,Dispatch])

    useEffect(()=>{
        if(!!urlCliId && articulo?.id === parseInt(urlCliId)) {
            setState({...initialState, ...nullToEmptyString(articulo)});
        }else{
            setState(initialState)
        }
    },[urlCliId,articulo])



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value,type} = e.target
        return setState({...state,[name]: type==="number"?parseInt(value):value});
    }

    const submit = () =>{
       // e.preventDefault();
        let obj = {...state}
        //si en la misma imagen no enviar nada
        //if(obj.artImagen === articulo?.artImagen ) obj.artImagen = "";

        Dispatch(agregarEditarArticulo(obj));
    }

    return(
        <Fragment>
            <TopAppBar/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {(status === "loading") && <LoadingProgress vh/>}
                    <ValidatorFormCustom id="form1" onSubmit={submit}
                                   //instantValidate={instantValidate}
                                   debounceTime={1500}
                                 //  onError={()=>setInstantValidate(true)}
                    >
                        <Paper className={classes.paper}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={2}>

                                            <TextValidatorCustom
                                                name="artCodigo"
                                                label={Strings.codigo}
                                                value={state.artCodigo}
                                                onChange={handleChange}
                                                fullWidth
                                                validators={['required']}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                name={"artNombre"}
                                                label={Strings.nombre}
                                                value={state.artNombre}
                                                onChange={handleChange}
                                                inputProps={{maxLength: 50}}
                                                required
                                            //    validators={['required']}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <SelectSearchCategoria
                                                value={{
                                                    categoria:state.categoria??"",
                                                    categoriaId: (state.categoriaId ?? "").toString(),
                                                }}
                                                onChange={(v)=>setState({
                                                    ...state,
                                                    categoriaId:v.categoriaId?parseInt(v.categoriaId):undefined,
                                                    categoria: v.categoria
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <SelectSearchMarca
                                                value={{
                                                    marca:state.marca??"",
                                                    marcaId: (state.marcaId ?? "").toString(),
                                                }}
                                                onChange={(v)=>setState({
                                                    ...state,
                                                    marcaId:v.marcaId? parseInt(v.marcaId):undefined,
                                                    marca: v.marca
                                                })}
                                            />

                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <TextValidatorCustom
                                                name="artPrecio"
                                                label={Strings.precio}
                                                value={state.artPrecio}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <TextValidatorCustom
                                                name="artCodigoBarra"
                                                label={Strings.codigo+" de barra"}
                                                value={state.artCodigoBarra}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <TextValidatorCustom
                                                type={"number"}
                                                name="artPuntos"
                                                label={Strings.puntos}
                                                value={state.artPuntos}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name={"artDescripcion"}
                                                label={Strings.descripcion}
                                                value={state.artDescripcion}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={state.artIndicadorDesc}
                                                                                   color={"secondary"}
                                                                                   onChange={({target})=>setState({...state, artIndicadorDesc:target.checked})}
                                                />}
                                                                  labelPlacement="end" label={"Descuento"}/>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={state.artIndicadorPushmoney}
                                                                                   color={"secondary"}
                                                                                   onChange={({target})=>setState({...state, artIndicadorPushmoney:target.checked})}
                                                />}
                                                                  labelPlacement="end" label={"Push Money"}/>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={state.artInactivo} color={"secondary"}
                                                                                   onChange={({target})=>setState({...state, artInactivo:target.checked})} />}
                                                                  labelPlacement="end" label={Strings.inactivo}/>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            {!!state.id &&
                                <Button onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.articulosActualizar)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                        color="error" sx={{marginRight:"auto"}}>{Strings.eliminar}</Button>}
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{!!state.id ? Strings.actualizar : Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>
                    </ValidatorFormCustom>
                </div>
            </div>
            {!!urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarArticulos(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}

export default CrearArticulo;
