import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getVentas, addEditVenta, getVentaById, delVenta, confirmVenta} from '../../services';
import {IVentaState, IVenta, IVentaRes} from "../types/IVenta";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
import {VENTA_ESTADO} from "../../helpers/CONSTANTES";
import {AppState} from "./rootReducer";
const initialState: IVentaState = {
    status: "idle"
}

export const mostrarVentas = createAsyncThunk(
    'mostrarVentas', async (params:object|undefined,thunkAPI ) => {
        const {ventas} = thunkAPI.getState() as AppState;
        let paramsFilter = params || ventas.filtered;
        const response = await getVentas(paramsFilter);
        return {data: response.data as IVentaRes, params: response.config.params}


    }
)
export const mostrarVentaById = createAsyncThunk(
    'mostrarVentaById', async (id:number ) => {

        const response = await getVentaById(id)
        return response.data as IVenta
    }
)

export const agregarEditarVenta = createAsyncThunk(
    'Ventas/agregarEditar', async (venta: IVenta) => {
        const response = await addEditVenta(venta);

        if (response.status === 200) {
            if(!venta.id){
                store.dispatch(Notifications.success({
                    title: 'Solicitud enviada con exito.',
                    message:"Su solicitud No. ("+response.data.id+") ha sido enviada de forma satisfactoria y está pendiente de verificación para fines de aprobación.", autoDismiss: 12,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!venta.id};
    }
);
export const eliminarVenta = createAsyncThunk(
    'venta/eliminar', async ({id, motivo}:{id: number, motivo: string}) => {
        let response = await delVenta(id,motivo);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {id, motivo};
    }
);
export const confirmarVenta = createAsyncThunk(
    'venta/confirmar', async (id: number) => {
        await confirmVenta(id);
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarVentas',
    initialState,
    reducers: {
        updateVentaRow: (state,{payload}) => {
            let data = payload as IVenta;
            if(state.list?.results){
                state.list.results = state.list.results.map((com)=>{
                    if(com.id === data.id) return {...com,...data};

                    return com;
                })
            }
        },
    },
    extraReducers: builder => {
        //--mostrarVentas
        builder.addCase(mostrarVentas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarVentas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarVentas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarVenta
        builder.addCase(agregarEditarVenta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarVenta.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarVenta.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarVentaById
        builder.addCase(mostrarVentaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarVentaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarVentaById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarVenta
        builder.addCase(eliminarVenta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarVenta.fulfilled, (state,action ) => {
            state.status = "idle";
            const {id, motivo} = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results?.map(r=>{
                    if(r.id === id){
                        return {...r, venEstado:2,venNota:motivo }
                    }
                    return r
                })
            }
            if(state.byId){
                state.byId = {...state.byId, venEstado:2,venNota:motivo }
            }
        })
        builder.addCase(eliminarVenta.rejected, (state ) => {
            state.status = "idle";
        })

        //--confirmnar venta
        builder.addCase(confirmarVenta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(confirmarVenta.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.byId?.id){
                state.byId = {...state.byId, venEstado:VENTA_ESTADO.confirmada, venEstadoString: "Confirmada"}
            }
            if(state.list?.results){
                state.list.results = state.list?.results?.map(r=>{
                    if(r.id === id){
                        return {...r, venEstado:VENTA_ESTADO.confirmada, venEstadoString: "Confirmada"}
                    }
                    return r
                })
            }
        })
        builder.addCase(confirmarVenta.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export const { updateVentaRow } = slice.actions;

export default slice.reducer;
