import React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import Strings from "../../assets/strings";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Box from "@mui/material/Box";
import {IEntrega} from "../../redux/types/IEntrega";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch} from "../../redux/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import VisitaDetalles from "./VisitaDetalles";
import ConfirmDialog from "../../components/ConfirmDialog";
import {eliminarEntrega} from "../../redux/reducers/entregasReducers";

type Iprops = {
  entrega?:IEntrega,
  onClose:()=>void,
}

export default  function EntregaDetalles({entrega, onClose}:Iprops){
  const Dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {pathname, state: urlState}:{state:any,pathname:string}  = useLocation();

  return <>
    <Dialog maxWidth="xs" open={!!entrega?.id} onClose={onClose} >
      <TopBarDialog title={Strings.detalles} onClose={onClose} nofullScreen/>

      <DialogContent>
        <table className={"noHover"}>
          <tbody>
          <tr>
            <td><Box fontWeight={500}>Fecha</Box></td>
            <td>: {formatDate(entrega?.fechaCreacion,"DD/MM/YYYY h:m A")}</td>
          </tr>
          <tr>
            <td width={"128px"}><Box fontWeight={500}>Médico</Box></td>
            <td>: {entrega?.medico}</td>
          </tr>
          <tr>
            <td width={"128px"}><Box fontWeight={500}>Producto</Box></td>
            <td>: {entrega?.articulo}</td>
          </tr>
          <tr>
            <td><Box fontWeight={500}>Cantidad</Box></td>
            <td>: {entrega?.cantidad}</td>
          </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        {hasPermissionTo(PERSMISOS.entregasEliminar) &&
          <Button onClick={()=>{
            navigate(pathname, {state: {...urlState, delEntrega:entrega?.id}})
          }} sx={{color:"#f84f4f"}}>{Strings.eliminar}</Button>
        }

        <Button onClick={()=>{
          navigate(pathname, {state: {...urlState, visitaDetalles: {id: entrega?.visitaId}}})
        }} color="primary">Ver Visita</Button>

      </DialogActions>
    </Dialog>

    <VisitaDetalles/>

    {!!urlState?.delEntrega &&
        <ConfirmDialog onConfirm={() => {
          onClose();
          Dispatch(eliminarEntrega(urlState.delEntrega))
        }} />
    }
  </>;
}
