import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getNotificaciones, marcarNotLeida} from '../../services';
import {INotificacionState, INotificacion} from "../types/INotificacion";
const initialState: INotificacionState = {
    status: "idle"
}

export const mostrarNotificaciones = createAsyncThunk(
    'mostrarNotificaciones', async (params:object|undefined ) => {
        const response = await getNotificaciones(params)
        return {data: response.data as INotificacion[], params: response.config.params}
    }
)

export const marcarNotificacionLeida = createAsyncThunk(
    'Notificaciones/leida', async (id: number) => {
        await marcarNotLeida(id);
        return {id};
    }
);

const slice = createSlice({
    name: 'notificaciones',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarNotificaciones
        builder.addCase(mostrarNotificaciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarNotificaciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarNotificaciones.rejected, (state ) => {
            state.status = "idle";
        })

        //--marcarNotificacionLeida
        builder.addCase(marcarNotificacionLeida.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(marcarNotificacionLeida.fulfilled, (state,action ) => {
            state.status = "idle";
            state.list = state.list?.filter(n=>n.id!==action.payload.id)
            // state.list = state.list?.map(notificacion=>{
            //     if(notificacion.id === action.payload.id){
            //         return {...notificacion, notLeida:true}
            //     }
            //     return notificacion
            // });
        })
        builder.addCase(marcarNotificacionLeida.rejected, (state ) => {
            state.status = "idle";
        })
    }
});


export default slice.reducer;
