import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getVisitas, addEditVisita, getVisitaById, delVisita} from '../../services';
import {IVisitaState, IVisita, IVisitaRes} from "../types/IVisita";
import historyHelper from "../../helpers/history";
const initialState: IVisitaState = {
    status: "idle"
}

export const mostrarVisitas = createAsyncThunk(
    'mostrarVisitas', async (params:object|undefined ) => {
        const response = await getVisitas(params)
        return {data: response.data as IVisitaRes, params: response.config.params}
    }
)
export const mostrarVisitaById = createAsyncThunk(
    'mostrarVisitaById', async (id:number ) => {

        const response = await getVisitaById(id)
        return response.data as IVisita
    }
)

export const agregarEditarVisita = createAsyncThunk(
    'Visitas/agregarEditar', async (Visita: IVisita, thunkAPI) => {
        const response = await addEditVisita(Visita);

        if (response.status === 200) {
            historyHelper.back();
        }

        return {data: response.data, edit:!!Visita.id};
    }
);
export const eliminarVisita = createAsyncThunk(
    'visita/eliminar', async (id: number) => {
        const response = await delVisita(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarVisitas',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarVisitas
        builder.addCase(mostrarVisitas.pending, (state ) => {
            state.status = "loading";
            state.byId = undefined;
        })
        builder.addCase(mostrarVisitas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarVisitas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarVisita
        builder.addCase(agregarEditarVisita.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarVisita.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarVisita.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarVisitaById
        builder.addCase(mostrarVisitaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarVisitaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarVisitaById.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarVisita
        builder.addCase(eliminarVisita.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarVisita.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarVisita.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
