import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {Dialog, DialogActions, DialogContent, Button, TextField} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingProgress from "../../components/LoadingProgress";
import {MostrarError} from "../../components/MostrarError";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Strings from "../../assets/strings";
import {SuccessMsj} from "../../components/transitions";
import Box from "@mui/material/Box";
import {changePassword} from "../../services";
const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "none",
        [theme.breakpoints.up('sm')]: {
            maxWidth:510,
            margin:"0px auto",
        },
    },
    content:{
        padding:theme.spacing(2),
    }
}));

const initialState = {
    usuName:"",
    usuClave: "",
    usuClaveNueva: ""
};
function ChangePassword() {
    const navigate = useNavigate();
    const {state:urlState={}} = useLocation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);


    const [open, setOpen] = useState(false);
    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlState && urlState.modalChangePassword) {
            setState({...initialState,usuName:urlState.modalChangePassword} );
            setOpen(true);
        }else{
            setState(initialState);
            setOpen(false);
        }
    },[urlState,setState])
    const handleChange = e => {
        const {name,value} = e.target;
        setState({...state,  [name]: value });
    };

    const onSubmit = e =>{
        e.preventDefault()
        setError(false)
        setLoading(true)
        changePassword(state).then(()=>{
            setLoading(false)
            setSuccess(true)
            setTimeout(()=>{
                navigate(-1)
            }, 3000);
        }).catch(e=>{
            setError(e.response)
            setLoading(false)
        })
    }
    const validatePassword = ()=>{
        let confirm_password = document.getElementById("confirm_password");
        if(state.usuClaveNueva !== confirm_password.value) {
            confirm_password.setCustomValidity(Strings.passNoMatch);
        } else {
            confirm_password.setCustomValidity('');
        }
    }
    return (
        <Dialog className={classes.root} open={open} onClose={()=>navigate(-1)} fullWidth maxWidth={"sm"}>

            {success ?(
                <Box p={3}>
                  <SuccessMsj/>
                </Box>
            ):(
                <>

                    <TopBarDialog title={Strings.cambiar+" "+Strings.contrasena} onClose={()=>navigate(-1)} nofullScreen/>
                    <DialogContent className={classes.content}>
                        <MostrarError errors={error}/>
                        {loading && <LoadingProgress />}
                        <form onSubmit={onSubmit} id="formChangePass" autoComplete="off">
                            <TextField
                                label={Strings.nombreDeUsuario}
                                defaultValue={state.usuName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                label={Strings.contrasenaActual}
                                required
                                name="usuClave"
                                type="password"
                                value={state.usuClave}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                                margin={"normal"}
                            />
                            <TextField
                                name="usuClaveNueva"
                                label={Strings.contrasenaNueva}
                                required
                                type="password"
                                value={state.usuClaveNueva}
                                fullWidth
                                onChange={handleChange}
                                margin={"normal"}
                            />
                            <TextField
                                label={Strings.confirmar+" "+Strings.contrasena}
                                required
                                defaultValue={""}
                                type="password"
                                id="confirm_password"
                                fullWidth
                                onBlur={validatePassword}
                                margin={"normal"}
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>navigate(-1)}>
                            {Strings.cancelar}
                        </Button>
                        <Button  type="submit" color="primary" form="formChangePass">
                            {Strings.guardar}
                        </Button>
                    </DialogActions>
                </>
            )}

        </Dialog>
    );
}
export default ChangePassword



