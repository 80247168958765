import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { ITheme } from '../types/ITheme';

const initialState: ITheme = {
    drawerOpen: false,
    viewTableList: false,
    autorizacionNumber: 0,
    installBannerEvent:null
}
const slice = createSlice({
    name: 'themeSetting',
    initialState,
    reducers: {
        toggleDrawer(state) {
            state.drawerOpen = !state.drawerOpen;
        },
        setAutorizacionNumber(state: ITheme, action: PayloadAction<number>) {
            state.autorizacionNumber = action.payload;
        },
        toggleViewTable(state: ITheme) {
            state.viewTableList = !state.viewTableList;
        },
        setInstallBannerEvent(state: ITheme,action: PayloadAction<any>) {
            state.installBannerEvent = action.payload;
        }
    },

});

export const {toggleDrawer,setAutorizacionNumber,toggleViewTable,setInstallBannerEvent} = slice.actions;
export default slice.reducer;
