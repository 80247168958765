import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getUsuarios, addEditUsuario, getUsuarioById, delUsuario,editPWUsuario,lockToggleUsuario} from '../../services';
import historyHelper from "../../helpers/history";
import {IUsuarioState, IUsuario, IUsuarioRes} from "../types/IUsuarios";
const initialState: IUsuarioState = {
    status: "idle"
}

export const mostrarUsuarios = createAsyncThunk(
    'mostrarUsuarios',
    async (params:object|undefined) => {
        const response = await getUsuarios(params)
        return {data: response.data as IUsuarioRes, params: response.config.params}
    }
)
export const mostrarUsuarioById = createAsyncThunk(
    'mostrarUsuarioById',
    async (id:number) => {
        const response = await getUsuarioById(id)
        return response.data as IUsuario
    }
)

export const agregarEditarUsuario = createAsyncThunk(
    'Usuarios/agregarEditar',
    async (Usuario: IUsuario) => {
        const response = await addEditUsuario(Usuario);
        if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, edit:!!Usuario.id};

    }
);

export const eliminarUsuario = createAsyncThunk(
    'usuarios/eliminar',
    async (id: number) => {
        await delUsuario(id);
        return id;
    }
);

export const toggleLock = createAsyncThunk(
    'usuarios/toggleLock',
    async (id: number) => {
        let response = await lockToggleUsuario(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();



        return id;
    }
);

export const editarContrasena = createAsyncThunk(
    'usuarios/editarContrasena',
    async (params: any) => {
        let response = await editPWUsuario(params);
        if (response.status === 200 || response.status === 204) historyHelper.back();

        return params;
    }
);
const slice = createSlice({
    name: 'mostrarUsuarios',
    initialState,
    reducers: {
        // mostrarBancas(state: IBancaState, {payload: key}: PayloadAction<string>) {
        //     state.isFetching = true;
        // }
    },
    extraReducers: builder => {
        //---mostrarUsuarios
        builder.addCase(mostrarUsuarios.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarUsuarios.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarUsuarios.rejected, (state ) => {
            state.status = "idle";
        })

        //---agregarEditarUsuario
        builder.addCase(agregarEditarUsuario.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarUsuario.fulfilled, (state,action ) => {
            state.status = "idle";

            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }
            }
        })
        builder.addCase(agregarEditarUsuario.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarUsuarioById
        builder.addCase(mostrarUsuarioById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarUsuarioById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarUsuarioById.rejected, (state ) => {
            state.status = "idle";
        })

        //---eliminarUsuario
        builder.addCase(eliminarUsuario.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarUsuario.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarUsuario.rejected, (state ) => {
            state.status = "idle";
        })

        //---toggleLock
        builder.addCase(toggleLock.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(toggleLock.fulfilled, (state,action ) => {
            const id = action.payload;
            if(!!state.list?.results){
                state.list.results = state.list.results.map(r=>{
                    if(r.id === id){
                        return {
                            ...r,
                            usuInactivo: !r.usuInactivo
                        }

                    }
                    return r;
                })
            }
            state.status = "idle";
        })
        builder.addCase(toggleLock.rejected, (state ) => {
            state.status = "idle";
        })

        //---editarContrasena
        builder.addCase(editarContrasena.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(editarContrasena.fulfilled, (state,action ) => {
            state.status = "idle";
        })
        builder.addCase(editarContrasena.rejected, (state ) => {
            state.status = "idle";
        })
    }

});


export default slice.reducer;
