import React, {useEffect, useState} from 'react';
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, Grid, Theme, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Strings from "../../assets/strings"
import {IAutorizacion} from "../../redux/types/IAutorizacion";
import {getCanjeById} from "../../services";
import {TIPO_AUTORIZACION} from "../../helpers/CONSTANTES";
import AutorizacionActions from "./AutorizacionActions";
import makeStyles from "@mui/styles/makeStyles";
import {ICanje} from "../../redux/types/ICanje";

const useStyles = makeStyles((theme:Theme) => ({
    dialogContent:{
        padding:theme.spacing(2),
        "& table td":{
            padding:theme.spacing(1)+" 0",
        }
    }
}));

const initialState = {
    id: undefined,
    autTipo: undefined,
    autTipoString: "",
    autTransacionId: undefined,

};

export default function AutorizacionDetalle() {
    const classes = useStyles();

    const {state:urlState}:any = useLocation();
    const autdetalle = urlState?.autdetalle as IAutorizacion;


    const navigate =useNavigate();
    const location =useLocation();

    const goBack = () => {
        navigate(-1)
    }
    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState("");

    const [state, setState] = useState<IAutorizacion>(initialState);
    const [canje, setCanje] = useState<ICanje>();

    useEffect(() => {
        (async ()=>{
            setError("")
            if(!!autdetalle?.id) {
                setState(state => ({...state, ...nullToEmptyString(autdetalle)}));
                setLoading(true)
                let response;
                try {
                    switch (autdetalle.autTipo) {
                        case TIPO_AUTORIZACION.canje:
                            response  = await getCanjeById(autdetalle.autTransacionId)
                            setCanje(response.data)
                            break
                        default:
                            response = undefined
                    }
                }catch (e) {
                   if(e.response.status===404){
                       setError("Registro no encontrado.")
                   }
                }
                setLoading(false)

            }else {
                setCanje(undefined);
            }
        })()
    }, [setState, autdetalle,setCanje]);

    return (
        <>
            <Dialog open={Boolean(autdetalle?.hasOwnProperty("id"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={state.autTipoString || Strings.autorizacion+" "+Strings.pendiente} nofullScreen onClose={goBack}/>
                <DialogContent className={classes.dialogContent}>
                    {loading && <LoadingProgress />}
                    {!!error &&
                        <Alert severity="warning">{error}</Alert>
                    }
                    {!!canje?.id &&
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(canje.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{canje.medico}</Typography>
                                        <Typography variant={"caption"}>{Strings.medico}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{canje.canPuntos}</Typography>
                                        <Typography variant={"caption"}>{Strings.puntos}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{!!canje.canInactivo ? Strings.inactivo : Strings.activo}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>
                                    {canje.canNota &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{canje.canNota}</Typography>
                                            <Typography variant={"caption"}>{Strings.comentario}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                    }

                </DialogContent>
                {state.autEstado === 1 &&
                    <DialogActions>
                        <Button color="secondary" onClick={()=> {
                            navigate(location.pathname, {replace:true})
                            navigate(location.pathname, {state: {autActions: {id: state.id, action: "rechazar"}}})
                        }}>{Strings.rechazar}</Button>
                        <Button color="primary"
                                onClick={()=> {
                                    navigate(location.pathname, {replace:true})
                                    navigate(location.pathname, {state: {autActions: {id: state.id, action: "aprobar"}}})
                                }}
                        >{Strings.aprobar}</Button>
                    </DialogActions>
                }
            </Dialog>
            <AutorizacionActions/>

        </>
    );

}
