import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getCanjes, addEditCanje, getCanjeById, delCanje} from '../../services';
import {ICanjeState, ICanje, ICanjeRes} from "../types/ICanje";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
const initialState: ICanjeState = {
    status: "idle"
}

export const mostrarCanjes = createAsyncThunk(
    'mostrarCanjes', async (params:object|undefined ) => {
        const response = await getCanjes(params)
        return {data: response.data as ICanjeRes, params: response.config.params}
    }
)
export const mostrarCanjeById = createAsyncThunk(
    'mostrarCanjeById', async (id:number ) => {

        const response = await getCanjeById(id)
        return response.data as ICanje
    }
)

export const agregarEditarCanje = createAsyncThunk(
    'Canjes/agregarEditar', async (canje: ICanje) => {
        const response = await addEditCanje(canje);

        if (response.status === 200) {
            if(!canje.id){
                store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Este canje esta pendiente a autorización por un administrador.", autoDismiss: 8,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!canje.id};
    }
);
export const eliminarCanje = createAsyncThunk(
    'canje/eliminar', async (id: number) => {
        await delCanje(id);
        //  if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarCanjes',
    initialState,
    reducers: {
        updateCanjeRow: (state,{payload}) => {
            let data = payload as ICanje;
            if(state.list?.results){
                state.list.results = state.list.results.map((can)=>{
                    if(can.id === data.id) return {...can,...data};

                    return can;
                })
            }
        },
    },
    extraReducers: builder => {
        //--mostrarCanjes
        builder.addCase(mostrarCanjes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCanjes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarCanjes.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarCanje
        builder.addCase(agregarEditarCanje.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarCanje.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarCanje.rejected, (state ) => {
            state.status = "idle";
        })


        //---mostrarCanjeById
        builder.addCase(mostrarCanjeById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCanjeById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarCanjeById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarCanje
        builder.addCase(eliminarCanje.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarCanje.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results?.filter(r=>r.id!==id)
            }
        })
        builder.addCase(eliminarCanje.rejected, (state ) => {
            state.status = "idle";
        })
    }
});



export const { updateCanjeRow } = slice.actions;

export default slice.reducer;
