import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {mostrarVisitas} from "../../redux/reducers/visitasReducers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonResponsive,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {formatDate} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IVisitaFilter} from "../../redux/types/IVisita";
import TopAppBar from "../../components/TopAppBar";
import VisitaDetalles from "./VisitaDetalles";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchMedico from "../../components/selects/SelectSearchMedico";
import {DateInput} from "../../components/CustomTextField";
import {FilterList} from "@mui/icons-material";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState:IVisitaFilter={
  name: '',
  medico: "",
  medicoId: undefined,
  fechaDesde: "",
  fechaHasta: "",
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:false
};
function Visitas(){
  let navigate =useNavigate();
  const {pathname}:{pathname:string} = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);


  const { list, status,filtered} = useAppSelector(state => state.visitas)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarVisitas());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarVisitas({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,page:0,[name]:value})
  };
  const resetFilter = () => {
    setFilter(initialState);
    setOpenFilter(false);
    Dispatch(mostrarVisitas({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarVisitas(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarVisitas({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectSearchMedico
                  value={{
                    medico:filter.medico ?? "",
                    medicoId: (filter.medicoId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    medicoId:v.medicoId?+v.medicoId:undefined,
                    medico: v.medico
                  })}
              />
            </Grid>
            <Grid item xs={6}>
              {/*// @ts-ignore*/}
              <DateInput
                  name={"fechaDesde"}
                  label={Strings.fechaDesde}
                  value={filter.fechaDesde}
                  onChange={handleFilter}
              />
            </Grid>
            <Grid item xs={6}>
              {/*// @ts-ignore*/}
              <DateInput
                  name={"fechaHasta"}
                  label={Strings.fechaHasta}
                  value={filter.fechaHasta}
                  onChange={handleFilter}
              />
            </Grid>
          </Grid>

        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>


    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}

      <ListViewMode onNew={undefined}>
        <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
        <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
        <FilteredAlert onClose={resetFilter} filter={filtered}/>
      </ListViewMode>
      <TableSort data={[
        {pro:"medicoId",text:Strings.medico},
        {pro:"fechaCreacion",text:"Fecha entrada"},
        {pro:"fechaCreacion",text:"Fecha salida"},
        {pro:"estado",text:"Estado"},
        {pro:"visComentario",text:Strings.comentario},
        {pro:"usuarioCreadoPor",text:Strings.creadoPor}
      ]} onSort={tableSortProps} lessVh={155}>
        <TableBody>
          {!isEmpty && list?.results.map((item) =>
              <TableRow key={item.id}
                        onClick={()=> {
                            navigate(pathname, {state: {visitaDetalles: item}})
                        }}
                        sx={item?.estado === 1 ? {background: "#ffecd0 !important"}: {}} //abierta

              >
                <TableCell>{item.medico}</TableCell>
                <TableCell>{formatDate(item.fechaCreacion,"DD/MM/YYYY h:m A")}</TableCell>
                <TableCell>{formatDate(item.fechaSalida,"DD/MM/YYYY h:m A")}</TableCell>
                <TableCell>{!!item.estado && item.estadoString}</TableCell>
                <TableCell>{item.visComentario}</TableCell>
                <TableCell>{item.usuarioCreadoPor}</TableCell>
              </TableRow>
          )}
        </TableBody>
      </TableSort>
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarVisitas({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    <VisitaDetalles/>

  </>;
}

export default Visitas;

