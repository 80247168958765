import React, {useEffect, useState} from 'react';
import {eliminarVisita, mostrarVisitaById} from "../../redux/reducers/visitasReducers";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, hasPermissionTo, nullToEmptyString, PERSMISOS} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IVisita} from "../../redux/types/IVisita";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import ConfirmDialog from "../../components/ConfirmDialog";

const initialState:IVisita = {
    id: undefined,
    visComentario: "",
    medico: "",
    medicoId: undefined,
    entregas: []
};
export default function VisitaDetalles() {
    const Dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {byId: visita, status} = useAppSelector(state => state.visitas)
    const {pathname,  state: urlState}:{state:any,pathname:string}  = useLocation();
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if(!!urlState?.visitaDetalles?.id) {
            Dispatch(mostrarVisitaById(+urlState?.visitaDetalles?.id))
        }else {
            setState(initialState);
        }
    }, [setState, urlState?.visitaDetalles?.id, Dispatch]);

    useEffect(()=>{
        if(!!urlState?.visitaDetalles?.id && visita?.id === parseInt(urlState?.visitaDetalles.id)) {
            setState({...initialState, ...nullToEmptyString(visita)});
        }else{
            setState(initialState)
        }
    },[urlState?.visitaDetalles?.id,visita])

    return (
        <>
            <Dialog open={Boolean(urlState?.hasOwnProperty("visitaDetalles"))} onClose={()=>navigate(-1)} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={"Detalles Visita"} onClose={()=>navigate(-1)} nofullScreen/>

                <DialogContent>

                    {(status === "loading") && <LoadingProgress />}
                    <table className={"noHover"}>
                        <tbody>
                            <tr>
                                <td width={"128px"}><Box fontWeight={500}>Médico</Box></td>
                                <td>: {state.medico}</td>
                            </tr>
                            <tr>
                                <td><Box fontWeight={500}>Fecha Entrada</Box></td>
                                <td>: {formatDate(state.fechaCreacion,"DD/MM/YYYY h:m A")}</td>
                            </tr>
                            <tr>
                                <td><Box fontWeight={500}>Fecha Salida</Box></td>
                                <td>: {formatDate(state.fechaSalida,"DD/MM/YYYY h:m A")}</td>
                            </tr>

                            <tr>
                                <td width={"128px"}><Box fontWeight={500}>{Strings.creadoPor}</Box></td>
                                <td>: {state.usuarioCreadoPor}</td>
                            </tr>
                            <tr>
                                <td width={"128px"}><Box fontWeight={500}>{Strings.estado}</Box></td>
                                <td>: {!!state.estado && state.estadoString}</td>
                            </tr>


                            <tr>
                                <td><Box fontWeight={500}>Comentario</Box></td>
                                <td>: {state.visComentario}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <Typography color={"primary"} fontWeight={500} mb={0.5}>Productos entregados:</Typography>
                    {!!state?.entregas?.length &&
                        <TableContainer>
                            <Table aria-label="simple table" size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{Strings.articulo}</TableCell>
                                        <TableCell>Cantidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state?.entregas.map((item,i)=>{
                                        return (
                                            <React.Fragment key={i}>
                                                <TableRow >
                                                    <TableCell>{item.articulo}</TableCell>
                                                    <TableCell>{item.cantidad}</TableCell>
                                                </TableRow>
                                            </React.Fragment>

                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                </DialogContent>
                {hasPermissionTo(PERSMISOS.visitasEliminar) &&
                    <DialogActions>
                        <Button
                            onClick={()=>{
                                navigate(pathname, {state: {...urlState ,delVisita:state.id}})
                            }}
                            sx={{color:"#f84f4f"}}
                        >{Strings.eliminar}</Button>

                    </DialogActions>
                }
            </Dialog>
            {!!urlState?.delVisita &&
                <ConfirmDialog onConfirm={() => Dispatch(eliminarVisita(urlState.delVisita))}/>
            }
        </>
    );

}
