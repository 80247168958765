import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    Alert
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {TIPO_USUARIO} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {IReposicion} from "../../redux/types/IReposicion";
import {agregarEditarReposicion} from "../../redux/reducers/reposicionesReducers";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import {getTokenInfo} from "../../services/auth";
import SelectSearchFarmacia from "../../components/selects/SelectSearchFarmacia";
import {SelectSmart} from "../../components/CustomTextField";
import {objToKeyValue, TIPO_REPOSICION} from "../../helpers/CONSTANTES";
import VentasDetalle from './VentasDetalle';
import PushMoneyDetalle from "./PushMoneyDetalle";


const initialState:IReposicion = {
    farmaciaId: undefined,
    farmacia: "",
    repTipo: undefined,
    pushMoney: [],
    ventas: []
};
export default function Crear() {
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const {status} = useAppSelector(state => state.reposiciones);
    const user = useAppSelector(state=>state.auth.user)
    const [error, setError] = useState("");

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(user?.usuTipo === TIPO_USUARIO.farmacia){
            setState(prevState => ({...prevState,farmaciaId:(getTokenInfo() as any)["RelatedId"],farmacia:user.usuNombre}))
        }
    },[user, setState])

    useEffect(()=>{
        if(urlState?.hasOwnProperty("id")){
            setState(initialState)
        }
    },[urlState])

    const handleSubmit = () => {
        if(state.repTipo === TIPO_REPOSICION.ventas && !state.ventas?.length){
           return setError("Debes seleccionar algunas ventas.")
        }
        if(state.repTipo === TIPO_REPOSICION.pushMoney && !state.pushMoney?.length){
            return setError("Debes seleccionar algunos PushMoney.")
        }
        Dispatch(agregarEditarReposicion(state))
    };

    return (
        <>
            <Dialog open={Boolean(urlState?.hasOwnProperty("id"))} onClose={()=>navigate(-1)} maxWidth={"lg"} fullWidth={true}>
                <TopBarDialog title={Strings.reposicion} onClose={()=>navigate(-1)}/>
                <DialogContent>
                        {(status === "loading") && <LoadingProgress />}
                            <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
                                <Box sx={{mb:2}}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>{Strings.tipo}</InputLabel>
                                        <SelectSmart
                                            loading={false}
                                            required
                                            value={state?.repTipo ?? ""}
                                            onChange={(v:any)=>setState(prevState => ({
                                                ...prevState,
                                                repTipo:v.target.value,
                                                farmaciaId: undefined,
                                                farmacia: "",
                                                ventas: [],
                                                pushMoney: []
                                            }))}
                                            opciones={[
                                                {key: '', value: Strings.seleccione},
                                                ...objToKeyValue(TIPO_REPOSICION)
                                            ]}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{mb:2}}>
                                    <SelectSearchFarmacia
                                        disabled={user?.usuTipo === TIPO_USUARIO.farmacia || !state.repTipo}
                                        required
                                        value={{
                                            farmaciaId: (state.farmaciaId ?? "").toString(),
                                            farmacia: state.farmacia ?? ""
                                        }}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            farmacia:v.farmacia,
                                            farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined
                                        }))}
                                    />
                                </Box>
                            </ValidatorFormCustom>
                            {error &&
                                <Alert severity="error" onClose={() => setError("")}>{error}</Alert>
                            }
                            {state.repTipo === TIPO_REPOSICION.ventas && state.farmaciaId &&
                                <VentasDetalle onSelect={(v)=>setState({...state, ventas:v})} selected={state.ventas??[]} farmaciaId={state.farmaciaId}/>

                            }
                            {state.repTipo === TIPO_REPOSICION.pushMoney && state.farmaciaId &&
                                <PushMoneyDetalle onSelect={(v)=>setState({...state, pushMoney:v})} selected={state.pushMoney??[]} farmaciaId={state.farmaciaId}/>
                            }
                    </DialogContent>
                <DialogActions>
                    <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                    <Button disabled={status === "loading"} type="submit" form="form1">
                        {status === "loading"? (
                            <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                        ):(
                            <span>{Strings.guardar}</span>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
