import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getOfertas, addEditOferta, getOfertaById, delOferta} from '../../services';
import {IOfertaState, IOferta, IOfertaRes} from "../types/IOferta";
import historyHelper from "../../helpers/history";
const initialState: IOfertaState = {
    status: "idle"
}

export const mostrarOfertas = createAsyncThunk(
    'mostrarOfertas', async (params:object|undefined ) => {
        const response = await getOfertas(params)
        return {data: response.data as IOfertaRes, params: response.config.params}
    }
)
export const mostrarOfertaById = createAsyncThunk(
    'mostrarOfertaById', async (id:number ) => {

        const response = await getOfertaById(id)
        return response.data as IOferta
    }
)

export const agregarEditarOferta = createAsyncThunk(
    'Ofertas/agregarEditar', async (Oferta: IOferta) => {
        const response = await addEditOferta(Oferta);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!Oferta.id};
    }
);
export const eliminarOferta = createAsyncThunk(
    'oferta/eliminar', async (id: number) => {
        const response = await delOferta(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarOfertas',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarOfertas
        builder.addCase(mostrarOfertas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarOfertas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarOfertas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarOferta
        builder.addCase(agregarEditarOferta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarOferta.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarOferta.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarOfertaById
        builder.addCase(mostrarOfertaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarOfertaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarOfertaById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarOferta
        builder.addCase(eliminarOferta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarOferta.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarOferta.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
