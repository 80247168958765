import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarReposicion, mostrarReposiciones} from "../../redux/reducers/reposicionesReducers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonResponsive,
  FilteredAlert,
  HtmlTooltip,
  ListViewMode,
  LoadingProgress,
  Pagination,
  TableSort
} from '../../components';
import {Close, FilterList} from '@mui/icons-material';
import {DateInput} from "../../components/CustomTextField";

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IReposicion} from "../../redux/types/IReposicion";
import Crear from "./Crear";
import ConfirmDialog from "../../components/ConfirmDialog";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import DetalleReposicion from "./DetalleReposicion";
import TopAppBar from "../../components/TopAppBar";
import SelectSearchFarmacia from "../../components/selects/SelectSearchFarmacia";
import {getValueString, TIPO_REPOSICION} from "../../helpers/CONSTANTES";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  farmaciaId:'',
  farmacia:'',
  estado: "",
  fechaDesde:'',
  fechaHasta:'',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};

function Reposiciones(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.reposiciones)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarReposiciones());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarReposiciones({
        ...filter,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarReposiciones({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarReposiciones(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarReposiciones({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };


  return <>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
          {/*<Grid item xs={12}>*/}
          {/*  <FormControl fullWidth>*/}
          {/*    <InputLabel shrink>{Strings.estado}</InputLabel>*/}
          {/*    <SelectSmart*/}
          {/*        loading={false}*/}
          {/*        value={filter?.estado ?? ""}*/}
          {/*        onChange={handleFilter}*/}
          {/*        name="estado"*/}
          {/*        opciones={[*/}
          {/*          {key: '', value: Strings.todo},*/}
          {/*          {key: '1', value: Strings.pendiente},*/}
          {/*          {key: '2', value: Strings.pendiente},*/}
          {/*          {key: '3', value: Strings.rechazada},*/}
          {/*        ]}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</Grid>*/}
          <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaDesde"}
                    label={Strings.fechaDesde}
                    value={filter.fechaDesde}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaHasta"}
                    label={Strings.fechaHasta}
                    value={filter.fechaHasta}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchFarmacia
                    value={{
                        farmaciaId: (filter.farmaciaId ?? "").toString(),
                        farmacia: filter.farmacia ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        farmacia:v.farmacia,
                        farmaciaId:v.farmaciaId?v.farmaciaId:""
                    }))}
                />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}

      <div>

        <ListViewMode  noView newBtnText={"Solicitar Reposicion"} onNew={hasPermissionTo(PERSMISOS.reposicionesCrear) ? ()=>navigate(location.pathname,{state:{id:null}}) : undefined}>
          <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
          <FilteredAlert onClose={resetFilter} filter={filtered}/>
        </ListViewMode>


        <TableSort data={[
          {pro:"",text:""},
          {pro:"id",text:"ID"},
          {pro:"fechaCreacion",text:Strings.fecha},
          {pro:"repTipo",text:Strings.tipo},
          {pro:"farmaciaId",text:Strings.farmacia},
          {pro:"repMonto",text:Strings.monto},
          {pro:"repEstado",text:Strings.estado},
        ]} onSort={tableSortProps} lessVh={155}>
          <TableBody>
            {!isEmpty && list?.results.map((rep:IReposicion) =>
                <TableRow key={rep.id} onClick={()=> {
                  navigate(location.pathname, {state: {reposicion: rep}})
                }}>
                  <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                    {!rep.repInactivo && hasPermissionTo(PERSMISOS.reposicionesEliminar) &&
                        <HtmlTooltip title={Strings.anular}>
                          <IconButton size={"small"}
                                      onClick={(e)=> {
                                        navigate(location.pathname, {state: {confirmDialog: rep.id}})
                                      }}>
                            <Close fontSize={"small"} className="text-red"/>
                          </IconButton>
                        </HtmlTooltip>
                    }
                  </TableCell>
                  <TableCell>{rep.id}</TableCell>
                  <TableCell>{formatDate(rep.fechaCreacion)}</TableCell>
                  <TableCell>{getValueString(TIPO_REPOSICION,rep.repTipo)}</TableCell>

                  <TableCell>{rep.farmacia}</TableCell>
                  <TableCell>{rep.repMonto}</TableCell>
                  <TableCell>{rep.repInactivo?Strings.inactivo:Strings.activo}</TableCell>
                </TableRow>
            )}
          </TableBody>
        </TableSort>

        <Pagination
            empty={Boolean(isEmpty && status !== "loading")}
            count={list?.totalRecordCount || 0}
            rowsPerPage={Number(filter.pageSize)}
            page={(list?.pageNo ?? 0) -1}
            onPageChange={(_,page:number)=>Dispatch(mostrarReposiciones({...filter,page:(page + 1).toString()}))}
            onRowsPerPageChange={handleFilter}
        />
      </div>

    </section>

    <DetalleReposicion/>
    <Crear/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={() => Dispatch(eliminarReposicion(urlState.confirmDialog))}/>
    }
  </>;
}

export default Reposiciones;

