import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getPushMoney, addEditPushMoney, getPushMoneyById, delPushMoney} from '../../services';
import {IPushMoneyState, IPushMoney, IPushMoneyRes} from "../types/IPushMoney";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
import {AppState} from "./rootReducer";
const initialState: IPushMoneyState = {
    status: "idle"
}

export const mostrarPushMoney = createAsyncThunk(
    'mostrarPushMoney', async (params:object|undefined,thunkAPI ) => {
        const {pushMoney} = thunkAPI.getState() as AppState;
        let paramsFilter = params || pushMoney.filtered;
        const response = await getPushMoney(paramsFilter);
        return {data: response.data as IPushMoneyRes, params: response.config.params}


    }
)
export const mostrarPushMoneyById = createAsyncThunk(
    'mostrarPushMoneyById', async (id:number ) => {

        const response = await getPushMoneyById(id)
        return response.data as IPushMoney
    }
)

export const agregarEditarPushMoney = createAsyncThunk(
    'PushMoney/agregarEditar', async (pushMoney: IPushMoney) => {
        const response = await addEditPushMoney(pushMoney);

        if (response.status === 200) {
            if(!pushMoney.id){
                store.dispatch(Notifications.success({
                    title: 'Solicitud enviada con exito.',
                    message:"Su solicitud No. ("+response.data.id+") ha sido enviada de forma satisfactoria y está pendiente de verificación para fines de aprobación.", autoDismiss: 12,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!pushMoney.id};
    }
);
export const eliminarPushMoney = createAsyncThunk(
    'pushMoney/eliminar', async ({id, motivo}:{id: number, motivo: string}) => {
        let response = await delPushMoney(id,motivo);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {id,motivo};
    }
);



const slice = createSlice({
    name: 'mostrarPushMoney',
    initialState,
    reducers: {},
    extraReducers: builder => {
        //--mostrarPushMoney
        builder.addCase(mostrarPushMoney.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPushMoney.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPushMoney.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarPushMoney
        builder.addCase(agregarEditarPushMoney.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPushMoney.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarPushMoney.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarPushMoneyById
        builder.addCase(mostrarPushMoneyById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPushMoneyById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarPushMoneyById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarPushMoney
        builder.addCase(eliminarPushMoney.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarPushMoney.fulfilled, (state,action ) => {
            state.status = "idle";
            const {id, motivo} = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results?.map(r=>{
                    if(r.id === id){
                        return {...r, psmEstado:2,psmnNota:motivo }
                    }
                    return r
                })
            }
            if(state.byId){
                state.byId = {...state.byId, psmEstado:2,psmNota:motivo }
            }
        })
        builder.addCase(eliminarPushMoney.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
