import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarCanje, mostrarCanjes} from "../../redux/reducers/canjesReducers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonResponsive,
  FilteredAlert,
  HtmlTooltip,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';
import {Close, FilterList} from '@mui/icons-material';
import {DateInput, SelectSmart} from "../../components/CustomTextField";

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ICanje} from "../../redux/types/ICanje";
import Crear from "./Crear";
import ConfirmDialog from "../../components/ConfirmDialog";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import DetalleCanje from "./DetalleCanje";
import TopAppBar from "../../components/TopAppBar";
import SelectSearchMedico from "../../components/selects/SelectSearchMedico";
import {TIPO_AUTORIZACION} from "../../helpers/CONSTANTES";
import AutorizacionDetalle from '../Autorizaciones/AutorizacionDetalle';
import {ShieldKeyOutline} from "mdi-material-ui";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  medicoId:'',
  medico:'',
  estado: "",
  fechaDesde:'',
  fechaHasta:'',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};

function Canjes(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.canjes)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarCanjes());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarCanjes({
        ...filter,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarCanjes({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarCanjes(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarCanjes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };


  return <>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.estado}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.estado ?? ""}
                  onChange={handleFilter}
                  name="estado"
                  opciones={[
                    {key: '', value: Strings.todo},
                    {key: '1', value: Strings.pendiente},
                    {key: '2', value: Strings.pendiente},
                    {key: '3', value: Strings.rechazada},
                  ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaDesde"}
                    label={Strings.fechaDesde}
                    value={filter.fechaDesde}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaHasta"}
                    label={Strings.fechaHasta}
                    value={filter.fechaHasta}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchMedico
                    showPuntos
                    value={{
                        medicoId: (filter.medicoId ?? "").toString(),
                        medico: filter.medico ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        medico:v.medico,
                        medicoId:v.medicoId?v.medicoId:""
                    }))}
                />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}

      <div>

        <ListViewMode  noView newBtnText={"Solicitar Canje"} onNew={hasPermissionTo(PERSMISOS.canjesCrear) ? ()=>navigate(location.pathname,{state:{id:null}}) : undefined}>
          <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
          <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
          <FilteredAlert onClose={resetFilter} filter={filtered}/>
        </ListViewMode>

        <TableSort data={[
          {pro:"",text:""},
          {pro:"id",text:"ID"},
          {pro:"fechaCreacion",text:Strings.fecha},
          {pro:"medicoId",text:Strings.medico},
          {pro:"canPuntos",text:Strings.puntos},
          {pro:"autorizacionEstado",text:Strings.autorizacion},
          {pro:"canEstado",text:Strings.estado},
        ]} onSort={tableSortProps} lessVh={155}>
          <TableBody>
            {!isEmpty && list?.results.map((can:ICanje) =>
                <TableRow key={can.id} onClick={()=> {
                  navigate(location.pathname, {state: {canje: can}})
                }}>
                  <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                    {!can.canInactivo && hasPermissionTo(PERSMISOS.canjesEliminar) &&
                        <HtmlTooltip title={Strings.anular}>
                          <IconButton size={"small"}
                                      onClick={(e)=> {
                                        navigate(location.pathname, {state: {confirmDialog: can.id}})
                                      }}>
                            <Close fontSize={"small"} className="text-red"/>
                          </IconButton>
                        </HtmlTooltip>
                    }
                    {can.autorizacionEstado === 1 && hasPermissionTo(PERSMISOS.autorizaciones) &&
                        <HtmlTooltip title={Strings.autorizacion}>
                          <IconButton  onClick={()=> navigate(location.pathname, {state: {autdetalle: {autEstado:1, id:can.autorizacionId,autTipo:TIPO_AUTORIZACION.canje,autTransacionId:can.id}}})}  size={"small"}>
                            <ShieldKeyOutline fontSize={"small"} className="text-violet"/>
                          </IconButton>
                        </HtmlTooltip>
                    }
                  </TableCell>
                  <TableCell>{can.id}</TableCell>
                  <TableCell>{formatDate(can.fechaCreacion)}</TableCell>
                  <TableCell>{can.medico}</TableCell>
                  <TableCell>{can.canPuntos}</TableCell>
                  <TableCell>{can.autorizacionEstadoString}</TableCell>
                  <TableCell>{can.canInactivo?Strings.inactivo:Strings.activo}</TableCell>
                </TableRow>
            )}
          </TableBody>
        </TableSort>

        <Pagination
            empty={Boolean(isEmpty && status !== "loading")}
            count={list?.totalRecordCount || 0}
            rowsPerPage={Number(filter.pageSize)}
            page={(list?.pageNo ?? 0) -1}
            onPageChange={(_,page:number)=>Dispatch(mostrarCanjes({...filter,page:(page + 1).toString()}))}
            onRowsPerPageChange={handleFilter}
        />
      </div>

    </section>
    {hasPermissionTo(PERSMISOS.autorizaciones) &&
        <AutorizacionDetalle />
    }


    <DetalleCanje/>
    <Crear/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={() => Dispatch(eliminarCanje(urlState.confirmDialog))}/>
    }
  </>;
}

export default Canjes;

