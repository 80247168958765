import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress} from '@mui/material';
import Strings from "../../assets/strings";
import {getArticulosMarcas} from "../../services";
import {IArticulosMarca} from "../../redux/types/IArticulosMarcas";
import {TextValidatorCustom} from "../../helpers/form-validator";

type TProps = {
    label?:string,
    required?:boolean,
    value:TValue,
    onChange: (val:TValue)=>void

}
type TValue = {
    marcaId?:string,
    marca:string
}
var searchTimeout:ReturnType<typeof setTimeout>;

let emptyValue = {
    marcaId:"",
    marca:""
}

export default function SelectSearchMarca({ value,label=Strings.marca,required, onChange}:TProps) {
    const [options, setOptions] = React.useState<TValue[]>([]);
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        return ()=>clearTimeout(searchTimeout)
    })
    const fetchItems = (name:string) => {
        setLoading(true)
        getArticulosMarcas({name}).then(res=>{
            let result = res.data.results as IArticulosMarca[];
            if(result.length){
                setOptions(result.map(r=>({marcaId:r.id!.toString(),marca:r.marNombre} as TValue)) )
            }
        }).finally(()=>setLoading(false))
    }

    return (

        <React.Fragment>
            <Autocomplete
                fullWidth
                loading={loading}
                onOpen={()=> {
                    if(options.length) return false;
                    fetchItems("")
                }}
                options={[value,...options.filter((r) =>r.marcaId!==value.marcaId)]}
                getOptionLabel={(option) => option.marca}
                value={value}
                isOptionEqualToValue={(option, value) => option.marcaId === value.marcaId}

                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                    onChange(newValue??emptyValue)
                }}
                // inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        fetchItems(newInputValue)
                    },1000);
                }}
                renderInput={(params) => (
                    <TextValidatorCustom
                        {...params}
                        name={""}
                        value={value.marcaId}
                        label={label}
                        validators={required?['required']:[]}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </React.Fragment>

    );
}
