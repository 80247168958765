import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getTarjetas, addEditTarjeta, getTarjetaById, delTarjeta} from '../../services';
import {ITarjetaState, ITarjeta, ITarjetaRes} from "../types/ITarjeta";
import historyHelper from "../../helpers/history";
const initialState: ITarjetaState = {
    status: "idle"
}

export const mostrarTarjetas = createAsyncThunk(
    'mostrarTarjetas', async (params:object|undefined ) => {
        const response = await getTarjetas(params)
        return {data: response.data as ITarjetaRes, params: response.config.params}
    }
)
export const mostrarTarjetaById = createAsyncThunk(
    'mostrarTarjetaById', async (id:number ) => {

        const response = await getTarjetaById(id)
        return response.data as ITarjeta
    }
)

export const agregarEditarTarjeta = createAsyncThunk(
    'Tarjetas/agregarEditar', async (Tarjeta: ITarjeta) => {
        const response = await addEditTarjeta(Tarjeta);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!Tarjeta.id};
    }
);
export const eliminarTarjeta = createAsyncThunk(
    'tarjeta/eliminar', async (id: number) => {
        await delTarjeta(id);
      //  if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarTarjetas',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarTarjetas
        builder.addCase(mostrarTarjetas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarTarjetas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarTarjetas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarTarjeta
        builder.addCase(agregarEditarTarjeta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarTarjeta.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarTarjeta.rejected, (state ) => {
            state.status = "idle";
        })


        //---mostrarTarjetaById
        builder.addCase(mostrarTarjetaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarTarjetaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarTarjetaById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarTarjeta
        builder.addCase(eliminarTarjeta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarTarjeta.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results?.map(r=>{
                    if(r.id === id){
                        return {...r, tarInactivo:true}
                    }
                    return r
                })
            }
        })
        builder.addCase(eliminarTarjeta.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
