import React from 'react';
import {Box, Button, Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from "react-router-dom";
import {ArrowBack} from '@mui/icons-material';
import Html5QrcodePlugin from '../../../components/Html5QrcodePlugin';

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        boxShadow: "#5d5d5d 0px 0px 12px",
        maxWidth: 615,
        margin: "-110px auto 30px auto",
        boxSizing: "border-box",
        background:"#fff",
        [theme.breakpoints.down('md')]: {
            marginTop:-71,
            "& h1":{
                marginTop: "-64px !important",
                color: "white  !important",
                marginBottom: "39px !important",
            }
        },
        "& #html5qr-code-full-region>div:first-child":{
            display:"none",
        }


    },
    topHead: {
        position:"relative",
        height: "103px",
        padding: theme.spacing(2),
        borderBottom: "1px solid #ddd",
        "& .info":{
            display: 'flex',
            alignItems: "center",
        },
        "& h1":{
            color:theme.palette.secondary.main,
            fontWeight: "normal",
            margin: "0",
            marginTop:36,
            fontSize: "1.5rem",
            minWidth: 254,
            textAlign: "left",
            textTransform: "uppercase",

        },
        "& h2":{
            color:theme.palette.primary.main,
            margin: "0",
            fontSize: "1.2rem",
            fontWeight: 100,
            [theme.breakpoints.down('md')]: {
                paddingTop: 20,
                fontSize: "1.1rem",
                textAlign: "center",
            }
        },
        "& .logo":{
            marginLeft: 10,
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: 99,
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".9rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            height: "auto",
            "& .info":{
                flexDirection: "column-reverse",
                textAlign: "center",
            },
            "& h1":{
                textAlign: "center",
            }
        }
    },
    lang:{
        textAlign: "center",
        marginBottom: theme.spacing(3),
        "& select":{
            padding: 5,
            fontSize: "1em",
        }
    },
    title:{
        borderBottom: "1px solid "+theme.palette.grey[500],
        marginBottom: theme.spacing(2),
        fontSize: 15,
        fontWeight: 500,
        padding: "6px 0",
    }
}));

function EscanearCodigo(){
    const classes = useStyles();
    let navigate = useNavigate();

    return(
        <>
            <Box bgcolor="primary.main" width={"100%"} height={138} />
            <div className={classes.root}>
                <>
                    <div className={classes.topHead}>
                        <Button onClick={()=>navigate("/login")}
                                sx={{
                                    position: "absolute",
                                    top: 5,
                                    left: 9,
                                }}
                                startIcon={<ArrowBack/>}>Iniciar sesión</Button>
                        <h1>Registro Paciente</h1>
                        <h2>Escanear código QR.</h2>

                    </div>
                </>

                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={(v:any)=> {
                        if(v.indexOf("recetaForm") !== -1){
                            window.open(v, "_blank")
                        }else{
                            window.alert("Código incorrecto!")
                        }
                    }}/>
            </div>
        </>
    );
}

export default EscanearCodigo;
