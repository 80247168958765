import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getPaises} from '../../services';
import { IIdNameAccion, IIdNameAccionState} from "../types/Icommon";
const initialState: IIdNameAccionState= {
    status: "idle"
}

export const mostrarPaises = createAsyncThunk(
    'mostrarPaises', async (params:object|undefined ) => {
            const response = await getPaises()
            return {data: response.data as IIdNameAccion[], params: response.config.params}
    }
)


const slice = createSlice({
    name: 'mostrarPaises',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarPaises
        builder.addCase(mostrarPaises.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPaises.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPaises.rejected, (state ) => {
            state.status = "idle";
        })
    }

});




export default slice.reducer;