import React, { useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Theme,
    InputAdornment,
    Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AspectRatioSharp,ImageAspectRatioSharp, Close} from '@mui/icons-material';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import defaultImg from '../../assets/img/no_image.jpg';

import pdfIcon from '../../assets/img/pdf.svg';
import Strings from "../../assets/strings";
import {TextValidatorCustom} from "../../helpers/form-validator";
const useStyles = makeStyles((theme:Theme) => ({
    root:{
        textAlign:"center",
        position: "relative",
        "& img":{
            cursor:"pointer"
        }
    },
    delIcon:{
        position: "absolute",
        bottom: 10,
        left: "calc(50% - 14px)",
        color: theme.palette.error.main,
        opacity: .9,
        background:"rgba(0, 0, 0, 0.3) !important",
        padding: 3,
        "&.showImg":{
            bottom: 5,
            left: "calc(100% - 36px)",
            background:"rgba(0, 0, 0, 0.06) !important",

        },
        "& svg":{
            width: "0.8em",
            height: "0.8em",
        }
    },
    inputFlex:{
        background: "white",
        marginTop: 7,
        display:"flex",
        alignItems:"center",
        border: "1px solid #e6e6e6",
        height:36,
        "& img":{
            cursor: "pointer",
        },
        "& label":{
            padding: "8px 8px",
            textAlign: "left",
            width: "100%",
            cursor:"pointer",

        }
    },
    dialogContent:{
        padding: "3px !important",

    },
    dialogActions:{
        display:"flex",
        "& button:first-child":{
            marginRight: "auto",
        }
    },
    textField:{
        "& input:before":{
            content: `'Seleccione'`,
            position: "absolute",
            top: 0,
            left: 34,
            paddingLeft: 16,
            background: "white",
            width: "calc(100% - 50px)",
            height: "calc(100% - 1px)",
            display: "flex",
            alignItems: "center",
        },
        "& .MuiInput-root":{
            padding: 0,
        }
    }


}));

interface Iprops {
    src:string,
    label?:string,
    onChange:Function,
    changeRadio?:Boolean,
    showImg?:Boolean,
    required?:Boolean,
    accept?:string,
    output?:string,
    defaultImgProp?:string,
}

export default function SelectFiles({src, onChange, changeRadio = true,label="",required, showImg = true, accept="image/*",output="jpeg",defaultImgProp = defaultImg}:Iprops){
    const classes = useStyles();
   // const id = Math.random().toString();

    const [deleted, setDeleted] = useState("");


    const [img, setImg] = useState(null);
    const [, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [aspectRatioSq, setAspectRatioSq] = useState(true);
    const [cropper, setCropper] = useState<any>();
    const [selectedFile, setSelectedFile] = useState();



    const showModal = () => {
        setShowAddForm(true)
    }
    const closeModal = () => {
        setLoading(false)
        setShowAddForm(false)
    }
    const handleChange = (e:any) => {
        e.preventDefault();
        setError("");
        let file:any;
        if (e.dataTransfer) {
          file = e.dataTransfer.files[0];
        } else if (e.target) {
          file = e.target.files[0];
        }
        if (!file) return false;
        setSelectedFile(e.target.value)

        if(file.type.indexOf("image") === -1 && file.type.indexOf("pdf") === -1) return setError(Strings.archivoNoPermitidoMsj)

        if(file.type.indexOf("pdf") !== -1 && file.size > 3000000) return setError(Strings.archivoExcedeMsj+ ", 3MB!");


        const reader = new FileReader();
        reader.onload = () => {
            if(file.type.indexOf("image") !== -1){
                setImg(reader.result as any);
                showModal()
            }else{
                onChange(reader.result);
            }
        //    e.target.files = [];
        };
        reader.readAsDataURL(file);

      }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
                setLoading(true)

                if (typeof cropper.getCroppedCanvas() === 'undefined') {
                  return;
                }
                onChange(cropper.getCroppedCanvas().toDataURL('image/'+output, 0.8));
                closeModal();
        }
    };

    const onDelClick = () =>{
        setSelectedFile(undefined)
        if(src && src.indexOf("https://")  !== -1 && src !== "-1"){
            setDeleted(src);
            onChange("-1")
        }else{
            onChange(deleted)
        }
    }
    const viewFileClick = (src:string) =>{
        if(src.indexOf("base64") === -1)
            window.open(src);
    }



    return <>
        <div  className={classes.textField}>
            <TextValidatorCustom

                value={selectedFile}
                label={label}
                type="file"
                name={"file"}
                fullWidth
                accept={accept}
                validators={required?['required']:[]}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                                <Avatar onClick={()=>viewFileClick(src)}
                                    variant="square"
                                    alt="Remy Sharp"
                                    src={src && src !== "-1" ? (src.indexOf("pdf") !== -1? pdfIcon:src) : defaultImgProp ? defaultImgProp: defaultImg}
                                    sx={{ width: 33, height: 33 }}
                                />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="start">
                            {(src || deleted) &&
                            <IconButton onClick={onDelClick}>
                                <Close onClick={onDelClick}/>
                            </IconButton>}
                        </InputAdornment>
                    ),
                }}
            />
        </div>


        {/*<div className={classes.root}>*/}
        {/*    {(src || deleted) &&*/}
        {/*        <IconButton*/}
        {/*            className={classes.delIcon + (!showImg?" showImg":"")}*/}
        {/*            onClick={onDelClick}*/}
        {/*            size="large">*/}
        {/*            <Close/>*/}
        {/*        </IconButton>*/}
        {/*    }*/}
        {/*    <div className="label">*/}
        {/*        {showImg ?(*/}
        {/*            <label htmlFor={id}>*/}
        {/*                <img style={{width: "100%"}} src={src && src !== "-1" ? src : defaultImgProp ? defaultImgProp: defaultImg} alt="avatar"/>*/}
        {/*            </label>*/}
        {/*        ):(*/}
        {/*            <div className={classes.inputFlex}>*/}
        {/*                {(src && src !== "-1") ?(*/}
        {/*                    <img height={30} src={src.indexOf("pdf") !== -1? pdfIcon:src}*/}
        {/*                         onClick={()=>viewFileClick(src)} alt={""}/>*/}
        {/*                ) :(*/}
        {/*                    <AttachFile fontSize={"small"} />*/}
        {/*                )}*/}
        {/*                <label htmlFor={id}>{Strings.adjuntar}</label>*/}
        {/*            </div>*/}
        {/*        )}*/}
        {/*        {error && <Typography component={"div"} color={"error"} variant={"caption"} align={"left"}>{error}</Typography>}*/}
        {/*        <input type="file" id={id} style={{display:"none"}} onChange={handleChange} accept={accept}  />*/}
        {/*    </div>*/}
        {/*</div>*/}
        <Dialog open={Boolean(showAddForm)} onClose={closeModal}>
            <DialogContent className={classes.dialogContent}>
                <Cropper
                    src={img || undefined}
                    style={{ height: 400, width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {changeRadio &&
                <Button color="primary" onClick={() => setAspectRatioSq(!aspectRatioSq)}>
                    {aspectRatioSq ?(<AspectRatioSharp/>):(<ImageAspectRatioSharp color={"secondary"}/>)}
                </Button>
                }
                <Button color="primary" onClick={closeModal}>{Strings.cerrar}</Button>
                <Button
                    color="primary"
                    disabled={loading}
                    onClick={!loading ? getCropData : undefined} >
                    {loading ? Strings.cargando : Strings.agregar}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}
