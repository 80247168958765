import React, {useState, useEffect} from 'react';
import {agregarEditarUsoMultiple} from "../../redux/reducers/usosMultiplesReducers";
import { Button,Dialog,DialogContent,TextField,DialogActions} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {connect} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Grid from "@mui/material/Grid";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {MostrarError} from "../../components";
import {useAppSelector} from "../../redux/hooks";
import Strings from "../../assets/strings"
const filter = createFilterOptions();

const initialState = {
    usoGrupo:"",
    id: "",
    usoDescripcion: ""
};
function Crear(props) {
    const usosMultiples = useAppSelector(state=>state.usosMultiples.list);
    const grupos = Object.keys(usosMultiples??{});
    const {state:urlState} = useLocation();
    const navigate =useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState(initialState);
    const {isPending} = props;

    useEffect(() => {
        if(!!urlState && urlState.id) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarUsoMultiple(state)
    };
    const edit = (urlState && !!urlState.id)



    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("id"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title="Usos multiples" nofullScreen onClose={goBack}/>
                <DialogContent>
                    <MostrarError errors={props.error}/>
                    {isPending && <LoadingProgress />}
                    <form onSubmit={handleSubmit} id="form1">
                        <Grid container spacing={3}>
                            <Grid item xs={7}>
                                <Autocomplete
                                   options={grupos}
                                   value={state.usoGrupo}

                                   onChange={(event, newValue) => {
                                       setState({...state,usoGrupo:newValue.replace(Strings.agregar,"").trim()});
                                   }}
                                   filterOptions={(options, params) => {
                                       const filtered = filter(options, params);

                                       const { inputValue } = params;
                                       // Suggest the creation of a new value
                                       const isExisting = options.some((option) => inputValue === option);
                                       if (inputValue !== '' && !isExisting) {

                                           filtered.push(`${Strings.agregar} ${inputValue}`);
                                       }

                                       return filtered;
                                   }}
                                   freeSolo
                                   disabled={edit}
                                   renderInput={(params) =>
                                        <TextField  {...params} label="Grupo" fullWidth margin={"normal"}/>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="usoDescripcion"
                                    value={state.usoDescripcion}
                                    onChange={handleChange}
                                    required
                                    label="Descripción"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack} color="primary">Cancelar</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                        {edit ? " Actualizar" : " Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.usosMultiples.isPending,
    error:state.usosMultiples.error,
    grupos: state.usosMultiples.list || {}
});
export default connect(mapStateToProps, { agregarEditarUsoMultiple })(Crear);
