import {
    TextValidator,
    ValidatorComponent,
    ValidatorComponentProps,
    ValidatorForm,
    ValidatorFormProps
} from 'react-material-ui-form-validator';
import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
// debounceTime={1500}
import Strings from "../assets/strings";

export const errorMessagesC = (validators:string[])=>{
    //const lang = getLSLang("l");

    const valores = {
        matchRegexp: "Caracteres no permitidos.",
        isEmail: "Formato incorrecto.",
        isEmpty: "",
        required: "Campo requerido.",
        trim: "",
        isNumber: "",
        isFloat: "",
        isPositive: "",
        minNumber: "",
        maxNumber: "",
        minFloat: "",
        maxFloat: "",
        isString: "",
        minStringLength: "",
        maxStringLength: "maxStringLength",
        maxFileSize: "",
        allowedExtensions: "",
    }

    return validators?.map(v=>(valores as any)[v.split(":")[0]])
}

export const ValidatorFormCustom = (props:ValidatorFormProps)=> {
    return(
        <ValidatorForm {...props}/>
    )
}
export const TextValidatorCustom = ({validators,label,type, ...props}:ValidatorComponentProps)=> {
    const [listValidators, setListValidators] = useState<any[]>([]);

    useEffect(()=>{
        let list:any[] = [...validators??[]]
        const containsRegdexp = list.some(element => {
            if (element.includes("matchRegexp")) {
                return true;
            }
            return false;
        });
        if(!containsRegdexp ){ //&& !props.multiline
            switch (type) {
                // case undefined:
                //     list.push("matchRegexp:^[A-zÀ-ú0-9_ \\-+,.]*$")
                //     break
                // case "text":
                //     list.push("matchRegexp:^[A-zÀ-ú0-9\u00C0-\u024F_ \\-+,.]*$")
                //     break
                case "tel":
                    list.push("matchRegexp:^[0-9_ \\-().]*$")
                    break
                case "email":
                    list.push("matchRegexp:^[a-zA-Z0-9_ \\.@-]*$")
                    break
            }
        }
        setListValidators(list)
    },[type, validators,setListValidators])

    return(
        <TextValidator {...props}
                       type={type??"text"}
                       label={label+(listValidators?.includes("required")? " *":"")}
                       validators={[...listValidators]}
                       errorMessages={[...errorMessagesC(listValidators)]}
        />
    )
}

export class SelectValidatorCustom  extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            value,
            loading,
            error,
            errorMessages,
            label,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state as any;
        return (
            <TextField
                {...rest}
                label={label+(validators?.includes("required")? " *":"")}
                value={loading? Strings.cargando: (value?value:"")}
                fullWidth
                select={!loading}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
            >
                {this.props.children}
            </TextField>
        );
    }
}




export const TextValidatorMultiple = ({validators,multiple, label,type, ...props}:ValidatorComponentProps)=> {
    useEffect(()=>{
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isMultiple', (value) => {
            if (parseInt(value) % multiple === 0) {
                return true;
            }
            return false;
        });

        // remove rule when it is not needed

        return ()=>ValidatorForm.removeValidationRule('isMultiple');
    },[])


    return(
        <TextValidator {...props}
                       type={type??"text"}
                       label={label}
                       validators={["isMultiple"]}
                       errorMessages={["El valor debe ser multiplo de "+multiple]}
        />
    )
}

