import {authReducer} from './authReducer';
import { combineReducers } from '@reduxjs/toolkit'
import {reducer as notifications} from 'react-notification-system-redux';
import theme from './themeReducer';
import planes from './planesReducers';
import pacientes from './pacientesReducers';
import centrosMedicos from './centrosMedicosReducers';
import medicos from './medicosReducers';
import visitas from './visitasReducers';
import entregas from './entregasReducers';
import farmacias from './farmaciaReducers';
import ofertas from './ofertasReducers';
import ventas from './ventasReducers';
import canjes from './canjesReducers';
import transacciones from './transaccionesReducers';
import tarjetas from './tarjetasReducers';
import recetas from './recetasReducers';
import pushMoney from './pushMoneyReducers';
import reposiciones from './reposicionesReducers';

import articulos from './articulosReducers';
import articulosCategorias from './articulosCategoriaReducers';
import articulosMarcas from './articulosMarcaReducers';
import autorizaciones from  './autorizacionesReducers'
import notificaciones from './notificacionesReducers';
import parametros from './parametrosReducers';

import roles from './rolesReducers';
import usuarios from './usuariosReducers';


import usosMultiples from "./usosMultiplesReducers";
import paises from "./paisesReducers";



const rootReducer = combineReducers({
    auth: authReducer,
    theme,
    articulos,
    planes,
    pacientes,
    centrosMedicos,
    medicos,
    visitas,
    entregas,
    farmacias,
    ofertas,
    ventas,
    tarjetas,
    canjes,
    transacciones,
    recetas,
    articulosCategorias,
    articulosMarcas,
    roles,
    pushMoney,
    reposiciones,
    usuarios,
    usosMultiples,
    paises,
    parametros,
    autorizaciones,
    notificaciones,
    notifications
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
