import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarMedico, mostrarMedicos} from "../../redux/reducers/medicosReducers";

import {
  Button,
  Dialog, DialogActions, DialogContent, Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonResponsive,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IMedico, IMedicoFilter} from "../../redux/types/IMedico";
import TopAppBar from "../../components/TopAppBar";
import {Close, Edit, FilterList, MedicalServicesOutlined} from "@mui/icons-material";
import CrearVisita from "../Visitas/crearVisita";
import ConfirmDialog from "../../components/ConfirmDialog";
import {ValidatorFormCustom} from "../../helpers/form-validator";

import SelectSearchCentroMedico from "../../components/selects/SelectSearchCentroMedico";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState:IMedicoFilter={
  name: '',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:false
};
function Medicos(){
  let navigate =useNavigate();
  const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();

  const Dispatch = useAppDispatch();
  const [openMenu, setOpenMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    medico: string;
    medicoId: number
  } | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLTableRowElement>, medico: IMedico) => {
    setOpenMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      medico: medico.medNombre??"",
      medicoId: medico.id!
    });
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };


  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.medicos)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarMedicos());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarMedicos({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,page:0,[name]:value})
  };
  const resetFilter = () => {
    setFilter(initialState);
    Dispatch(mostrarMedicos({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarMedicos(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarMedicos({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>

    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectSearchCentroMedico
                  required
                  value={{
                    centroMedicoId: (filter.centroMedicoId ?? "").toString(),
                    centroMedico: filter.centroMedico ?? ""
                  }}
                  onChange={(v)=>setFilter(prevState => ({
                    ...prevState,
                    centroMedico:v.centroMedico,
                    centroMedicoId:v.centroMedicoId?parseInt(v.centroMedicoId):undefined
                  }))}
              />
            </Grid>
          </Grid>

        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>





    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}

      <ListViewMode onNew={hasPermissionTo(PERSMISOS.medicosCrear) ? ()=>navigate("/crear/medico") : undefined} noView>
        <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
        <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
        <FilteredAlert onClose={resetFilter} filter={filtered}/>
      </ListViewMode>

      <TableSort data={[
        {pro:"medCodigo",text:Strings.codigo},
        {pro:"medNombre",text:Strings.nombres},
        {pro:"medNumLicencia",text:Strings.licencia},
        {pro:"medTelefono",text:Strings.telefono},
        {pro:"medTelefono2",text:Strings.celular},
        {pro:"medPuntos",text:Strings.puntos},
        {pro:"centroMedicoId",text:Strings.centroMedico},
        {pro:"visitadorId",text:Strings.visitador},
        {pro:"cenDireccion",text:Strings.direccion},
      ]} onSort={tableSortProps} lessVh={155}>
        <TableBody>
          {!isEmpty && list?.results.map((med:IMedico) =>
              <TableRow key={med.id}
                        onClick={(event)=> {
                          handleOpenMenu(event, med)
                        }}
                        sx={openMenu?.medicoId === med.id ? {background: "#e6e6e6 !important"}:(!!med?.visitaAbierta ? {background: "#ffecd0 !important"}: (med.visitado? {background: "#c3eeff !important"}: {}))}
              >
                <TableCell>{med.medCodigo}</TableCell>
                <TableCell>{med.medNombre}</TableCell>
                <TableCell>{med.medNumLicencia}</TableCell>
                <TableCell>{med.medTelefono}</TableCell>
                <TableCell>{med.medTelefono2}</TableCell>
                <TableCell>{med.medPuntos}</TableCell>
                <TableCell>{med.centroMedico}</TableCell>
                <TableCell>{med.visitador}</TableCell>
                <TableCell>{med.medDireccion}</TableCell>
              </TableRow>
          )}
        </TableBody>
      </TableSort>
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarMedicos({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />


      <Menu
          id="basic-menu"
          // anchorEl={openMenu?.anchorEl}
          anchorReference="anchorPosition"
          open={Boolean(openMenu)}
          onClose={handleCloseMenu}
          anchorPosition={
            openMenu !== null
                ? { top: openMenu.mouseY, left: openMenu.mouseX }
                : undefined
          }
      >
        <MenuItem disabled sx={{
          color: "#0b0f9f",
          opacity: "1 !important"
        }}>{openMenu?.medico}</MenuItem>
        {hasPermissionTo(PERSMISOS.medicosActualizar) &&
          <MenuItem
              onClick={()=> {
                handleCloseMenu()
                navigate("/editar/medico/" + openMenu?.medicoId)
              }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar Médico</ListItemText>

          </MenuItem>
        }
        {hasPermissionTo(PERSMISOS.visitasCrear) &&

          <MenuItem
              onClick={()=>{
                handleCloseMenu()
                navigate(pathname, {state: {crearVisita: {medicoId: openMenu?.medicoId, medico: openMenu?.medico}}})
              }}
          >
            <ListItemIcon>
              <MedicalServicesOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Crear Visita</ListItemText>
          </MenuItem>
        }
        {hasPermissionTo(PERSMISOS.medicosEliminar) &&
          <MenuItem
              onClick={()=>{
                handleCloseMenu()
                navigate(pathname, {state: {"delMedico": openMenu?.medicoId}})
              }}
              sx={{color: "#a80c0c",}}
          >
            <ListItemIcon>
              <Close sx={{color: "#a80c0c"}} fontSize="small" />
            </ListItemIcon>
            <ListItemText>Eliminar Médico</ListItemText>
          </MenuItem>
        }
      </Menu>
    </section>

    {!!urlState?.delMedico &&
        <ConfirmDialog onConfirm={()=>Dispatch(eliminarMedico(urlState.delMedico))}/>
    }
    <CrearVisita/>
  </>;
}

export default Medicos;

