import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getTransacciones} from '../../services';
import {ITransaccionState, ITransaccionRes} from "../types/ITransaccion";
const initialState: ITransaccionState = {
    status: "idle"
}

export const mostrarTransacciones = createAsyncThunk(
    'mostrarTransacciones', async (params:object|undefined ) => {
        const response = await getTransacciones(params)
        return {data: response.data as ITransaccionRes, params: response.config.params}
    }
)


const slice = createSlice({
    name: 'mostrarTransacciones',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarTransacciones
        builder.addCase(mostrarTransacciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarTransacciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarTransacciones.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
