import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import Strings from "../../assets/strings";
import {IIdNameAccion} from "../../redux/types/Icommon";
import {errorMessagesC, SelectValidatorCustom} from "../../helpers/form-validator";

type TProps = {
    label?:string,
    value:IIdNameAccion,
    list:IIdNameAccion[],
    loading?:boolean,
    required?:boolean,
    disabled?:boolean,
    onChange: (val:IIdNameAccion)=>void

}

let emptyValue:IIdNameAccion = {
    name:""
}

export default function SelectList({ value,label=Strings.pais,list ,loading ,disabled,required, onChange}:TProps) {
    const handleChange = (e:SelectChangeEvent)=>{
        const {value} = e.target
        let selected = list.find(l=>l.id===parseInt(value));
        onChange(selected?selected:emptyValue)
    }
    const validators=required?['required']:[]

    return (
        <SelectValidatorCustom
            {...{value:value.id? value.id.toString():"", validators,disabled,loading,name:"", errorMessages:errorMessagesC(validators), label, onChange:handleChange}}
        >
            <MenuItem aria-label="None" value="" >{loading?Strings.cargando+"...":Strings.seleccione}</MenuItem>
             {list.map(item=>
                 <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
             )}
        </SelectValidatorCustom>
    )
    // return (
    //     <FormControl fullWidth disabled={disabled} required={required}>
    //         <InputLabel>{label}</InputLabel>
    //         <Select
    //             native
    //             value={value.id? value.id.toString():""}
    //             onChange={handleChange}
    //         >
    //             <option aria-label="None" value="" >{loading?Strings.cargando+"...":Strings.seleccione}</option>
    //             {value.id && !list.find(l=>l.id===value.id) &&
    //                 <option value={value.id.toString()} >{value.name}</option>
    //             }
    //             {list.map(item=>
    //                 <option value={item.id} key={item.id}>{item.name}</option>
    //             )}
    //         </Select>
    //     </FormControl>
    // );
}
