import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Strings from "../../assets/strings";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Typography,Button} from '@mui/material';
import {getLSLang, setLSLang} from "../../helpers";
import Notifications from "react-notification-system-redux";
import {useAppSelector} from "../../redux/hooks";
import makeStyles from "@mui/styles/makeStyles";
import {Theme} from "@mui/material";
import {Link} from "react-router-dom";
import logo from '../../assets/img/logoBlue.png';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Typography component={"a"} variant="body2" color="inherit" href="https://laboratoriosintesis.com/" target={"_blank"} rel={""}>
                Sintesis
            </Typography>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const useStyles = makeStyles((theme:Theme) => ({
    root:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height: "100%",
        background:theme.palette.grey[100],
        [theme.breakpoints.down('sm')]: {
            height: "auto",
        },
    },
    content:{
        width:800,
        height:600,
        [theme.breakpoints.down('sm')]: {
            width:"100%",
            height: "auto",
        },
    },
    rightSide:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background:'common.white',
        textAlign:"center",
        height: "100%",
        padding: "0 28px",
        fontSize: 30,
        fontWeight: 500,
        "& img":{
            width: 226,
            marginBottom: 11,
        },
        "& p":{
            fontSize: "0.9rem",
            marginBottom: 10,
        }
    }
}));
const style = {

    NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
            margin: '10px 5px 2px 1px',
            borderRadius: '0px',
            boxShadow:"none",
        },
    },
    Dismiss: {
        DefaultStyle: {
            backgroundColor: "none",
            color: '#bbbbbb',
        },
    }
};
export default function LayOutLogin({children}:{children:any}) {
    const notifications = useAppSelector(state=> state.notifications);
    const classes = useStyles();

    const [state, setState] = useState({
        lang:getLSLang("l"),
    });

    return (
           <div className={classes.root}>
               <Paper className={classes.content}>
                   <Grid container component="main" sx={{ height: '100%' }}>
                       <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }} sx={{
                           position: "relative",
                           display: "inline-grid",
                           alignItems: "center"
                       }}>
                           <Box
                               sx={(theme)=>({
                                   my: 8,
                                   mx: 4,
                                   display: 'flex',
                                   flexDirection: 'column',
                                   alignItems: 'center',
                                   [theme.breakpoints.down('sm')]: {
                                       margin: "28px",
                                   },
                               })}
                           >
                               <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                   <LockOutlinedIcon />
                               </Avatar>

                               {children}

                               <Copyright sx={{ mt: 5 }} />
                               <Box mt={2} sx={{display:"none"}}>
                                   <select value={state.lang} onChange={(e)=>{
                                       let lang = e.target.value;
                                       setLSLang(lang);
                                       Strings.setLanguage(lang);
                                       setState({...state,lang});
                                   }}>
                                       {Strings.getAvailableLanguages().map(l=>
                                           <option key={l} value={l}>{(Strings as any)[l]}</option>
                                       )}
                                   </select>
                               </Box>
                           </Box>
                       </Grid>
                       <Grid
                           item
                           xs={false}
                           sm={6}
                           md={6}
                           order={{ xs: 1, sm: 2 }}
                           sx={(theme)=>({
                               background:theme.palette.grey[200],
                               [theme.breakpoints.down('sm')]: {
                                   padding: "44px",
                               },

                           })}
                       >
                           <Box className={classes.rightSide}>
                               <img src={logo} alt=""/>
                               <Typography>
                                   Bienvenido al programa <Typography color={"primary"} component={"span"}>ProLeal</Typography> de Laboratorio Síntesis, diseñado para el servicio a
                                   los usuarios en lealtad de nuestro productos.
                               </Typography>
                               <Typography>
                                   <Typography color={"primary"} component={"span"}>ProLeal</Typography>  es un programa de ayuda al paciente que recibe recetas y tarjetas con descuento en
                                   medicamentos para uso ambulatorio. Pensado para contribuir a facilitar al paciente el acceso y
                                   cumplimiento del tratamiento indicado por su médico.
                               </Typography>
                               <Button variant={"outlined"} component={Link}  to="/escanearCodigo">Escanea tu Código QR</Button>
                           </Box>
                       </Grid>

                   </Grid>
                   <Box sx={{
                       textAlign: "center",
                       width: "100%",
                       pt: "23px",
                       pb: "13px",
                   }}>
                       <Typography variant="caption" color="text.secondary" component={"a"} href={"https://dsegura.net/"} target={"_Blank"}>Desarrollado por Dsegura</Typography>
                   </Box>
               </Paper>
               <Notifications notifications={notifications} style={style} />

           </div>
    );
}
