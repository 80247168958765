import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getRecetas,addRecetaPublic, addEditReceta, getRecetaById, delReceta} from '../../services';
import {IRecetaState, IReceta, IRecetaRes} from "../types/IReceta";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
import {AppState} from "./rootReducer";
const initialState: IRecetaState = {
    status: "idle"
}

export const mostrarRecetas = createAsyncThunk(
    'mostrarRecetas', async (params:object|undefined,thunkAPI ) => {
        const {recetas} = thunkAPI.getState() as AppState;
        let paramsFilter = params || recetas.filtered;
        const response = await getRecetas(paramsFilter)
        return {data: response.data as IRecetaRes, params: response.config.params}
    }
)
export const mostrarRecetaById = createAsyncThunk(
    'mostrarRecetaById', async (id:number ) => {

        const response = await getRecetaById(id)
        return response.data as IReceta
    }
)

export const agregarEditarReceta = createAsyncThunk(
    'Recetas/agregarEditar', async (receta: IReceta) => {
        const response = await addEditReceta(receta);

        if (response.status === 200) {
            if(!receta.id){
                store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Este regitro esta pendiente a autorización por un administrador.", autoDismiss: 8,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!receta.id};
    }
);
export const agregarReceta = createAsyncThunk(
    'receta/agregar', async (id: number) => {
        const response = await addRecetaPublic(id);
        if (response.status === 200 || response.status === 204) {
            historyHelper.push(historyHelper.location.pathname,response.data);
        }

        return {data: response.data};
    }
);
export const eliminarReceta = createAsyncThunk(
    'receta/eliminar', async (id: number) => {
        const response = await delReceta(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarRecetas',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarRecetas
        builder.addCase(mostrarRecetas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarRecetas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarRecetas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarReceta
        builder.addCase(agregarEditarReceta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarReceta.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarReceta.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarRecetaById
        builder.addCase(mostrarRecetaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarRecetaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarRecetaById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarReceta
        builder.addCase(eliminarReceta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarReceta.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarReceta.rejected, (state ) => {
            state.status = "idle";
        })
        //--generar Usuario
        builder.addCase(agregarReceta.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarReceta.fulfilled, (state,action ) => {
            state.status = "idle";
          //  state.byId = {...state.byId, usuarioId:action.payload.data.id}
        })
        builder.addCase(agregarReceta.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
