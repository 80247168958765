import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress, ListItem, ListItemText} from '@mui/material';
import Strings from "../../assets/strings";
import {getUsuarios} from "../../services";
import {TextValidatorCustom} from "../../helpers/form-validator";
import {IUsuario} from "../../redux/types/IUsuarios";

type TProps = {
    tipo:number,
    label?:string,
    value:TValue,
    required?:boolean,
    disabled?:boolean,
    onChange: (val:TValue)=>void

}
type TValue = {
    id?:string,
    nombre:string,
}
var searchTimeout:ReturnType<typeof setTimeout>;

let emptyValue = {
    id:"",
    nombre:""
}
export default function SelectSearchUsuario({ tipo, value,label=Strings.usuario,required,disabled, onChange}:TProps) {
    const [options, setOptions] = React.useState<TValue[]>([]);
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        return ()=>clearTimeout(searchTimeout)
    })
    const fetchUsuario = (name:string) => {
        setLoading(true)
        getUsuarios({name, tipo}).then(res=>{
            let result = (res.data.results) as IUsuario[];
            setOptions(result?.map(r=>({id:r.id!.toString(),nombre:r.usuNombre??""})))
        }).finally(()=>setLoading(false))
    }

    return (

        <React.Fragment>
            <Autocomplete
                fullWidth
                disabled={disabled}
                loading={loading}
                aria-required={required}
                onOpen={()=> {
                    if(options.length) return false;
                    fetchUsuario("")
                }}
                options={[value,...options.filter((r) =>r.id!==value.id)]}
                getOptionLabel={(option) => option.nombre}
                value={value}
                isOptionEqualToValue={(option, value) => option.id === value.id}

                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                    onChange(newValue??emptyValue)
                }}
                // inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        fetchUsuario(newInputValue)
                    },1000);
                }}
                renderOption={(props, option) => (
                    <ListItem  {...props}>
                        <ListItemText primary={option.nombre} />
                    </ListItem>
                )}
                renderInput={(params) => (
                    <TextValidatorCustom
                        {...params}
                        name={""}
                        value={value.id}
                        label={label}
                        validators={required?['required']:[]}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </React.Fragment>

    );
}
