import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getArticulosCategorias,addEditArticulosCategoria,delArticulosCategoria} from '../../services';
import {IArticulosCategoriaState, IArticulosCategoriaRes, IArticulosCategoria} from "../types/IArticulosCategorias";
import historyHelper from "../../helpers/history";
const initialState: IArticulosCategoriaState = {
    status: "idle"
}

export const mostrarArticulosCategorias = createAsyncThunk(
    'mostrarArticulosCategorias', async (params?:object|undefined ) => {
        const response = await getArticulosCategorias(params)
        return {data:response.data as IArticulosCategoriaRes, params: response.config.params}
    }
)
export const agregarEditarArticuloCategoria = createAsyncThunk(
    'articulosCategorias/agregarEditar', async (articulosCategoria: IArticulosCategoria) => {
        const response = await addEditArticulosCategoria(articulosCategoria);

        if (response.status === 200) historyHelper.back();
        return {data: response.data, edit:!!articulosCategoria.id};
    }
);
export const eliminarArticuloCategoria = createAsyncThunk(
    'articulosCategorias/eliminar', async (id: number) => {
        await delArticulosCategoria(id);
        return id;
    }
);
const slice = createSlice({
    name: 'mostrarArticulosCategorias',
    initialState,
    reducers: {
        changeStatus: (state,{payload}) => {
            state.status = payload;
        },
    },
    extraReducers: builder => {
        //--mostrarArticulosCategorias
        builder.addCase(mostrarArticulosCategorias.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarArticulosCategorias.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarArticulosCategorias.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarArticuloCategoria
        builder.addCase(agregarEditarArticuloCategoria.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarArticuloCategoria.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarArticuloCategoria.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarArticuloCategoria
        builder.addCase(eliminarArticuloCategoria.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarArticuloCategoria.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarArticuloCategoria.rejected, (state ) => {
            state.status = "idle";
        })
    }

});

export const { changeStatus } = slice.actions;



export default slice.reducer;
