import React, {useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {Check, Close} from "@mui/icons-material";
import Strings from "../../assets/strings";
import Typography from "@mui/material/Typography";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Box from "@mui/material/Box";
import SelectSearchArticulo from "../../components/selects/SelectSearchArticulo";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";
import _ from "lodash";
import {IVisita} from "../../redux/types/IVisita";
import {IEntrega} from "../../redux/types/IEntrega";

let initialState:IEntrega={
    accion: 1,
    articuloId: undefined,
    articulo:"",
    cantidad: 0,
};
type Iprops = {
    visitaState?:IVisita,
    onChange: (vars:IEntrega[])=>void
}

export default  function ArticuloDetalles({visitaState, onChange}:Iprops){

    const [state, setState] = useState(initialState);
    const [error, setError] = useState<string>("");


    const [openNewVar, setOpenNewVar] = useState(false);
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const deleteDet = (varIndex:number) =>{
        let vars = [...(visitaState?.entregas ?? [])];
        let itemToDelete = {...vars[varIndex]};

        if(itemToDelete.accion === 0){
            itemToDelete.accion = 3;
            vars.splice(varIndex, 1,itemToDelete);
        }else if(itemToDelete.accion === 3){
            itemToDelete.accion = 0;
            vars.splice(varIndex, 1,itemToDelete);
        }else{
            vars.splice(varIndex, 1);
        }
        onChange(vars);
    };
    const addDetalle = ()=>{
        setError("");
        let vars = [...(visitaState?.entregas ?? [])];
        let datos = {..._.omitBy(state, (v) => v === '' || _.isNil(v))};


            if(datos.id){
                let varIndex = vars.findIndex((i=>i.id === datos.id));
                datos.accion = 2; //si tiene un id, se esta editando.
                vars.splice(varIndex, 1,datos);
            }else{
                vars.unshift(datos);
            }
            onChange(vars);
            closeDialog();


            setState(initialState)
    };
    const closeDialog = ()=>{
        setState(initialState)
        setOpenNewVar(false)
    }

    return <>
        <div>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    Productos
                </Typography>
                <Button color={"primary"} onClick={()=>setOpenNewVar(!openNewVar)}>
                    {Strings.agregar}
                </Button>
            </Box>
            {!!visitaState?.entregas?.length &&
                <TableContainer>
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.articulo}</TableCell>
                                <TableCell>Cantidad</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visitaState?.entregas.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <TableRow sx={{textDecoration:item.accion===3?"line-through":""}} onClick={()=> {
                                            if(item.accion===3 || item.accion===1) return false;
                                            setState(item);
                                            setOpenNewVar(true);
                                        }}>
                                            <TableCell className="actionCell" onClick={(e)=>e.stopPropagation()}>
                                                <IconButton onClick={()=>deleteDet(i)} size="large">
                                                    {item.accion===3?(
                                                        <Check className="text-blue"/>
                                                    ):(
                                                        <Close className="text-red"/>
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>{item.articulo}</TableCell>
                                            <TableCell>{item.cantidad}</TableCell>
                                        </TableRow>
                                    </React.Fragment>

                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
        <Dialog maxWidth="xs" onClose={closeDialog} open={!!openNewVar}>
            <TopBarDialog title={(state.id?Strings.actualizar:Strings.agregar)+" "+Strings.detalles} onClose={closeDialog} nofullScreen/>

            <DialogContent>
                {error && <Alert severity="warning">{error}</Alert>}
                <ValidatorFormCustom id={"ofertaDetalleForm"} onSubmit={addDetalle}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectSearchArticulo
                                required
                                value={{
                                    articuloId: (state.articuloId ?? "").toString(),
                                    articulo: state.articulo ?? ""
                                }}
                                onChange={(v)=>setState(prevState => ({
                                    ...prevState,
                                    articulo:v.articulo,
                                    articuloId:v.articuloId?parseInt(v.articuloId):undefined
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidatorCustom
                                type={"number"}
                                name={"cantidad"}
                                label={"Cantidad"}
                                value={state.cantidad??""}
                                onChange={handleChange}
                                inputProps={{min: 0}}
                                validators={['required']}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                    </Grid>
                </ValidatorFormCustom>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">{Strings.cancelar}</Button>
                <Button color="primary" type={"submit"} form={"ofertaDetalleForm"} >{Strings.guardar}</Button>
            </DialogActions>
        </Dialog>
    </>;
}
