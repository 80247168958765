import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {ICanje} from "../../redux/types/ICanje";
import Strings from "../../assets/strings";
import {mostrarCanjeById} from "../../redux/reducers/canjesReducers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";


export default function DetalleCanje() {
    const {byId,status} = useAppSelector(state => state.canjes);

    const {state:urlState}:{state:any} = useLocation();
    const navigate =useNavigate();
    const Dispatch = useAppDispatch()

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState<ICanje|null>(null);

    useEffect(() => {
        if(!!urlState && urlState.canje) {
            setState(state => ({...state, ...(nullToEmptyString(urlState.canje) as ICanje)}));
        }else {
            setState(null);
        }
    }, [setState, urlState]);


    useEffect(()=>{
        if(urlState?.canje && urlState.canje.id !== byId?.id){
            Dispatch(mostrarCanjeById(Number(urlState.canje.id)));
        }else if(byId){
            setState(state => ({...state, ...nullToEmptyString(byId) as ICanje}));
        }
    },[byId,setState,urlState,Dispatch])

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("canje"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={Strings.canje} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0.5}}>
                    {status === "loading" && <LoadingProgress />}
                    {!!state &&
                        <>
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.medico}</Typography>
                                        <Typography variant={"caption"}>{Strings.medico}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.canPuntos}</Typography>
                                        <Typography variant={"caption"}>{Strings.puntos}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{!!state.canInactivo ? Strings.inactivo : Strings.activo}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>
                                    {state.canNota &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{state.canNota}</Typography>
                                            <Typography variant={"caption"}>{Strings.comentario}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </>
                    }
                    <br/>
                </DialogContent>
            </Dialog>
        </>
    );

}

