import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {mostrarAutorizaciones} from "../../redux/reducers/autorizacionesReducers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ButtonResponsive, ListViewMode, LoadingProgress} from '../../components';

import {FilterList} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Strings from "../../assets/strings";
import {SelectSmart} from "../../components/CustomTextField";
import {formatDate} from "../../helpers";
import {
  AUTORIZACION_ESTADO,
  getValueString,
  objToKeyValue,
  TIPO_AUTORIZACION,
} from "../../helpers/CONSTANTES";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IAutorizacion} from "../../redux/types/IAutorizacion";
import AutorizacionDetalle from "./AutorizacionDetalle";
import TopAppBar from "../../components/TopAppBar";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1),
    "& tr.aprobada":{
      background: "#eff8ff !important"
    },
    "& tr.rechazada":{
      background: "#fff3f3 !important"
    },
  }
}));

const initialFilter={
  estado: "1",
  tipo:""
};

function Autorizaciones(){
  let navigate =useNavigate();
  const {pathname}= useLocation();

  const Dispatch = useAppDispatch();

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialFilter);
  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    setFilter(prevState => ({...prevState,[name]:type === "checkbox"?checked:value}))
  };


  const { list, status} = useAppSelector(state => state.autorizaciones)


  useEffect(() => {
    Dispatch(mostrarAutorizaciones({estado: "1"}));
  }, [Dispatch]);


  return <>
    <TopAppBar/>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.estado}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.estado ?? ""}
                  onChange={handleFilter}
                  name="estado"
                  opciones={[
                    {key: '0', value: Strings.todo},
                    ...objToKeyValue(AUTORIZACION_ESTADO)
                  ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.tipo}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.tipo ?? ""}
                  onChange={handleFilter}
                  name="tipo"
                  opciones={objToKeyValue(TIPO_AUTORIZACION)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={()=> {
          setOpenFilter(false)
          Dispatch(mostrarAutorizaciones(filter));
        }}>oK</Button>
      </DialogActions>
    </Dialog>

    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      <ListViewMode noView>
        <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
      </ListViewMode>
      {status === "loading" && <LoadingProgress vh/>}
      <TableContainer>
        <Table className={"hasOnclick"}>
          <TableHead>
            <TableRow>
              {/*<TableCell/>*/}
              <TableCell>ID</TableCell>
              <TableCell>{Strings.estado}</TableCell>
              <TableCell>{Strings.tipo}</TableCell>
              <TableCell>{Strings.comentario}</TableCell>
              <TableCell>{Strings.fecha}</TableCell>
              <TableCell>{Strings.fecha} {Strings.actualizacion}</TableCell>
              <TableCell>{Strings.autorizadoPor}</TableCell>
              <TableCell>{Strings.motivo}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((aut:IAutorizacion) =>
                <TableRow key={aut.id} className={getValueString(AUTORIZACION_ESTADO,aut?.autEstado, false)} onClick={()=> navigate(pathname, {state: {autdetalle:aut}})}>
                  {/* <TableCell className="actionCell" sx={{width: "108px"}} onClick={(e)=>e.stopPropagation()}>
                   {aut.autEstado === 1 && <>
                     <HtmlTooltip title={Strings.aprobar}>
                       <IconButton onClick={()=> {
                         navigate(location.pathname, {state: {id: aut.id,action:"aprobar"}})
                       }}><Check className="text-blue"/></IconButton>
                     </HtmlTooltip>
                     <HtmlTooltip title={Strings.rechazar}>
                       <IconButton onClick={()=> {
                         navigate(location.pathname, {state: {id: aut.id,action:"rechazar"}})
                       }}><Close className="text-red"/></IconButton>
                     </HtmlTooltip>
                   </>}
                 </TableCell>  */}
                  <TableCell>{aut.id}</TableCell>
                  <TableCell>
                    {(() => {
                      switch (aut.autEstado) {
                        case 2:   return <span className="text-blue">{Strings.aprobada}</span>;
                        case 3: return <span className="text-red">{Strings.rechazada}</span>;
                        default: return Strings.pendiente;
                      }
                    })()}
                  </TableCell>
                  <TableCell>{aut.autTipoString}</TableCell>
                  <TableCell>{aut.autComentario}</TableCell>
                  <TableCell>{formatDate(aut.fechaCreacion)}</TableCell>
                  <TableCell>{formatDate(aut.fechaActualizacion)}</TableCell>
                  <TableCell>{aut.actualizadoPorNombre}</TableCell>
                  <TableCell>{aut.motivosRechazo}</TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </section>

    <AutorizacionDetalle/>

  </>;
}

export default Autorizaciones;

