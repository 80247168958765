import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarParametro, mostrarParametros} from "../../redux/reducers/parametrosReducers";

import {IconButton, TableBody, TableCell, TableRow, Theme,} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {HtmlTooltip, ListViewMode, LoadingProgress, TableSort} from '../../components';

import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IParametro} from "../../redux/types/IParametro";
import Crear from "./Crear";
import ConfirmDialog from "../../components/ConfirmDialog";
import TopAppBar from "../../components/TopAppBar";
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

function Parametros(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();


  const { list, status} = useAppSelector(state => state.parametros)

  const isEmpty = (!list || list.length === 0);

  useEffect(() => {
    Dispatch(mostrarParametros());
  }, [Dispatch]);


  return <>

    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}

      <div>

        <ListViewMode  noView />
        {/*onNew={()=>navigate(location.pathname,{state:{parametro:{id:undefined}}})}*/}
        <TableSort data={[
          {pro:"",text:""},
          {pro:"parTipo",text:Strings.tipo},
          {pro:"parValor",text:"Valor"},
        ]} onSort={()=>{}} lessVh={155}>
          <TableBody>
            {!isEmpty && list?.map((item:IParametro) =>
                <TableRow key={item.parTipo} onClick={()=> {
                  navigate(location.pathname, {state: {parametro: {...item}}})
                }}>
                  <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                        <HtmlTooltip title={Strings.anular}>
                          <IconButton size={"small"}
                                      disabled={!item.id}
                                      onClick={(e)=> {
                                        navigate(location.pathname, {state: {confirmDialog: item.id}})
                                      }}>
                            <Close fontSize={"small"} className={item.id?"text-red":"text-gray"}/>
                          </IconButton>
                        </HtmlTooltip>
                  </TableCell>
                  <TableCell>{item.parTipoString}</TableCell>
                  <TableCell>{item.parValor}</TableCell>
                </TableRow>
            )}
          </TableBody>
        </TableSort>
      </div>

    </section>



    <Crear/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={() => Dispatch(eliminarParametro(urlState.confirmDialog))}/>
    }
  </>;
}

export default Parametros;

