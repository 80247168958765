import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TopAppBar from "../../components/TopAppBar";
import {Typography} from "@mui/material";
import {getUsuTipo,TIPO_USUARIO} from "../../helpers";
import HomeCounter from "../../components/homeWidget/HomeCounter";

const useStyles = makeStyles(theme => ({
    root:{
        [theme.breakpoints.down('md')]: {
            padding:theme.spacing(1)
        }
    },
    welcome:{
        margin: "0 auto",
        marginTop:"10%",
        maxWidth:900,
        textAlign: "justify",
        boxShadow: "1px 1px 10px #d4d4d4",
        padding: "20px",

        "& p":{
            marginBottom: "11px",
        }
    },
}));
export default function DashBoard() {
    const classes = useStyles();



    return (
        <>
            <TopAppBar/>
            <div className={"contentInner "+classes.root}>
                <HomeCounter/>
                {getUsuTipo() !== TIPO_USUARIO.farmacia &&
                    <div className={classes.welcome}>
                        <Typography>
                            Bienvenido al programa <Typography color={"primary"} component={"span"}>ProLeal</Typography> de Laboratorio Síntesis, diseñado para el servicio a
                            los usuarios en lealtad de nuestro productos.
                        </Typography>
                        <Typography>
                            <Typography color={"primary"} component={"span"}>ProLeal</Typography>  es un programa de ayuda al paciente que recibe recetas y tarjetas con descuento en
                            medicamentos para uso ambulatorio. Pensado para contribuir a facilitar al paciente el acceso y
                            cumplimiento del tratamiento indicado por su médico.
                        </Typography>
                    </div>
                }
                {getUsuTipo() === TIPO_USUARIO.farmacia &&
                    <div className={classes.welcome}>
                        <Typography>
                            Bienvenido al programa <Typography color={"primary"} component={"span"}>ProLeal</Typography> de Laboratorio Síntesis, en esta página tiene acceso para
                            registrar la receta, la tarjeta de descuento o puede registrar las ventas de impulso realizadas.
                        </Typography>
                        <Typography>
                            Gracias por confiar en nuestros servicios.
                        </Typography>
                    </div>
                }
            </div>
        </>
    )
}


