const errorToNotifObj = (obj)=>{
    let notifObj = { title: obj.title??"Error", message: obj.message??"", autoDismiss: 5};

    if(Object.keys(obj.errors ?? {}).length){
        Object.keys(obj.errors).forEach(prop=>{
            notifObj.message += prop+": "+obj.errors[prop][0]
        })
    }
    if(obj.status === 404) return {...notifObj,title:"Error 404"};

    if(obj === "string"){
        notifObj.message = obj;
    }
    return notifObj

}
export default errorToNotifObj
