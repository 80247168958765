import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {formatDate, hasPermissionTo, nullToEmptyString, PERSMISOS, getUsuTipo, TIPO_USUARIO} from '../../helpers';
import {
    Box,
    Button,
    Grid,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {eliminarPaciente, generarUsuarioPaciente, mostrarPacienteById} from "../../redux/reducers/pacientesReducers";
import {IPaciente} from "../../redux/types/IPacientes";
import ConfirmDialog from "../../components/ConfirmDialog";
import CrearUsuario from "../Usuarios/CrearUsuario";
import ListTransacciones from './ListTransacciones';
import {Close, Edit,EmailOutlined} from '@mui/icons-material';
import {AccountOutline} from "mdi-material-ui";
import {getUsuarioById} from '../../services';
import TopAppBar from "../../components/TopAppBar";
import SendSMS from "../../components/SendSMS";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        position:"relative",
        marginBottom:theme.spacing(1)
    },
    avatar:{
        background:"#f1f1f1",
        width: 100,
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 50,
        margin: "0 auto",
        marginBottom: theme.spacing(1),
        border: "1px solid #b6b6b6",
        "& svg":{
            fontSize: 54,
            color: "#b6b6b6",
        }
    },
    table:{
        "& tr":{
            background:"#fff !important"
        }
    }
}));

let initialState:IPaciente = {
    id: undefined,
    pacNombres: "",
    pacApellidos: "",
    pacTipoDocumento: undefined,
    pacTipoDocumentoString: "",
    pacDocIdentidad: "",
    pacEmail: "",
    pacTelefono: "",
    pacTelefono2: "",
    paisId: undefined,
    pais: "",
    provinciaId: undefined,
    provincia: "",
    municipioId: undefined,
    municipio: "",
    sectorId: undefined,
    sector: "",
    pacDireccion: "",
    pacComentario: "",
    pacFechaNacimiento: "",
    pacSexo: "",
    usuarioId: undefined,
    pacInactivo: false,
    tarjeta:"",
    actualizadoPor: undefined,
    creadoPor: undefined,
    fechaCreacion: "",
    fechaActualizacion: "",
}
function CrearPaciente(){
    const classes = useStyles();
    const navigate = useNavigate()
    const Dispatch = useAppDispatch();
    let { id:urlFarId} = useParams()
    const {state:urlState, pathname}:{state:any,pathname:string} = useLocation();
    const {byId:paciente,status} = useAppSelector(state => state.pacientes);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlFarId) Dispatch(mostrarPacienteById(Number(urlFarId)));
    },[urlFarId,Dispatch])
    useEffect(()=>{
        if(!!urlFarId && paciente?.id === parseInt(urlFarId)) {
            setState({...initialState, ...nullToEmptyString(paciente)});
        }else{
            setState(initialState)
        }
    },[urlFarId,paciente])


    const [anchorEl, setAnchorEl] =  React.useState<null | HTMLElement>(null);
    const verUsuario = (id:number) => {
        getUsuarioById(id).then(({data})=>{
            navigate(pathname, {state: {usuario:data}})
        })
    }
    return(
        <>
            <TopAppBar/>

            <div className={"contentInner "+classes.root} >

                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={4}>
                                <Paper elevation={0} variant="outlined" square className={classes.paper}>
                                    <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                                        <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                                            {Strings.paciente}
                                        </Typography>
                                        <Box display='flex' alignItems="center">

                                            <div>
                                                <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)} size="small" sx={{ ml: 2 }}>
                                                    {Strings.opciones}
                                                </Button>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={()=>setAnchorEl(null)}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            '& .MuiAvatar-root': {
                                                                width: 32,
                                                                height: 32,
                                                                ml: -0.5,
                                                                mr: 1,
                                                            },
                                                            '&:before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                >
                                                    <MenuItem onClick={()=> {
                                                        setAnchorEl(null);
                                                        navigate(pathname,{state:{sendSMS:{pacienteId: state.id , paciente:state.pacNombres}}})
                                                    }}>
                                                        <ListItemIcon>
                                                            <EmailOutlined fontSize="small" />
                                                        </ListItemIcon>
                                                        Enviar notificación
                                                    </MenuItem>
                                                    {(getUsuTipo() === TIPO_USUARIO.admin || hasPermissionTo(PERSMISOS.seguridad)) &&
                                                        <MenuItem
                                                            onClick={() => {
                                                                setAnchorEl(null)
                                                                if (state.usuarioId) {
                                                                    verUsuario(state.usuarioId)
                                                                } else if (hasPermissionTo(PERSMISOS.pacientesGenerarUsuario)) {
                                                                    Dispatch(generarUsuarioPaciente(state.id!))
                                                                }
                                                            }}>
                                                            <ListItemIcon>
                                                                <AccountOutline fontSize="small"/>
                                                            </ListItemIcon>
                                                            <ListItemText>{state.usuarioId ? <>Ver</> : <>Crear</>} {Strings.usuario.toLowerCase()}</ListItemText>
                                                        </MenuItem>
                                                    }

                                                    <MenuItem onClick={()=> {
                                                        setAnchorEl(null);
                                                        navigate(pathname, {state:{cancelSuscription:state.id}})
                                                    }}>
                                                        <ListItemIcon>
                                                            <Close fontSize="small" />
                                                        </ListItemIcon>
                                                        Cancelar la suscripción
                                                    </MenuItem>
                                                    <MenuItem onClick={()=> {
                                                        setAnchorEl(null);
                                                        navigate("/editar/paciente/"+state.id)
                                                    }}>
                                                        <ListItemIcon>
                                                            <Edit fontSize="small" />
                                                        </ListItemIcon>
                                                        {Strings.editar}
                                                    </MenuItem>
                                                </Menu>
                                            </div>




                                        </Box>

                                    </Box>
                                    <TableContainer>
                                        <Table className={classes.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.nombres}</TableCell>
                                                    <TableCell align="right">{state.pacNombres} {state.pacApellidos}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.cedula}</TableCell>
                                                    <TableCell align="right">{state.pacDocIdentidad}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.telefono}</TableCell>
                                                    <TableCell align="right">{state.pacTelefono}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.celular}</TableCell>
                                                    <TableCell align="right">{state.pacTelefono2}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.email}</TableCell>
                                                    <TableCell align="right">{state.pacEmail}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.sexo}</TableCell>
                                                    <TableCell align="right">{state.pacSexo}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.fechaNacimiento}</TableCell>
                                                    <TableCell align="right">{formatDate(state.pacFechaNacimiento)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.estado}</TableCell>
                                                    <TableCell align="right">{state.pacInactivo?Strings.inactivo:Strings.activo}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.tarjeta}</TableCell>
                                                    <TableCell align="right">{state.tarjeta??"-"}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.pais}</TableCell>
                                                    <TableCell align="right">{state.pais}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.provincia}</TableCell>
                                                    <TableCell align="right">{state.provincia}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.municipio}</TableCell>
                                                    <TableCell align="right">{state.municipio}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.sector}</TableCell>
                                                    <TableCell align="right">{state.sector}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.direccion}</TableCell>
                                                    <TableCell align="right">{state.pacDireccion}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">{Strings.comentario}</TableCell>
                                                    <TableCell align="right">{state.pacComentario}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Box sx={{pl:{md:2}}}>
                                        <ListTransacciones list={paciente?.transacciones}/>
                                </Box>
                            </Grid>
                        </Grid>
                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("cancelSuscription") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarPaciente(urlState.cancelSuscription))} >
                    <Typography color={"secondary"}>
                        Estás seguro que deseas cancelar tu suscripción. Al realizar esta acción, perderás los puntos acumulados y se deshabilitará tu cuenta.
                    </Typography>
                </ConfirmDialog>
            }
            {(getUsuTipo() === TIPO_USUARIO.admin || hasPermissionTo(PERSMISOS.seguridad)) && <CrearUsuario/>}
            <SendSMS/>
        </>
    );

}
export default CrearPaciente;
