import React, {useState} from 'react';
import {
   useNavigate,
    useLocation
} from 'react-router-dom'
import {Box, CircularProgress, Typography} from '@mui/material';

import {TextField,InputAdornment,Button} from '@mui/material';
import {EmailOutlined} from '@mui/icons-material'

import Strings from "../../assets/strings";
import {Link} from "react-router-dom";
import {forgotPassword, forgotPasswordConfirm} from "../../services";
import {MostrarError} from "../../components";
import Alert from '@mui/material/Alert';
import LayOutLogin from "./LayOutLogin";
import {store} from "../../redux/store";
import Notifications from "react-notification-system-redux";

function ForgotPassword(){
    const {search} = useLocation()
    const code = search ? search.replace("?code=",""):"";
    let navigate = useNavigate();

    const [sentCode, setSentCode] = useState(false)

    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [state, setState] = useState({
        usuEmail: "",
        tipo: '',
        newPassword: "",
    })

    const handleChange = (e)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});
    }

    const submitForm = (e) =>{
        e.preventDefault()

        setLoading(true)

        const request = code ? forgotPasswordConfirm({code:code,newPassword:state.newPassword}): forgotPassword({usuEmail:state.usuEmail});

        request.then(() =>{
            if(code){
                store.dispatch(Notifications.success({ title: 'Actualizado con exito.', autoDismiss: 5 }));
                navigate("/login", {replace:true})
            }else{
                setSentCode(true)
            }
        }).catch(({response}) => {
            setError(response.data)
        }).finally(() => {
            setLoading(false)
        });
    }
    const validatePassword = ()=>{
        let confirm_password = document.getElementById("confirm_password");
        if(state.newPassword !== confirm_password.value) {
            confirm_password.setCustomValidity(Strings.passNoMatch);
        } else {
            confirm_password.setCustomValidity('');
        }
    }

        return(
            <LayOutLogin>
                    {sentCode ? (
                        <Alert severity="success">{Strings.contrasenaEnviadaMsj}</Alert>
                    ):(
                        <>
                            <Typography component="h1" variant="h5">
                                {Strings.recuperarContrasena}
                            </Typography>
                            {!!error &&
                                <MostrarError errors={error}/>
                            }
                            <Box component="form" onSubmit={submitForm} sx={{ mt: 1 }}>
                                <>
                                    {!code &&
                                    <TextField
                                        value={state.usuEmail}
                                        onChange={handleChange}
                                        name={"usuEmail"}
                                        label={Strings.email}
                                        margin="normal"
                                        required
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailOutlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                    {code &&
                                    <>
                                        <TextField
                                            value={state.newPassword}
                                            onChange={handleChange}
                                            name={"newPassword"}
                                            label={Strings.contrasenaNueva}
                                            margin="normal"
                                            required
                                            fullWidth
                                        />
                                        <TextField
                                            id="confirm_password"
                                            onChange={handleChange}
                                            label={Strings.confirmar+" "+Strings.contrasena}
                                            margin="normal"
                                            required
                                            onBlur={validatePassword}
                                            fullWidth
                                        />
                                    </>
                                    }
                                </>
                                <Button type="submit" variant="contained" sx={{mt:1}} fullWidth  color="primary" disableElevation disabled={isLoading}>
                                    {isLoading? (
                                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                    ):(
                                        <>{Strings.enviar}</>
                                    )}
                                </Button>
                                {/*{code &&*/}
                                {/*<Typography component={Link} to="/forgotPassword" sx={{marginTop: 9, textAlign: "center", color:"inherit"}}>*/}
                                {/*    {Strings.enviar} {Strings.nuevo}*/}
                                {/*</Typography>*/}
                                {/*}*/}
                            </Box>
                        </>
                    )}
                <Typography component={Link} to="/login" sx={{textAlign: "center",mt:2}} color={"inherit"}>
                    {Strings.iniciarSesion}
                </Typography>

            </LayOutLogin>
        )
}
export default ForgotPassword;
