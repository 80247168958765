import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Typography,
    Theme
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import {formatDate} from "../../helpers";
import {IVenta, IVentaDetalle} from "../../redux/types/IVenta";
import SelectSearchFarmacia from "../../components/selects/SelectSearchFarmacia";
import AddAdjuntos from "../../components/AddAdjuntos";
import SelectSearchArticulo from "../../components/selects/SelectSearchArticulo";
import {Check, Close} from "@mui/icons-material";
import {HtmlTooltip} from "../../components";
import {agregarEditarVenta} from "../../redux/reducers/ventasReducers";
import {IReceta} from "../../redux/types/IReceta";
import {getRecetaByCode} from "../../services";
import pdfIcon from "../../assets/img/pdf.svg";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme:Theme) => ({
    groupImg:{
        "display": "flex",
        overflow: "hidden",
        "&>div":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #e9ecef",
            position: "relative",
            width: "40px",
            height: "40px",
            padding: "5px",
            margin: "5px",
            "& img":{
                width:"100%",
            },
            "&.pdf img":{
                maxWidth: 66,
            }

        }
    },
    top:{
        display:"flex",
        justifyContent: "space-between",
        color:theme.palette.secondary.main,
        background:theme.palette.grey[200],
        alignItems: "center",
        marginTop:theme.spacing(1),
        padding: `${theme.spacing(1)} 0px`,
    },
}));

const initialState:IVenta = {
    farmaciaId: undefined,
    farmacia: "",
    venDependiente: "",
    recetaIdHash: "",
    venNumeroDoc: "",
    venLineas: undefined,
    venIndicadorVentaLibre: false,
    venNota: "",
    detalle:[],
    adjuntos:[],

};
export default function CrerVenta() {
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const {status} = useAppSelector(state => state.ventas);

    const [receta, setReceta] = useState<IReceta>();

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(!urlState?.hasOwnProperty("venta")){
            setState(initialState)
        }
    },[setState, urlState])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value, type, checked} = e.target
        return setState({...state,[name]: type === "checkbox"?checked: value});
    }

    const handleSubmit = () => {
        if(state.recetaIdHash && !receta) return window.alert("Receta no encontrada.")
        Dispatch(agregarEditarVenta(state))
    };
    const addArticuloDetalle = (item:any) => {
        let list = [...(state.detalle ?? [])];
        let editingItem:IVentaDetalle = {
            accion: 1,
            ventaId: state.id,
            articuloId: item.articuloId,
            articulo: item.articulo,
            venCantidad: 1,
            ofertaId: undefined,
           // venIndicadorOferta?: true,
        }


            if(list?.length){
                let found = list?.find(s=>(s.articuloId === editingItem.articuloId && s.ofertaId === editingItem.ofertaId));
                if(found) {
                    list = list.map(l => {
                        if (l.articuloId === editingItem.articuloId && l.ofertaId === editingItem.ofertaId)
                            return {...editingItem, accion: editingItem.accion === 3 ? 2 : editingItem.accion};

                        return l
                    })
                    setState(prev=>({...prev, detalle: list}))

                }else{
                    setState(prev=>({...prev, detalle: [{...editingItem},...list]}))
                }

            }else{
                setState(prev=>({...prev, detalle: [{...editingItem}]}))
            }

    };
    const deleteItem = (item:IVentaDetalle) => {
        let list = [...(state.detalle ?? [])];
        let index =  list.findIndex(l=>l.articuloId===item?.articuloId && l.ofertaId === item?.ofertaId)


        if(index === -1) return false;

        if(list[index].accion === 1){
            list.splice(index,1)
        }else if(list[index].accion === 3){
            list.splice(index,1,{...list[index],accion:2})

        }else{
            list.splice(index,1,{...list[index],accion:3})
        }
        setState(prev=>({...prev, detalle: list}))
    }
    const handleCantChange = (item:IVentaDetalle) => {
            let detalle = state?.detalle?.map(detalle=>{
                if(detalle.articuloId === item.articuloId){
                    return item;
                }
                return detalle
            })
            setState({...state, detalle})
    }
    const searchReceta = () => {
        if(state.recetaIdHash){
            getRecetaByCode(state.recetaIdHash).then(({data})=>{
                setReceta(data)
            }).catch(()=>setReceta(undefined))
        }else{
            setReceta(undefined)
        }
    }
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("venta"))} disableEscapeKeyDown maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={Strings.venta} onClose={()=>navigate(-1)}/>
                <DialogContent>
                    {(status === "loading") && <LoadingProgress />}
                    <ValidatorFormCustom onSubmit={handleSubmit} id="form1">

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SelectSearchFarmacia
                                    required
                                    value={{
                                        farmaciaId: (state.farmaciaId ?? "").toString(),
                                        farmacia: state.farmacia ?? ""
                                    }}
                                    onChange={(v)=>setState(prevState => ({
                                        ...prevState,
                                        farmacia:v.farmacia,
                                        farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label={Strings.numeroFactura} name={"venNumeroDoc"} value={state.venNumeroDoc} onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label={"Dependiente"} name={"venDependiente"} value={state.venDependiente} onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label={Strings.receta} onBlur={searchReceta} name={"recetaIdHash"} value={state.recetaIdHash} onChange={handleChange}/>
                            </Grid>
                            {receta &&
                                <Grid item xs={12}>
                                    <Box sx={{
                                        background: "#eeeeee",
                                        padding: "1px 8px 5px 8px",
                                    }}>
                                        <div className={classes.top}>
                                            <Typography noWrap variant={"body2"}>{Strings.receta}</Typography>
                                        </div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{formatDate(receta?.fechaCreacion)}</Typography>
                                                <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{receta?.paciente}</Typography>
                                                <Typography variant={"caption"}>{Strings.paciente}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2">{receta?.centroMedico || "-"}</Typography>
                                                <Typography variant={"caption"}>{Strings.centroMedico}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{receta?.centroMedicoCiudad || "-"}</Typography>
                                                <Typography variant={"caption"}>Ciudad</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2">{receta?.medico}</Typography>
                                                <Typography variant={"caption"}>{Strings.medico}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">{receta?.especialidad || "-"}</Typography>
                                                <Typography variant={"caption"}>{Strings.especialidad}</Typography>
                                            </Grid>

                                        </Grid>
                                        {receta?.adjuntos &&
                                            <>
                                                <div className={classes.top}>
                                                    <Typography noWrap variant={"body2"}>{Strings.adjuntos}</Typography>
                                                </div>
                                                <div>
                                                    <div className={classes.groupImg}>
                                                        {receta.adjuntos.map((adjunto, i) =>
                                                            <div key={i} className={adjunto.url?.indexOf("pdf") !== -1 ? 'pdf' : 'img'}>
                                                                <a href={adjunto.url} target="_blank" rel="noopener noreferrer">
                                                                    <img src={adjunto.url?.indexOf("pdf") !== -1 ? pdfIcon : adjunto.url} alt=""  />
                                                                </a>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField fullWidth label={Strings.comentario} multiline name={"venNota"} value={state.venNota} onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={state.venIndicadorVentaLibre}
                                                                         name={"venIndicadorVentaLibre"}
                                                                         onChange={handleChange} />}
                                                      label={Strings.ventaLibre} />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                               <Box sx={{mb:1}}>
                                   <SelectSearchArticulo
                                       label={""}
                                       value={{
                                           articuloId: "",
                                           articulo: ""
                                       }}
                                       onChange={addArticuloDetalle}
                                   />
                               </Box>
                                <TableContainer component={Paper} variant={"outlined"} sx={{mb:1}}>
                                    <Table className={"noHover"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell/>
                                                <TableCell>Nombre</TableCell>
                                                <TableCell align="right">Cantidad</TableCell>
                                                {!!receta && <TableCell align="right">Cant. Recetada</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.detalle?.map(item=>
                                                <TableRow sx={{textDecoration:item.accion===3?"line-through":""}}>
                                                    <TableCell className="actionCell">
                                                        <HtmlTooltip title={item.accion===3?Strings.eliminar:"Deshacer"}>
                                                            <IconButton onClick={()=>deleteItem(item)} size="large">
                                                                {item.accion===3?(
                                                                    <Check className="text-blue"/>
                                                                ):(
                                                                    <Close className="text-red"/>
                                                                )}
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                    </TableCell>
                                                    <TableCell>{item.articulo}</TableCell>
                                                    <TableCell align="right">
                                                        <TextField type={"number"}
                                                                   value={item.venCantidad}
                                                                   onChange={({target})=>handleCantChange({...item,venCantidad:parseInt(target.value)})}/>
                                                    </TableCell>
                                                    {!!receta &&
                                                        <TableCell align="right">
                                                            <TextField type={"number"}
                                                                       disabled={receta.recEstado !== 2}
                                                                       value={item.venCantidadReceta}
                                                                       onChange={({target})=>handleCantChange({...item,venCantidadReceta:parseInt(target.value)})}/>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box textAlign={"right"} width={"100%"}>Total {Strings.articulos}: {state.detalle?.reduce((a,b)=> a + (b.venCantidad ?? 0),0)}</Box>
                            </Grid>
                            <Grid item xs={12}>
                                <AddAdjuntos
                                    adjuntos={state.adjuntos?.map(adj=>({
                                        arcPath:adj.url,
                                        arcBase64:adj.base64,
                                        arcExtension: (adj.url || adj.base64)?.indexOf("pdf") !== -1?"pdf":"jpg"
                                    })) ?? []}
                                    onChange={(val)=>{
                                        setState(prevState => ({...prevState, adjuntos:val.map(v=>({
                                                url: v.arcPath,
                                                base64: v.arcBase64,
                                            }))}))}}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorFormCustom>

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                    <Button disabled={status === "loading"} type="submit" form="form1">
                        {status === "loading"? (
                            <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                        ):(
                            <span>{Strings.guardar}</span>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
