import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getEntregas, getEntregaById, delEntrega} from '../../services';
import {IEntregaState, IEntrega, IEntregaRes} from "../types/IEntrega";
import historyHelper from "../../helpers/history";
const initialState: IEntregaState = {
    status: "idle"
}

export const mostrarEntregas = createAsyncThunk(
    'mostrarEntregas', async (params:object|undefined ) => {
        const response = await getEntregas(params)
        return {data: response.data as IEntregaRes, params: response.config.params}
    }
)
export const mostrarEntregaById = createAsyncThunk(
    'mostrarEntregaById', async (id:number ) => {

        const response = await getEntregaById(id)
        return response.data as IEntrega
    }
)
export const eliminarEntrega = createAsyncThunk(
    'Entrega/eliminar', async (id: number) => {
        delEntrega(id);
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarEntregas',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarEntregas
        builder.addCase(mostrarEntregas.pending, (state ) => {
            state.status = "loading";
            state.byId = undefined;
        })
        builder.addCase(mostrarEntregas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarEntregas.rejected, (state ) => {
            state.status = "idle";
        })
        //---mostrarEntregaById
        builder.addCase(mostrarEntregaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarEntregaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarEntregaById.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarEntrega
        builder.addCase(eliminarEntrega.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarEntrega.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarEntrega.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
