import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";

import {INotificacion} from "../redux/types/INotificacion";
import {TextValidatorCustom, ValidatorFormCustom} from "../helpers/form-validator";
import TopBarDialog from './topBar/TopBarDialog';
import LoadingProgress from "./LoadingProgress";
import {sentNotificacion} from "../services";


const initialState:INotificacion = {
    tipoDestino: 1,
    title: "",
    mensaje: "",


        // pacienteId: 0,
        // paciente: "string",
        // medicoId: 0,
        // medico: "string",
        // farmaciaId: 0,
        // farmacia: "string",
        // usuarioId: 0,
        // proveedorReference: "string",
}

export default function SendSMS() {

    const {state:urlState}:{state:any} = useLocation();
    const navigate =useNavigate();

    const goBack = () => {
        navigate(-1)
    }
    const [loading, setLoading] = useState(false);
    const [enviado, setEnviado] = useState(false);

    const [state, setState] = useState(initialState);


    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value, type, checked} = e.target
        return setState({...state,[name]: type === "checkbox"?checked: value});
    }
    useEffect(()=>{
        if(urlState?.sendSMS){
            setEnviado(false)
            setLoading(false)
            setState({...initialState,...urlState.sendSMS})
        }
    },[urlState,setEnviado,setState,setLoading])
    useEffect(()=>{
        if(enviado){
            setState({...initialState,...urlState.sendSMS})
        }
    },[enviado,setState])


    const submit = () => {
        setEnviado(false)
        setLoading(true)
        sentNotificacion(state).then((res)=>{
            setEnviado(true)

        }).finally(()=>setLoading(false))
    }

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("sendSMS"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={"Enviar notificación"} nofullScreen onClose={goBack}/>
                <DialogContent>
                    {loading && <LoadingProgress />}
                    {enviado?(
                        <Alert severity="success">Notificación enviada correctamente.</Alert>
                    ):(
                        <ValidatorFormCustom onSubmit={submit} id={"sendSMS"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl  fullWidth>
                                        <InputLabel>Tipo Destino</InputLabel>
                                        <Select
                                            name={"tipoDestino"}
                                            value={state.tipoDestino}
                                            onChange={({target})=>setState({...state,tipoDestino: +target.value ??undefined})}
                                            displayEmpty
                                        >
                                            {[{key:1,value:"Todos"},{key:2,value:"Correo"},{key:3,value:"Sms"}].map((el)=>
                                                <MenuItem key={el.key} value={el.key}>{el.value}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidatorCustom
                                        name={"title"}
                                        label={"Título"}
                                        value={state.title}
                                        onChange={handleChange}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidatorCustom
                                        multiline
                                        name={"mensaje"}
                                        label={"Mensaje"}
                                        rows={3}
                                        value={state.mensaje}
                                        onChange={handleChange}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {/*<SelectSearchFarmacia required */}
                                    {/*                      value={{*/}
                                    {/*                          farmaciaId: (state.farmaciaId ?? "").toString(),*/}
                                    {/*                          farmacia: state.farmacia ?? ""*/}
                                    {/*                      }}*/}
                                    {/*                      onChange={(v)=> setState(prev=>({...prev,*/}
                                    {/*                          farmacia: v.farmacia,*/}
                                    {/*                          farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined*/}
                                    {/*                      }))}*/}
                                    {/*/>*/}
                                </Grid>

                            </Grid>
                        </ValidatorFormCustom>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button color={"inherit"} onClick={goBack}>Cancelar</Button>

                        <Button sx={{display:enviado?"block":"none"}} type={"button"} onClick={()=>setEnviado(false)} >Enviar nuevo</Button>


                        <Button sx={{display:!enviado?"block":"none"}} disabled={loading} variant={"contained"} disableElevation type={"submit"} form={"sendSMS"}>Enviar</Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

