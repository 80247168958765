import React, { useEffect} from 'react';
import { mostrarUsosMultiples } from '../../redux/reducers/usosMultiplesReducers';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import _ from "lodash";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IUsoMultiple} from "../../redux/types/IUsosMultiples";
import {errorMessagesC, SelectValidatorCustom} from "../../helpers/form-validator";

type keyValue = { key?:number,value:string }
interface Iprops{
    emptyOption?: keyValue,
    required?:boolean,
    disabled?:boolean,
    value:string,
    label:string,
    onChange: (val:keyValue)=>void
    group:string
}
let emptyValue:keyValue = {
    value:""
}
function SelectUsoMultiples({emptyOption,required,disabled,value="",label="", onChange, group}:Iprops) {

    const {status,list={}} = useAppSelector(state => state.usosMultiples);
    const Dispatch = useAppDispatch();


    //cargar usos multiples
    useEffect(() => {
        if(!Object.keys(list).length ){
            Dispatch(mostrarUsosMultiples())
        }
    }, [list, Dispatch]);


    const lista = (list as any)[group] ? (list as any)[group] : [];


    var options:keyValue[] = lista.map((opc:IUsoMultiple)=>({key:opc.id,value: (Strings as any)[_.camelCase(opc.usoDescripcion)] || opc.usoDescripcion}))
    if(emptyOption) options.unshift(emptyOption)


    const handleChange = (e:SelectChangeEvent)=>{
        const {value} = e.target
        let selected = options?.find(l=>l.key===parseInt(value));
        onChange(selected?selected:emptyValue)
    }

    const validators=required?['required']:[]

    return (
        <SelectValidatorCustom
            {...{value, validators,disabled:(status === "loading" || disabled),loading: status === "loading", errorMessages:errorMessagesC(validators),name:"", label, onChange:handleChange}}
        >
            {options.map(o=>
                <MenuItem key={o.key} value={o.key}>{o.value}</MenuItem>
            )}
        </SelectValidatorCustom>
    )
}

export default SelectUsoMultiples
