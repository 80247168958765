import React, {useEffect, useState} from 'react';
import {eliminarRol, mostrarRoles} from '../../redux/reducers/rolesReducers';
import Crear from './crear';
import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Close} from "@mui/icons-material";
import LoadingProgress from "../../components/LoadingProgress";
import {useLocation, useNavigate} from "react-router-dom";
import Empty from "../../components/Empty";
import {ConfirmDialog, ListViewMode, SeacrhAppBar} from "../../components";
import {noTilde} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IRol} from "../../redux/types/IRoles";
import TopAppBar from "../../components/TopAppBar";

function Roles() {
    const navigate =useNavigate();
    const location = useLocation();
    const { list, status } = useAppSelector(state => state.roles);
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const [searchBox, setSearchBox] = useState("");


    useEffect(() => {
        Dispatch(mostrarRoles());
    }, [Dispatch]);

    const isEmpty = !list || list.length === 0;
    function filterItems():IRol[]|undefined{


        return list?.filter(item=> noTilde(item.rolDescripcion).indexOf(noTilde(searchBox)) !== -1);
    }
    return <>
        <TopAppBar/>

        <section className="contentInner" style={{maxWidth:600}}>
            <ListViewMode onNew={()=>navigate(location.pathname, {state:{id:""}})} noView>
                <SeacrhAppBar name="search" value={searchBox} onChange={({target}:{target:any})=>setSearchBox(target.value)}/>
            </ListViewMode>
            {status === "loading"  && !list?.length && <LoadingProgress vh/>}
            <List >
                {filterItems()?.map(item =>
                    <ListItem role={undefined} button key={item.id} onClick={()=>navigate(location.pathname, {state:item})}>
                        <ListItemText primary={item.rolDescripcion}  />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={()=>navigate(location.pathname, {state:{confirmDialog:item.id}})}
                                size="large">
                                <Close className="text-red" fontSize={"small"}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </List>
            {isEmpty && <Empty/>}
        </section>
        <Crear/>
        {urlState?.hasOwnProperty("confirmDialog") &&
            <ConfirmDialog onConfirm={()=>Dispatch(eliminarRol(urlState.confirmDialog))}/>
        }

    </>;
}
export default Roles;
