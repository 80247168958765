import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress} from '@mui/material';
import Strings from "../../assets/strings";
import {getArticulosCategorias} from "../../services";
import {IArticulosCategoria} from "../../redux/types/IArticulosCategorias";
import {TextValidatorCustom} from "../../helpers/form-validator";

type TProps = {
    label?:string,
    value:TValue,
    required?:boolean,
    onChange: (val:TValue)=>void

}
type TValue = {
    categoriaId?:string,
    categoria:string
}
var searchTimeout:ReturnType<typeof setTimeout>;

let emptyValue = {
    categoriaId:"",
    categoria:""
}

export default function SelectSearchCategoria({ value,label=Strings.categoria,required, onChange}:TProps) {
    const [options, setOptions] = React.useState<TValue[]>([]);
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        return ()=>clearTimeout(searchTimeout)
    })
    const fetchArcticulo = (name:string) => {
        setLoading(true)
        getArticulosCategorias({name}).then(res=>{
            let result = res.data.results as IArticulosCategoria[];
            if(result.length){
                setOptions(result.map(r=>({categoriaId:r.id!.toString(),categoria:r.catNombre} as TValue)) )
            }
        }).finally(()=>setLoading(false))
    }

    return (

        <React.Fragment>
            <Autocomplete
                fullWidth
                loading={loading}
                onOpen={()=> {
                    if(options.length) return false;
                    fetchArcticulo("")
                }}
                options={[value,...options.filter((r) =>r.categoriaId!==value.categoriaId)]}
                getOptionLabel={(option) => option.categoria}
                value={value}
                isOptionEqualToValue={(option, value) => option.categoriaId === value.categoriaId}

                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                    onChange(newValue??emptyValue)
                }}
                // inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        fetchArcticulo(newInputValue)
                    },1000);
                }}
                renderInput={(params) => (
                    <TextValidatorCustom
                        {...params}
                        name={""}
                        value={value.categoriaId}
                        label={label}
                        validators={required?['required']:[]}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </React.Fragment>

    );
}
