import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {IParametro} from "../../redux/types/IParametro";
import {agregarEditarParametro} from "../../redux/reducers/parametrosReducers";
import {ValidatorFormCustom} from "../../helpers/form-validator";

const initialState:IParametro = {
    parTipo: undefined,
    parTipoString: "",
    parValor: "",
};
export default function Crear() {
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const {status} = useAppSelector(state => state.parametros);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if(!!urlState && urlState.parametro) {
            setState(state => ({...state, ...nullToEmptyString(urlState.parametro)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = () => {
        Dispatch(agregarEditarParametro(state))
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("parametro"))} onClose={()=>navigate(-1)} maxWidth={"xs"} fullWidth={true}>
                <TopBarDialog title={state.parTipoString??""} onClose={()=>navigate(-1)}/>
                <DialogContent>
                        {(status === "loading") && <LoadingProgress />}
                            <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
                                {/*<Box sx={{mb:2}}>*/}
                                {/*    <SelectList*/}
                                {/*        label={Strings.tipo}*/}
                                {/*        disabled={!!state.id}*/}
                                {/*        list={list?.map(l=>({name:l.parTipoString??"",id:l.parTipo}))??[]}*/}
                                {/*        value={{id:state.parTipo, name:""}}*/}
                                {/*        onChange={(val)=>setState({...state,parTipo: val.id ?? 0  })}*/}
                                {/*    />*/}
                                {/*</Box>*/}
                                <TextField
                                    required
                                    value={state.parValor??""}
                                    name={"parValor"}
                                    onChange={(e:any) => setState({...state, parValor: e.target.value})}
                                    label={"Valor"}
                                    sx={{mt:2,mb:2}}
                                    fullWidth
                                />
                            </ValidatorFormCustom>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                        <Button disabled={status === "loading"} type="submit" form="form1">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{Strings.guardar}</span>
                            )}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
