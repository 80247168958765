import Strings from "../assets/strings";

export const TIPO_DOCUMENTO = {
    cedula: 1,
    pasaporte: 2,
    licenciaConducir: 3,
    extranjero: 4
}
export const VENTA_ESTADO = {
    sometido: 1,
    declinada: 2,
    confirmada: 3
}
export const RECETA_ESTADO = {
    completada: 1,
    pendiente: 2,
    entregaParcial: 3
}
export const AUTORIZACION_ESTADO = {
    pendiente: 1,
    aprobada: 2,
    rechazada: 3
}
export const TIPO_AUTORIZACION = {
    canje:1
}
export const TIPO_REPOSICION = {
    ventas: 1,
    pushMoney:2,
}

export const TIPO_USUARIO = {
    admin:1,
    medico: 2,
    paciente: 3,
    farmacia: 4,
    visitador: 5
}
export const SEXO = {
    masculino: "M",
    femenino: "F"
}
export const PERSMISOS = {
   // Del 1 - 9 Seria para permisos Generales.-
    parametrosCrear: 600,
    parientes : 20,
    parientesCrear:21,
    parientesActualizar:22,
    parientesEliminar:23,
    parientesGenerarUsuario:24,
    medicosGenerarUsuario:64,
    tarjetas : 80,
    tarjetasCrear: 81,
    tarjetasActualizar: 82,
    tarjetasEliminar: 83,
    tarjetasCrearLote: 84,

    canjesActualizar: 102,

    recetasActualizar: 102,

    compras : 110,
    comprasCrear: 111,
    comprasActualizar: 112,
    comprasEliminar: 113,
    planes:120,
    planesCrear:121,
    planesActualizar:122,
    planesEliminar:123,
//-------






    //------------
    dashboard: 1,
    configuracion: 2,
    autorizaciones: 3,
    seguridad: 4,

    pacientes:10,
    pacientesCrear: 11,
    pacientesActualizar: 12,
    pacientesEliminar: 13,
    pacientesGenerarUsuario: 14,

    articulos: 20,
    articulosCrear: 21,
    articulosActualizar: 22,
    articulosEliminar: 23,

    lineas: 30,
    lineasModifcar: 31,
    marcas: 32,
    marcasModificar: 33,

    farmacias: 40,
    farmaciasCrear: 41,
    farmaciasActualizar: 42,
    farmaciasEliminar: 43,
    farmaciasGenerarUsuario: 44,

    medicos: 50,
    medicosCrear: 51,
    medicosActualizar: 52,
    medicosEliminar: 53,

    centrosMedicos: 60,
    centrosMedicosCrear: 61,
    centrosMedicosActualizar: 62,
    centrosMedicosEliminar: 63,

    canjes: 70,
    canjesCrear: 71,
    canjesEliminar: 72,
    //tarjetas: 80,
    //tarjetasCrear: 1,
    //tarjetasActualizar,
    //tarjetasEliminar,
    //tarjetasCrearLote,

    usosMultiples: 80,
    usosMultiplesCrear: 81,
    usosMultiplesActualizar: 82,
    usosMultiplesEliminar: 83,

    recetas: 90,
    recetasCrear: 91,
    recetasEliminar: 92,

    ventas: 100,
    ventasCrear: 101,
    ventasEliminar: 102,

    ofertas: 110,
    ofertasCrear: 111,
    ofertasActualizar: 112,
    ofertasEliminar: 113,
    ofertasSucursales: 114,

    usuarios: 120,
    usuariosCrear: 121,
    usuariosActualizar: 122,
    usuariosEliminar: 123,
    usuariosBloquear: 124,

    roles: 130,
    rolesCrear: 131,
    rolesActualizar: 132,
    rolesEliminar: 133,


    pushMoney: 140,
    pushMoneyCrear: 141,
    pushMoneyEliminar: 142,
    pushMoneyConfirmar: 143,
    pushMoneyActualizar: 144,

    reposiciones: 150,
    reposicionesCrear: 151,
    reposicionesEliminar: 152,
    reposicionesActualiza: 153,

    visitas: 160,
    visitasCrear: 161,
    visitasEliminar: 162,

    entregas: 170,
    entregasCrear: 171,
    entregasEliminar: 172,
};

export const getValueString = (constant = {},value, trans= true) =>{
    let prop = "";
    Object.keys(constant).forEach((pro)=>{
        if(constant[pro] === parseInt(value)){
            prop = trans ? (Strings[pro] || pro) : pro;
        }
    })
    return prop;
}
export const objToKeyValue = (constant = {}) =>{
    let arr = [];
    Object.keys(constant).forEach((pro,i)=>{
        arr.push({
            key:constant[pro],
            value:Strings[pro] || pro
        })
    })
    return arr;
}
