import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getParametros,addEditParametro,delParametro} from '../../services';
import {IParametroState, IParametro} from "../types/IParametro";
import historyHelper from "../../helpers/history";
const initialState: IParametroState = {
    status: "idle"
}

export const mostrarParametros = createAsyncThunk(
    'mostrarParametros', async (params?:object|undefined ) => {
        const response = await getParametros(params)
        return {data:response.data as IParametro[], params: response.config.params}
    }
)
export const agregarEditarParametro = createAsyncThunk(
    'parametros/agregarEditar', async (parametro: IParametro) => {
        const response = await addEditParametro(parametro);

        if (response.status === 200) historyHelper.back();
        return {data: response.data, edit:!!parametro.id};
    }
);
export const eliminarParametro = createAsyncThunk(
    'parametros/eliminar', async (id: number) => {
        await delParametro(id);
        return id;
    }
);
const slice = createSlice({
    name: 'mostrarParametros',
    initialState,
    reducers: {},
    extraReducers: builder => {
        //--mostrarParametros
        builder.addCase(mostrarParametros.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarParametros.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarParametros.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarParametro
        builder.addCase(agregarEditarParametro.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarParametro.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list = state.list.filter(e => e.parTipo !== action.payload.data.parTipo);
                state.list.unshift(action.payload.data);
            }
        })
        builder.addCase(agregarEditarParametro.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarParametro
        builder.addCase(eliminarParametro.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarParametro.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list){
                state.list = state.list.map(item=>{
                    if(item.id === id){
                        return {...item, id:0, parValor:""}
                    }
                    return item;
                })
                state.list = state.list.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarParametro.rejected, (state ) => {
            state.status = "idle";
        })
    }

});

export default slice.reducer;
