import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getArticulos, addEditArticulos, getArticulosById, delArticulo} from '../../services';
import {IArticuloState, IArticuloRes, IArticulo} from "../types/IArticulo";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";
const initialState: IArticuloState = {
    status: "idle"
}
export const mostrarArticulos = createAsyncThunk(
    'mostrarArticulos', async (params:object|undefined,thunkAPI ) => {
        const {articulos} = thunkAPI.getState() as AppState;
        let paramsFilter = params || articulos.filtered;

        const response = await getArticulos(paramsFilter)
        return {data: response.data as IArticuloRes, params: response.config.params}
    }
)
export const mostrarArticulosById = createAsyncThunk(
    'mostrarArticulosById', async (id:number) => {
        const response = await getArticulosById(id)
        return response.data as IArticulo
    }
)
export const agregarEditarArticulo = createAsyncThunk(
    'articulos/agregarEditar', async (articulo: IArticulo) => {
        const response = await addEditArticulos(articulo);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {data: response.data, edit:!!articulo.id};
    }
);
export const eliminarArticulos = createAsyncThunk(
    'articulos/eliminar', async (id: number) => {
        const response = await delArticulo(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();

        return id;
    }
);


const slice = createSlice({
    name: 'mostrarArticulos',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //---mostrarArticulos
        builder.addCase(mostrarArticulos.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarArticulos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarArticulos.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarArticulo
        builder.addCase(agregarEditarArticulo.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarArticulo.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarArticulo.rejected, (state ) => {
            state.status = "idle";
        })

        //--mostrarArticulosById
        builder.addCase(mostrarArticulosById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarArticulosById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarArticulosById.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarArticuloCategoria
        builder.addCase(eliminarArticulos.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarArticulos.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarArticulos.rejected, (state ) => {
            state.status = "idle";
        })
    }

});




export default slice.reducer;
