import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress, ListItem, ListItemText} from '@mui/material';
import Strings from "../../assets/strings";
import {getArticulos} from "../../services";
import {TextValidatorCustom} from "../../helpers/form-validator";
import {IArticulo} from "../../redux/types/IArticulo";

type TProps = {
    label?:string,
    value:TValue,
    required?:boolean,
    disabled?:boolean,
    onChange: (val:TValue)=>void

}
type TValue = {
    articuloId?:string,
    articulo:string,
    artCodigo?:string
}
var searchTimeout:ReturnType<typeof setTimeout>;

let emptyValue = {
    articuloId:"",
    articulo:""
}
export default function SelectSearchArticulo({ value,label=Strings.articulo,required,disabled, onChange}:TProps) {
    const [options, setOptions] = React.useState<TValue[]>([]);
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        return ()=>clearTimeout(searchTimeout)
    })
    const fetchArcticulo = (name:string) => {
        setLoading(true)
        getArticulos({name}).then(res=>{
            let result = (res.data.results) as IArticulo[];
            setOptions(result?.map(r=>({articuloId:r.id!.toString(),articulo:r.artNombre,artCodigo:r.artCodigo})))
        }).finally(()=>setLoading(false))
    }

    return (

        <React.Fragment>
            <Autocomplete
                fullWidth
                disabled={disabled}
                loading={loading}
                aria-required={required}
                onOpen={()=> {
                    if(options.length) return false;
                    fetchArcticulo("")
                }}
                options={[value,...options.filter((r) =>r.articuloId!==value.articuloId)]}
                getOptionLabel={(option) => option.articulo}
                value={value}
                isOptionEqualToValue={(option, value) => option.articuloId === value.articuloId}

                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                    onChange(newValue??emptyValue)
                }}
                // inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        fetchArcticulo(newInputValue)
                    },1000);
                }}
                id="selectArticulos"
                renderOption={(props, option) => (
                    <ListItem  {...props}>
                        <ListItemText primary={option.articulo} secondary={option.artCodigo} />
                    </ListItem>
                )}
                renderInput={(params) => (
                    <TextValidatorCustom
                        {...params}
                        name={""}
                        value={value.articuloId}
                        label={label}
                        validators={required?['required']:[]}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </React.Fragment>

    );
}
