import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {mostrarPushMoney} from "../../redux/reducers/pushMoneyReducers";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonResponsive,
  FilteredAlert,
  HtmlTooltip,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {formatDate, formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IPushMoney} from "../../redux/types/IPushMoney";
import {Close, FilterList} from '@mui/icons-material';
import DetallePushMoney from "./DetallePushMoney";
import {DateInput, SelectSmart} from "../../components/CustomTextField";
import SelectSearchFarmacia from "../../components/selects/SelectSearchFarmacia";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import TopAppBar from "../../components/TopAppBar";
import {getValueString, objToKeyValue, VENTA_ESTADO} from "../../helpers/CONSTANTES";
import ConfirnarAnularPushMoney from './ConfirnarAnularPushMoney';

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1),
    "& tr.confirmada":{
      background: "#eff8ff !important"
    },
    "& tr.declinada":{
      background: "#fff3f3 !important"
    },
  },
  contList:{
    border: "1px solid "+theme.palette.grey[200],
  }
}));

const initialState={
  name: '',
  planId: '',
  plan:'',
  farmaciaId:'',
  farmacia:'',
  pacienteId:'',
  paciente:'',
  estado: "",
  fechaDesde:'',
  fechaHasta:'',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function PushMoney(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();

  const Dispatch = useAppDispatch();

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.pushMoney)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarPushMoney());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarPushMoney({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:value})
  };
  const resetFilter = () => {
    setOpenFilter(false)
    setFilter(initialState);
    Dispatch(mostrarPushMoney({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarPushMoney(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarPushMoney({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };



  return <>

    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*// @ts-ignore*/}
              <DateInput
                  name={"fechaDesde"}
                  label={Strings.fechaDesde}
                  value={filter.fechaDesde}
                  onChange={handleFilter}
              />
            </Grid>
            <Grid item xs={6}>
              {/*// @ts-ignore*/}
              <DateInput
                  name={"fechaHasta"}
                  label={Strings.fechaHasta}
                  value={filter.fechaHasta}
                  onChange={handleFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchFarmacia
                  value={{
                    farmaciaId: (filter.farmaciaId ?? "").toString(),
                    farmacia: filter.farmacia ?? ""
                  }}
                  onChange={(v)=>setFilter(prevState => ({
                    ...prevState,
                    farmacia:v.farmacia,
                    farmaciaId:v.farmaciaId?v.farmaciaId:""
                  }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink>{Strings.estado}</InputLabel>
                <SelectSmart
                    loading={false}
                    value={filter?.estado ?? ""}
                    onChange={handleFilter}
                    name="estado"
                    opciones={[
                      {key: '', value: Strings.todo},
                        ...objToKeyValue(VENTA_ESTADO)
                    ]}
                />
              </FormControl>
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>
    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}
      <Box className={classes.contList}>
        <ListViewMode newBtnText={Strings.crear+" PushMoney"} onNew={hasPermissionTo(PERSMISOS.pushMoneyCrear) ? ()=>navigate("/crear/pushMoney") : undefined} noView>
          <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
          <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>

          <FilteredAlert onClose={resetFilter} filter={filtered}/>
        </ListViewMode>

        <TableSort data={[
          {pro:"fechaCreacion",text:""},
          {pro:"id",text:"ID"},
          {pro:"fechaCreacion",text:Strings.fecha},
          {pro:"psmEstado",text:Strings.estado},
          {pro:"farmacia",text:Strings.farmacia},
          {pro:"psmNumeroDoc",text:Strings.numeroFactura},
          {pro:"psmMonto",text:"PushMoney"},
        ]} onSort={tableSortProps} lessVh={155}>
          <TableBody>
            {!isEmpty && list?.results.map((psm:IPushMoney) =>
                <TableRow key={psm.id}
                          onClick={()=> navigate(location.pathname, {state: {detallePushMoney: psm}})}
                          className={getValueString(VENTA_ESTADO,psm?.psmEstado, false)}
                >
                  <TableCell className="actionCell" sx={{width:100}}
                             onClick={e=>e.stopPropagation()}>
                    {psm.psmEstado !== 2 && hasPermissionTo(PERSMISOS.pushMoneyEliminar) &&
                        <HtmlTooltip title={Strings.anular}>
                          <IconButton size={"small"}
                                      onClick={(e)=> {
                                        navigate(location.pathname, {state: {confirnarAnularPushMoney: psm.id}})
                                      }}>
                            <Close fontSize={"small"} className="text-red"/>
                          </IconButton>
                        </HtmlTooltip>
                    }

                  </TableCell>
                  <TableCell>{psm.id}</TableCell>
                  <TableCell>{formatDate(psm.fechaCreacion)}</TableCell>
                  <TableCell>
                    <span className= {(() => {
                      switch (psm.psmEstado) {
                        case 2: return "text-red";
                        case 3:   return "text-blue";
                      }
                    })()}>
                      {getValueString(VENTA_ESTADO,psm.psmEstado)}
                    </span>
                  </TableCell>
                  <TableCell>{psm.farmacia}</TableCell>
                  <TableCell>{psm.psmNumeroDoc}</TableCell>
                  <TableCell>{formatPeso(psm.psmMonto)}</TableCell>
                </TableRow>
            )}
          </TableBody>
        </TableSort>

        <Pagination
            empty={Boolean(isEmpty && status !== "loading")}
            count={list?.totalRecordCount || 0}
            rowsPerPage={Number(filter.pageSize)}
            page={(list?.pageNo ?? 0) -1}
            onPageChange={(_,page:number)=>Dispatch(mostrarPushMoney({...filter,page:(page + 1).toString()}))}
            onRowsPerPageChange={handleFilter}
        />
      </Box>

    </section>

    {!!urlState?.hasOwnProperty("detallePushMoney") &&
      <DetallePushMoney/>
    }

    {!!urlState?.hasOwnProperty("confirnarAnularPushMoney") &&
        <ConfirnarAnularPushMoney/>
    }
  </>;
}

export default PushMoney;

