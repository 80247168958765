import { Box, Button, CircularProgress, Grid, Paper, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Strings from "../../assets/strings";
import { LoadingProgress } from "../../components";
import ConfirmDialog from "../../components/ConfirmDialog";
import { hasPermissionTo, nullToEmptyString, PERSMISOS } from '../../helpers';
import { TextValidatorCustom, ValidatorFormCustom } from "../../helpers/form-validator";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { agregarEditarCentroMedico, eliminarCentroMedico, mostrarCentroMedicoById } from "../../redux/reducers/centrosMedicosReducers";
import { ICentroMedico } from "../../redux/types/ICentroMedico";
import TopAppBar from "../../components/TopAppBar";
import {PhoneTextField} from "../../components/CustomTextField";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:ICentroMedico = {
    cenNombre: "",
    cenCodigo:"",
    cenTelefono: "",
    cenDireccion: "",
}
function CrearCentroMedico(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlCenId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:centroMedico,status} = useAppSelector(state => state.centrosMedicos);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlCenId) Dispatch(mostrarCentroMedicoById(Number(urlCenId)));
    },[urlCenId,Dispatch])
    useEffect(()=>{
        if(!!urlCenId && centroMedico?.id === parseInt(urlCenId)) {
            setState({...initialState, ...nullToEmptyString(centroMedico)});
        }else{
            setState(initialState)
        }
    },[urlCenId,centroMedico])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = () =>{
        Dispatch(agregarEditarCentroMedico(state));
    }

    return(
        <Fragment>
            <TopAppBar/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}
                    <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                        <input autoComplete="false" name="hidden" type="text" style={{display:"none"}} />

                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={4} xl={3}>
                                    <TextValidatorCustom
                                        name={"cenNombre"}
                                        label={Strings.nombre}
                                        value={state.cenNombre}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} xl={3}>
                                    <TextValidatorCustom
                                        name={"cenCodigo"}
                                        label={Strings.codigo}
                                        value={state.cenCodigo}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 10}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} xl={3}>
                                    <PhoneTextField name={"cenTelefono"} validators={['required']} label={Strings.telefono} value={state.cenTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth />

                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidatorCustom
                                        name={"cenDireccion"}
                                        label={Strings.direccion}
                                        value={state.cenDireccion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 200}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            {!!state.id &&
                                <Button onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.centrosMedicosCrear)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                        color="error" sx={{marginRight:"auto"}}>{Strings.eliminar}</Button>}
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{!!state.id ? Strings.actualizar : Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>

                    </ValidatorFormCustom>
                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarCentroMedico(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}
export default CrearCentroMedico;
