import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {TIPO_USUARIO} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {ICanje} from "../../redux/types/ICanje";
import {agregarEditarCanje} from "../../redux/reducers/canjesReducers";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import {getTokenInfo} from "../../services/auth";
import SelectSearchMedico from "../../components/selects/SelectSearchMedico";

const initialState:ICanje = {
    medicoId: undefined,
    medico: "",
    canNota: "",
    canPuntos:undefined,
};
export default function Crear() {
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const {status} = useAppSelector(state => state.canjes);
    const user = useAppSelector(state=>state.auth.user)

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(user?.usuTipo === TIPO_USUARIO.medico){
            setState(prevState => ({...prevState,medicoId:(getTokenInfo() as any)["RelatedId"],medico:user.usuNombre}))
        }
    },[user, setState])

    const handleSubmit = () => {
        Dispatch(agregarEditarCanje(state))
    };

    const [medPuntos, setMedPuntos] = useState<number|undefined>(undefined);

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("id"))} onClose={()=>navigate(-1)} maxWidth={"xs"} fullWidth={true}>
                <TopBarDialog title={Strings.canje} onClose={()=>navigate(-1)}/>
                <DialogContent>
                        {(status === "loading") && <LoadingProgress />}
                            <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
                                <Box sx={{mb:2}}>
                                    <SelectSearchMedico
                                        showPuntos
                                        disabled={user?.usuTipo === TIPO_USUARIO.medico}
                                        required
                                        value={{
                                            medicoId: (state.medicoId ?? "").toString(),
                                            medico: state.medico ?? ""
                                        }}
                                        onChange={(v)=> {
                                            setState(prevState => ({
                                                ...prevState,
                                                medico: v.medico,
                                                medicoId: v.medicoId ? parseInt(v.medicoId) : undefined
                                            }))
                                            setMedPuntos(v.puntos)
                                        }}
                                    />
                                    <Box sx={{textAlign:"right", marginTop:0.5}}> Puntos: {medPuntos ?? 0} </Box>

                                </Box>


                                <TextField
                                    required
                                    value={state.canPuntos??""}
                                    name={"canPuntos"}
                                    onChange={(e:any) => setState({...state, canPuntos: e.target.value ? parseInt(e.target.value) : undefined})}
                                    label={Strings.puntos + " a Canjear"}
                                    sx={{mt:2,mb:2}}
                                    fullWidth
                                />
                                <TextField
                                    multiline
                                    value={state.canNota}
                                    name={"canNota"}
                                    onChange={(e:any) => setState({...state, canNota: e.target.value})}
                                    label={Strings.comentario}
                                    sx={{mt:2,mb:2}}
                                    fullWidth
                                />
                            </ValidatorFormCustom>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                        <Button disabled={status === "loading"} type="submit" form="form1">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{Strings.guardar}</span>
                            )}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
