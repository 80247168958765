import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  aprobarAutorizacion,
  mostrarAutorizaciones,
  rechazarAutorizacion
} from "../../redux/reducers/autorizacionesReducers";

import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';
import {LoadingProgress} from '../../components';
import Grid from "@mui/material/Grid";
import Strings from "../../assets/strings";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ValidatorFormCustom} from '../../helpers/form-validator';


export default function AutorizacionActions(){
  let navigate =useNavigate();

  const {state:urlState}:{state: any,pathname:string} = useLocation();
  const autActions = urlState?.autActions as {id:number, action:string};


  const Dispatch = useAppDispatch();
  const [note, setNote] = useState("");
  const [motivo, setMotivo] = useState("");


  const { status} = useAppSelector(state => state.autorizaciones)

  useEffect(() => {
    Dispatch(mostrarAutorizaciones({estado: "1"}));
  }, [Dispatch]);

  const handleSubmit = () => {
    const {id,action} = autActions;
    if(action==="aprobar"){
      Dispatch(aprobarAutorizacion({id, value:note}))
    }else{
      Dispatch(rechazarAutorizacion({id, autComentario: note, autMotivoRechazoId:motivo}))
    }
  }

  return <>
    <Dialog open={Boolean(autActions?.id && autActions?.action)} onClose={()=> {
      navigate(-1);
      setNote("")
    }} maxWidth={"sm"} fullWidth={true}>
      <TopBarDialog
          style={autActions?.action === "rechazar"?{backgroundColor: "#ff5722"}:{}}
          title={(autActions?.action === "aprobar"?Strings.aprobar:Strings.rechazar) +" "+ Strings.autorizacion} nofullScreen onClose={()=>navigate(-1)}/>
      <DialogContent>
        {status === "loading" && <LoadingProgress />}
        <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
          <Grid container spacing={3}>
            {autActions?.action === "rechazar" &&
                <Grid item xs={12}>
                  <SelectUsoMultiples
                      label={Strings.motivo}
                      value={motivo}
                      onChange={(v)=>setMotivo(v.key?.toString()??"")}
                      group={"AutorizacionesMotivosRechazo"}
                      required
                  />
                </Grid>
            }
            <Grid item xs={12}>
              <TextField
                  multiline
                  value={note}
                  onChange={({target})=>setNote(target.value)}
                  label={Strings.comentario}
                  fullWidth
              />
            </Grid>

          </Grid>
        </ValidatorFormCustom>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>navigate(-1)} color="primary">Cancelar</Button>
        <Button type="submit" form={"form1"} color="primary" disabled={status === "loading"}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

