import React, {useState} from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import {AspectRatioSharp,ImageAspectRatioSharp,Close,Check} from '@mui/icons-material';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import pdfIcon from '../assets/img/pdf.svg';
import Strings from "../assets/strings";
const useStyles = makeStyles(theme => ({
    groupImg:{
        "display": "flex",
        overflow: "hidden",
        "&>div":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "border": "1px solid #e9ecef",
            "position": "relative",
            "width": "105px",
            "height": "105px",
            "margin": "5px",
            "& img":{
                width:"100%",
            },
            "& .delete":{
                "fontSize": "21px",
                "position": "absolute",
                "right": "0",
                "top": "0",
                "cursor": "pointer",
                "color": "#f64a4a",
                "background": "#fff",
                "width": "40px",
                "height": "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
                "textAlign": "center",
                "WebkitTransition": "opacity .2s",
                "transition": "opacity .2s",
            },
            "&.deleted img":{
                opacity: .3,
            },
            "&.pdf img":{
                maxWidth: 66,
            }

        }
    },
    dialogContent:{
        padding: "3px !important",

    },
    dialogActions:{
        display:"flex",
        "& button:first-child":{
            marginRight: "auto",
        }
    }
}));
type TAdjunto = {
    arcBase64?:string,
    arcExtension?:string,
    arcPath?:string,
}
interface Iprops {
    adjuntos: TAdjunto[],
    onChange: (v: TAdjunto[]) => void,
    changeRadio?:boolean,
    accept?:string,
    label?:string
}



export default function AddAdjuntos({adjuntos=[],label=Strings.adjuntos, onChange, changeRadio = true,accept="image/gif, image/jpeg, image/png,application/pdf"}:Iprops){
    const classes = useStyles();

    const [img, setImg] = useState(undefined);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [aspectRatioSq, setAspectRatioSq] = useState(true);
    const [cropper, setCropper] = useState<any>();


    const showModal = () => {
        setShowAddForm(true)
    }
    const closeModal = () => {
        setLoading(false)
        setShowAddForm(false)
    }
    const handleChange = (e:any) => {
        e.preventDefault();
        setError("");
        let file:any;
        if (e.dataTransfer) {
            file = e.dataTransfer.files[0];
        } else if (e.target) {
            file = e.target.files[0];
        }
        if (!file) return false;
        if(file.type.indexOf("image") === -1 && file.type.indexOf("pdf") === -1) return setError(Strings.archivoNoPermitidoMsj)

        if(file.type.indexOf("pdf") !== -1 && file.size > 1000000) return setError(Strings.archivoExcedeMsj+ ", 1MB!");

        const reader = new FileReader();
        reader.onload = () => {
            if(file.type.indexOf("image") !== -1){
                setImg(reader.result as any);
                showModal()
            }else{
                let adj = adjuntos;
                adj.push({ arcExtension: "pdf", arcBase64: reader.result as string})
                onChange(adj);
            }
        };
        reader.readAsDataURL(file);

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setLoading(true)

            if (typeof cropper.getCroppedCanvas() === 'undefined') {
                return;
            }

            let adj = adjuntos;
            let arcBase64 = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);

            adj.push({ arcExtension: "jpeg", arcBase64});



            onChange(adj);
            closeModal();
        }
    };
    const delAdjunto = (index:number)  =>{


        let adj = adjuntos;
        if (!adj[index].arcBase64){
            adj[index].arcBase64 = "-1";
        } else if (adj[index].arcBase64 === "-1") {
            // @ts-ignore
            adj[index].arcBase64 = null
        }else{
            adj.splice(index, 1)
        }
        onChange(adj);



        // if(src && src.toString().length < 100 && src !== -1){
        //     setDeleted(src);
        //     onChange(-1)
        // }else{
        //     onChange(deleted)
        // }
    }

    return (
        <>
            <div className="contAdjuntos">
                <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"2px 5px"}>
                    <Typography style={{
                        flex: '1 1 100%',
                        lineHeight: "10px",
                        marginTop: 8
                    }} variant="body1" color={"secondary"}>
                        {label}
                        <br/>
                        <Typography variant={"caption"} sx={{color:"#2e2e2e"}}>Puedes adjuntar archivos PDF o Fotos (JPG, JPEG)</Typography>
                    </Typography>
                        <Button color={"primary"} component={"label"} disabled={adjuntos?.length >= 5}>
                            {Strings.agregar}
                            <input type="file" style={{display:"none"}} onChange={handleChange} accept={accept}  />
                        </Button>
                </Box>
                {!!error && <Alert onClose={() => setError("")} severity="warning">{error}</Alert>}
                <div className={classes.groupImg}>
                        {adjuntos?.length > 0 && adjuntos.map((adjunto, i) =>
                            <div key={i} className={(adjunto.arcExtension === "pdf" ? 'pdf' : 'img') + (adjunto.arcBase64 === "-1" ? ' deleted' : '') }>
                                <div className="delete" onClick={() => delAdjunto(i)}>{adjunto.arcBase64 === "-1" ? <Check color={"primary"}/> : <Close/>}</div>
                                <a href={adjunto.arcPath ? adjunto.arcPath.replace("-small", ""):""} target="_blank" rel="noopener noreferrer">
                                    <img src={adjunto.arcExtension === "pdf" ? pdfIcon : (adjunto.arcPath ? adjunto.arcPath : adjunto.arcBase64)} alt=""  />
                                </a>
                            </div>)
                        }
                </div>
            </div>
            <Dialog open={Boolean(showAddForm)} onClose={closeModal}>
                <DialogContent className={classes.dialogContent}>
                    <Cropper


                        zoomTo={0.5}
                        initialAspectRatio={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}

                        style={{ height: 400, width: '100%' }}
                        aspectRatio={aspectRatioSq ? 1 : undefined}
                        guides={false}
                        viewMode={0}
                        src={img}
                        // ref={cropper => setCropper(cropper)}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {changeRadio &&
                    <Button color="primary" onClick={() => setAspectRatioSq(!aspectRatioSq)}>
                        {aspectRatioSq ?(<AspectRatioSharp/>):(<ImageAspectRatioSharp color={"secondary"}/>)}
                    </Button>
                    }
                    <Button color="primary" onClick={closeModal}>{Strings.cerrar}</Button>
                    <Button
                        color="primary"
                        disabled={loading}
                        onClick={!loading ? getCropData : undefined} >
                        {loading ? Strings.cargando : Strings.agregar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
