import React, { useEffect } from 'react';
import { mostrarRoles } from '../../redux/reducers/rolesReducers';
import {MenuItem} from "@mui/material";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {errorMessagesC, SelectValidatorCustom} from "../../helpers/form-validator";

export default function SelectRol({value = "", disabled=false, name = "rolId",all=false, label, onChange, required}) {

    const {roles, status} = useAppSelector(state => state);
    const Dispatch = useAppDispatch();

    useEffect(() => {
        Dispatch(mostrarRoles())
    }, [Dispatch]);

    let options = (roles.list && roles.list.map(opc=>({key:opc.id,value:opc.rolDescripcion}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})
    const validators=required?['required']:[]

    return (
        <SelectValidatorCustom
            {...{value, validators,disabled:(status === "loading" || disabled),loading: status === "loading", errorMessages:errorMessagesC(validators), name, label, onChange}}
        >
            {options.map(o=>
                <MenuItem key={o.key} value={o.key}>{o.value}</MenuItem>
            )}
        </SelectValidatorCustom>
    )
}

