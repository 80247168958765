import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getRoles, addEditRol, getRolById, delRol} from '../../services';
import historyHelper from "../../helpers/history";
import {IRolState,IRol} from "../types/IRoles";
const initialState: IRolState = {
    status: "idle"
}

export const mostrarRoles = createAsyncThunk(
    'mostrarRoles', async (params:object|undefined ) => {
            const response = await getRoles()
            return {data: response.data as IRol[], params: response.config.params}
    }
)
export const mostrarRolById = createAsyncThunk(
    'mostrarRolById', async (id:number) => {
        const response = await getRolById(id)
        return response.data as IRol
    }
)
export const agregarEditarRol = createAsyncThunk(
    'roles/agregarEditar', async (Rol: IRol) => {
        const response = await addEditRol(Rol);

        if (response.status === 200 || response.status === 204) historyHelper.back();

        return {data: response.data, edit:!!Rol.id};

    }
);
export const eliminarRol = createAsyncThunk(
    'roles/eliminar', async (id: number) => {
        await delRol(id);
        return id;
    }
);

const slice = createSlice({
    name: 'mostrarRoles',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarRoles
        builder.addCase(mostrarRoles.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarRoles.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarRoles.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarRol
        builder.addCase(agregarEditarRol.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarRol.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list = state.list.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
            }
        })
        builder.addCase(agregarEditarRol.rejected, (state ) => {
            state.status = "idle";
        })

        //--mostrarRolById
        builder.addCase(mostrarRolById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarRolById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarRolById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarRol
        builder.addCase(eliminarRol.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarRol.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list){
                state.list = state.list.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarRol.rejected, (state ) => {
            state.status = "idle";
        })
    }

});




export default slice.reducer;
