import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    Theme,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, formatPeso, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {IPushMoney} from "../../redux/types/IPushMoney";
import Strings from "../../assets/strings";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import {agregarEditarPushMoney, mostrarPushMoneyById} from "../../redux/reducers/pushMoneyReducers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import pdfIcon from "../../assets/img/pdf.svg";
import {getValueString, VENTA_ESTADO} from "../../helpers/CONSTANTES";
import Notifications from "react-notification-system-redux";

const useStyles = makeStyles((theme:Theme) => ({
    adjuntosCont:{
        "& .contAdjuntos":{
            "& .img, & .pdf":{
                width: 50,
                height: 50,
                "& .delete":{
                    width: 20,
                    height: 20,
                }
            },

        }
    },
    groupImg:{
        "display": "flex",
        overflow: "hidden",
        "&>div":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #e9ecef",
            position: "relative",
            width: "50px",
            height: "50px",
            padding: "5px",
            margin: "5px",
            "& img":{
                width:"100%",
            },
            "&.pdf img":{
                maxWidth: 66,
            }

        }
    },
    top:{
        display:"flex",
        justifyContent: "space-between",
        color:theme.palette.secondary.main,
        background:theme.palette.grey[200],
        alignItems: "center",
        marginTop:theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
    clienteCont:{
        borderBottom:"1px solid "+theme.palette.grey[300],
        display:"flex",
        justifyContent:"space-between",
        margin:theme.spacing(0.5),
        padding:theme.spacing(1),
        "& .inputCli":{
            width:"100%",
            marginRight:theme.spacing(1),
        },
        "& .btnNew":{
            padding: "0px 15px",
            fontSize: "0.8em",
            "& svg":{
                marginLeft:5
            }
        }
    },
    total:{
        position:"absolute",
        bottom:0,
        width:"100%",
        background:theme.palette.grey[100],
        padding: 0,
    }
}));



export default function DetallePushMoney() {
    const {byId,status} = useAppSelector(state => state.pushMoney);

    const {state:urlState, pathname}:{state:any,pathname:string} = useLocation();
    const navigate =useNavigate();
    const classes = useStyles();
    const Dispatch = useAppDispatch()

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState<IPushMoney|null>(null);

    useEffect(() => {
        if(!!urlState && urlState.detallePushMoney) {
            setState(state => ({...state, ...(nullToEmptyString(urlState.detallePushMoney) as IPushMoney)}));
        }else {
            setState(null);
        }
    }, [setState, urlState]);


    useEffect(()=>{
        if(urlState?.detallePushMoney && urlState.detallePushMoney?.id !== byId?.id){
            Dispatch(mostrarPushMoneyById(Number(urlState.detallePushMoney.id)));
        }else if(byId){
            setState(state => ({...state, ...nullToEmptyString(byId) as IPushMoney}));
        }
    },[byId,setState,urlState,Dispatch])

    const actualizar = () =>{
        if(state){
            if(!state.psmPathAdjunto?.length) return Dispatch(Notifications.error({ title: 'Debes adjuntar foto de factura despachada.', autoDismiss: 4}));
            Dispatch(agregarEditarPushMoney(state))
        }
    }
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("detallePushMoney"))} onClose={goBack} maxWidth={"md"} fullWidth={true}>
                <TopBarDialog title={Strings.venta} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0.5}}>
                    {status === "loading" && <LoadingProgress />}
                    {!!state &&
                        <>
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.farmacia}</Typography>
                                        <Typography variant={"caption"}>{Strings.farmacia}</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.psmNumeroDoc}</Typography>
                                        <Typography variant={"caption"}>{Strings.numeroFactura}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatPeso(state.psmMonto)}</Typography>
                                        <Typography variant={"caption"}>PushMoney</Typography>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <Typography variant="body2">{ getValueString(VENTA_ESTADO,state.psmMonto) ?? "-"}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>
                                    {state.psmNota &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{state.psmNota}</Typography>
                                            <Typography variant={"caption"}>{Strings.comentario}</Typography>
                                        </Grid>
                                    }

                                </Grid>
                            </Box>
                            {state.detalle &&
                                <>
                                    <div className={classes.top}>
                                        <Typography noWrap>{state.detalle?.length} {Strings.articulos}</Typography>
                                    </div>
                                    <div>
                                        <List dense>
                                            {state.detalle?.map((p)=>
                                                <React.Fragment key={p.articuloId}>
                                                    <ListItem>
                                                        <ListItemText
                                                            secondary={p.psmCantidad? Strings.cantidad+": "+p.psmCantidad:""}
                                                        >
                                                            {p.articulo?.replace(/(.{35})..+/, "$1…")}
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </div>
                                </>
                            }
                            {state.psmPathAdjunto &&
                                <>
                                    {state.psmMonto !== 1 ?(<>
                                        <div className={classes.top}>
                                            <Typography noWrap>{Strings.adjuntos}</Typography>
                                        </div>
                                        <div>
                                            <div className={classes.groupImg}>
                                                    <div className={state.psmPathAdjunto?.indexOf("pdf") !== -1 ? 'pdf' : 'img'}>
                                                        <a href={state.psmPathAdjunto} target="_blank" rel="noopener noreferrer">
                                                            <img src={state.psmPathAdjunto?.indexOf("pdf") !== -1 ? pdfIcon : state.psmPathAdjunto} alt=""  />
                                                        </a>
                                                    </div>
                                            </div>
                                        </div>
                                    </>):(
                                        <Box className={classes.adjuntosCont}>
                                            {/*<AddAdjuntos*/}
                                            {/*    //         label={"Debes adjuntar foto de factura despachada."}*/}
                                            {/*    adjuntos={state.psmPathAdjunto?.map(adj=>({*/}
                                            {/*        arcPath:adj.url,*/}
                                            {/*        arcBase64:adj.base64,*/}
                                            {/*        arcExtension: (adj.url || adj.base64)?.indexOf("pdf") !== -1?"pdf":"jpg"*/}
                                            {/*    })) ?? []}*/}
                                            {/*    onChange={(val)=>{*/}
                                            {/*        setState(prevState => ({...prevState, adjuntos:val.map(v=>({*/}
                                            {/*                url: v.arcPath,*/}
                                            {/*                base64: v.arcBase64,*/}
                                            {/*            }))}))}}*/}
                                            {/*/>*/}
                                        </Box>
                                    )}

                                </>
                            }
                        </>
                    }
                    <br/>
                </DialogContent>
                {state?.psmMonto === 1 &&
                    <DialogActions>
                        <Button onClick={actualizar}>{Strings.actualizar}</Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    );

}

