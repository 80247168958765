import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getUsuTipo, hasPermissionTo, nullToEmptyString, PERSMISOS, TIPO_USUARIO} from '../../helpers';
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Switch,
    TextField,
    Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {agregarEditarFarmacia, eliminarFarmacia, mostrarFarmaciaById} from "../../redux/reducers/farmaciaReducers";
import {IFarmacia} from "../../redux/types/IFarmacia";
import ConfirmDialog from "../../components/ConfirmDialog";
import SelectPaiProMun from "../../components/selects/SelectPaiProMun";
import {PhoneTextField} from "../../components/CustomTextField";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";
import TopAppBar from "../../components/TopAppBar";
import ArticuloDetalles from "./ArticuloDetalles";
import SendSMS from "../../components/SendSMS";
import UsuariosFarmacia from "./Usuarios";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:IFarmacia = {
    farCodigo:"",
    farNombre:"",
    farInactivo:false,
    farRNC:"",
    farTelefono:"",
    farTelefono2:"",
    farEmail:"",
    pais:"",
    paisId:undefined,
    provincia:"",
    provinciaId:undefined,
    municipio:"",
    municipioId:undefined,
    sector:"",
    sectorId:undefined,
    farDireccion:"",
    //farmaciaIdPrincipal:"",
   // autorizadoPor:"",
   // usuId:"",
}
function CrearFarmacia(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlFarId} = useParams()

    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:farmacia,status} = useAppSelector(state => state.farmacias);

    const [state, setState] = useState(initialState);
    const [verUsuarios, setVerUsuarios] = useState<number>();


    useEffect(()=>{
        if(urlFarId) Dispatch(mostrarFarmaciaById(Number(urlFarId)));
    },[urlFarId,Dispatch])


    useEffect(()=>{
        if(!!urlFarId && farmacia?.id === parseInt(urlFarId)) {
            setState({...initialState, ...nullToEmptyString(farmacia)});
        }else{
            setState(initialState)
        }
    },[urlFarId,farmacia])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = () =>{
        Dispatch(agregarEditarFarmacia(state));
    }

    return(
        <Fragment>
            <TopAppBar/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}

                    <Box  sx={{marginBottom:1}}>
                        {!!state.id &&
                            <Box bgcolor={"#ececec"}>
                                {(getUsuTipo() === TIPO_USUARIO.admin || hasPermissionTo(PERSMISOS.seguridad)) &&

                                    <Button color={"primary"} onClick={()=>setVerUsuarios(state.id)}>
                                        {Strings.usuarios}
                                    </Button>
                                }


                                <Button
                                    color="primary"
                                    onClick={()=> {
                                        navigate(pathname,{state:{sendSMS:{medicoId: state.id , medico:state.farNombre}}})
                                    }}
                                >
                                    Enviar notificación
                                </Button>
                            </Box>
                        }
                    </Box>
                    {!!state.id &&
                        <SendSMS/>
                    }



                        <Paper className={classes.paper}>
                            <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                                <input autoComplete="false" name="hidden" type="text" style={{display:"none"}} />
                                <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidatorCustom
                                                name={"farCodigo"}
                                                label={Strings.codigo}
                                                disabled={!!state.id}
                                                value={state.farCodigo}
                                                onChange={handleChange}
                                                inputProps={{maxLength: 50}}
                                                validators={['required']}
                                                fullWidth
                                            />
                                        </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextValidatorCustom
                                            name={"farNombre"}
                                            label={Strings.nombre}
                                            value={state.farNombre}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextValidatorCustom
                                            name={"farRNC"}
                                            label={Strings.rnc}
                                            value={state.farRNC}
                                            onChange={handleChange}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <PhoneTextField name={"farTelefono"} validators={['required']} label={Strings.telefono} value={state.farTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <PhoneTextField
                                            name={"farTelefono2"}
                                            label={Strings.celular}
                                            value={state.farTelefono2}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 15}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextValidatorCustom
                                            type={"email"}
                                            name={"farEmail"}
                                            label={Strings.email}
                                            value={state.farEmail}
                                            onChange={handleChange}
                                            validators={['required','isEmail']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <SelectPaiProMun required value={{
                                        pais:state.pais,
                                        paisId:state.paisId,
                                        provincia:state.provincia,
                                        provinciaId:state.provinciaId,
                                        municipio:state.municipio,
                                        municipioId:state.municipioId,
                                        sector:state.sector,
                                        sectorId:state.sectorId
                                    }} onChange={(val)=>setState(prev=>({...prev,...val}))} />

                                    <Grid item xs={12} md={6}>
                                        <TextValidatorCustom
                                            name={"farDireccion"}
                                            label={Strings.direccion}
                                            value={state.farDireccion}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 200}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name={"farDireccion2"}
                                            label={Strings.direccion+" "+Strings.referencia}
                                            value={state.farDireccion2}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 100}}
                                            fullWidth
                                        />
                                    </Grid>
                                    {getUsuTipo() !== TIPO_USUARIO.farmacia &&
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={state.farInactivo} color={"secondary"}
                                                                                onChange={({target})=>setState({...state, farInactivo:target.checked})} />}
                                                                labelPlacement="end" label={Strings.inactivo}/>
                                        </FormGroup>
                                    </Grid>}
                                </Grid>
                            </ValidatorFormCustom>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ArticuloDetalles farmaciaState={state} onChange={(articulos)=>setState({...state, articulos})}/>
                                </Grid>
                            </Grid>
                        </Paper>

                    <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                        {!!state.id &&
                            <Box sx={{marginRight:"auto"}}>
                                {hasPermissionTo(PERSMISOS.farmaciasActualizar) &&
                                    <Button onClick={() => {
                                        navigate(pathname, {state: {"confirmDialog": state.id}})
                                    }}
                                            color="error">{Strings.eliminar}
                                    </Button>
                                }
                            </Box>
                        }


                        <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                        <Button disabled={status === "loading"} type="submit" disableElevation form="form1" value="Submit">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{Strings.guardar}</span>
                            )}
                        </Button>
                    </Box>

                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarFarmacia(urlState.confirmDialog))}/>
            }

            <UsuariosFarmacia farId={verUsuarios} onClose={()=>setVerUsuarios(undefined)}/>


        </Fragment>
    );

}
export default CrearFarmacia;
