import Ventas from '../views/Ventas';
import Recetas from '../views/Recetas';
import CrearVenta from '../views/Ventas/crearVenta/index';

import Perfil from '../views/cuenta/perfil';
import inicio from '../views/inicio/inicio2';

import Articulos from '../views/Articulos';
import ArticuloMarcas from "../views/Articulos/ArticuloMarcas";
import Categorias from "../views/Articulos/Categorias";
import CrearArticulo from "../views/Articulos/crearArticulo";

import CentrosMedicos from "../views/CentrosMedicos";
import CrearCentroMedico from '../views/CentrosMedicos/crearCentroMedico';

import Medicos from "../views/Medicos";
import CrearMedico from '../views/Medicos/crearMedico';

// import Ofertas from "../views/Ofertas";
// import CrearOferta from '../views/Ofertas/crearOferta';
import Farmacias from "../views/Farmacia";
import DetallesFarmacia from '../views/Farmacia/detallesFarmacia';

import CrearFarmacia from '../views/Farmacia/crearFarmacia';

import Pacientes from "../views/Pacientes";
import EditarPaciente from '../views/Pacientes/editarPaciente';
import EditarPariente from '../views/Pacientes/editarPariente';

import DetallesPaciente from '../views/Pacientes/detallesPaciente';
import Autorizaciones from '../views/Autorizaciones';
import Canjes from '../views/Canjes';

import Roles from '../views/roles';
import Usuarios from '../views/Usuarios';

import PushMoney from "../views/PushMoney";
import CrearPushMoney from "../views/PushMoney/crearPushMoney";
import Reposiciones from "../views/Reposiciones";


//import Notificaciones from '../views/Notificaciones';
import Paises from '../views/Paises';
import UsosMultiples from '../views/usosMultiples';


//Reportes
//const Enums = lazy(() => import('../views/enums'));
import {
    AccountLockOutline,
    AccountOutline,
    ClipboardAccountOutline,
    Doctor,
    FileDocumentOutline,
    FormatListBulleted,
    HospitalBuilding,
    ReceiptOutline,
    ShieldKeyOutline,
    StoreOutline,
    SwapHorizontal,
    TuneVariant,
    ViewDashboardOutline
} from "mdi-material-ui";

import {getUsuTipo, hasPermissionTo, PERSMISOS, TIPO_USUARIO} from "../helpers";
import {IRoutes} from "../redux/types/IRoutes";
import Configuracion from '../views/Configuracion';
import Visitas from "../views/Visitas";
import {MedicalServicesOutlined} from "@mui/icons-material";
import Entregas from "../views/Visitas/Entregas";


const getRoutes = () =>{
    let obj:IRoutes[] = [
        {
            path: "/perfil",
            component: Perfil,
            navbarName: "Perfil",
            icon: AccountOutline,
        },

        {
            path: "/inicio",
            sidebarName: "inicio",
            navbarName: "inicio",
            icon: ViewDashboardOutline,
            component: inicio,
        },
        {
            sidebarName: "transacciones",
            icon: SwapHorizontal,
            nestedRoutes: [
                ...((getUsuTipo() !== TIPO_USUARIO.medico) ?
                        [
                            ...((hasPermissionTo(PERSMISOS.ventas)) ? [
                                {
                                    path: "/ventas",
                                    sidebarName: "ventas",
                                    navbarName: "Incentivo",
                                    icon: ReceiptOutline ,
                                    component: Ventas,
                                    nestedRoutes: [
                                        {
                                            path: "/crear/venta",
                                            sidebarName: "Crear Incentivo",
                                            navbarName: "Crear Incentivo",
                                            component: CrearVenta
                                        }
                                    ]
                                },
                            ]:[]),
                            ...((hasPermissionTo(PERSMISOS.pushMoney)) ? [
                                {
                                    path: "/pushmoney",
                                    sidebarName: "pushMoney",
                                    navbarName: "pushMoney",
                                    icon: ReceiptOutline ,
                                    component: PushMoney,
                                    nestedRoutes: [
                                        {
                                            path: "/crear/PushMoney",
                                            sidebarName: "Crear pushmoney",
                                            navbarName: "Crear pushmoney",
                                            component: CrearPushMoney
                                        }
                                    ]
                                }
                            ]:[]),
                        ]: []
                ),
                ...((getUsuTipo() !== TIPO_USUARIO.farmacia && getUsuTipo() !== TIPO_USUARIO.paciente) ?
                        [{
                            path: "/canjes",
                            sidebarName: "canjes",
                            navbarName: "canjes",
                            icon: SwapHorizontal ,
                            restricted: !hasPermissionTo(PERSMISOS.canjes),
                            component: Canjes,
                        }]: []
                ),

                ...((getUsuTipo() !== TIPO_USUARIO.medico && hasPermissionTo(PERSMISOS.reposiciones)) ?
                    [{
                    path: "/reposiciones",
                    sidebarName: "reposiciones",
                    navbarName: "reposiciones",
                    icon: SwapHorizontal ,
                    component: Reposiciones,
                }]: []),

                ...((getUsuTipo() !== TIPO_USUARIO.farmacia) ?
                        [{
                            path: "/recetas",
                            sidebarName: "recetas",
                            navbarName: "recetas",
                            icon: FileDocumentOutline ,
                            restricted: !hasPermissionTo(PERSMISOS.recetas),
                            component: Recetas,
                        }]: []
                ),

                ...(getUsuTipo() === TIPO_USUARIO.visitador && getUsuTipo() !== TIPO_USUARIO.admin  ? [
                    {
                        path: "/medicos",
                        sidebarName: "medicos",
                        navbarName: "Medicos",
                        icon: Doctor,
                        restricted: !hasPermissionTo(PERSMISOS.medicos),
                        component: Medicos,
                        nestedRoutes:[
                            {
                                path: "/crear/medico",
                                navbarName: "Crear Medico",
                                component: CrearMedico
                            },
                            {
                                path: "/editar/medico/:id",
                                navbarName: "Editar Medico",
                                component: CrearMedico
                            },
                        ]
                    },

                ]:[]),

                {
                    path: "/visitas",
                    sidebarName: "visitas",
                    navbarName: "visitas",
                    icon: MedicalServicesOutlined,
                    // restricted: !hasPermissionTo(PERSMISOS.visitas),
                    component: Visitas,
                },
                {
                    path: "/entregas",
                    sidebarName: "entregas",
                    navbarName: "entregas",
                    icon: MedicalServicesOutlined,
                    // restricted: !hasPermissionTo(PERSMISOS.entregas),
                    component: Entregas,
                },
            ]
        },




        ...(getUsuTipo() === TIPO_USUARIO.admin ?
            [
                {
                    sidebarName: "mantenimiento",
                    navbarName: "mantenimiento",
                    icon: FormatListBulleted,
                    nestedRoutes: [
                        {
                            path: "/pacientes",
                            sidebarName: "pacientes",
                            navbarName: "Pacientes",
                            icon: ClipboardAccountOutline,
                            restricted: !hasPermissionTo(PERSMISOS.pacientes),
                            component: Pacientes,
                            nestedRoutes: [
                                {
                                    path: "/detalles/paciente/:id",
                                    sidebarName: "detalles paciente",
                                    navbarName: "Detalles paciente",
                                    component: DetallesPaciente
                                },
                                {
                                    path: "/editar/paciente/:id",
                                    sidebarName: "editar Paciente",
                                    navbarName: "Editar Paciente",
                                    component: EditarPaciente
                                },
                                {
                                    path: "/crear/paciente",
                                    sidebarName: "crear Paciente",
                                    navbarName: "Crear Paciente",
                                    component: EditarPaciente
                                },
                                {
                                    path: "/crear/pariente/:pacId",
                                    sidebarName: "editar Paciente",
                                    navbarName: "Editar Paciente",
                                    component: EditarPariente
                                },
                                {
                                    path: "/editar/pariente/:pacId/:id",
                                    sidebarName: "editar Paciente",
                                    navbarName: "Editar Paciente",
                                    component: EditarPariente
                                },
                            ]
                        },
                        {
                            path: "/medicos",
                            sidebarName: "medicos",
                            navbarName: "Medicos",
                            icon: Doctor,
                            restricted: !hasPermissionTo(PERSMISOS.medicos),
                            component: Medicos,
                            nestedRoutes:[
                                {
                                    path: "/crear/medico",
                                    navbarName: "Crear Medico",
                                    component: CrearMedico
                                },
                                {
                                    path: "/editar/medico/:id",
                                    navbarName: "Editar Medico",
                                    component: CrearMedico
                                },
                            ]
                        },

                        {
                            path: "/farmacias",
                            sidebarName: "farmacias",
                            navbarName: "Farmacias",
                            icon: StoreOutline,
                            restricted: !hasPermissionTo(PERSMISOS.farmacias),
                            component: Farmacias,
                            nestedRoutes: [
                                {
                                    path: "/detalles/farmacia/:id",
                                    sidebarName: "detalles Farmacia",
                                    navbarName: "Detalles Farmacia",
                                    component: DetallesFarmacia
                                },
                                {
                                    path: "/crear/farmacia",
                                    sidebarName: "Crear Farmacia",
                                    navbarName: "Crear Farmacia",
                                    component: CrearFarmacia
                                },
                                {
                                    path: "/editar/farmacia/:id",
                                    sidebarName: "editar Farmacia",
                                    navbarName: "Editar Farmacia",
                                    component: CrearFarmacia
                                },
                                {
                                    path: "/crearSucursal/farmacia/:farPrincipal",
                                    sidebarName: "editar Farmacia",
                                    navbarName: "Editar Farmacia",
                                    component: CrearFarmacia
                                },
                            ]
                        },
                        //-------
                        {
                            path: "/articulos",
                            sidebarName: "articulos",
                            navbarName: "Articulos",
                            restricted: !hasPermissionTo(PERSMISOS.articulos),
                            component: Articulos,
                            nestedRoutes:[
                                {
                                    path: "/crear/articulo",
                                    navbarName: "articulos",
                                    component: CrearArticulo
                                },
                                {
                                    path: "/editar/articulo/:id",
                                    navbarName: "articulos",
                                    component: CrearArticulo
                                },
                            ]
                        },
                        {
                            path: "/marcas",
                            sidebarName: "marcas",
                            navbarName: "Marcas",
                            restricted: !hasPermissionTo(PERSMISOS.marcas),
                            component: ArticuloMarcas
                        },
                        {
                            path: "/categorias",
                            sidebarName: "categorias",
                            navbarName: "Categorias",
                            restricted: !hasPermissionTo(PERSMISOS.lineas),
                            component: Categorias
                        },
                        //-------
                        {
                            path: "/centrosMedicos",
                            sidebarName: "centrosMedicos",
                            navbarName: "Centros Medicos",
                            icon: HospitalBuilding,
                            restricted: !hasPermissionTo(PERSMISOS.centrosMedicos),
                            component: CentrosMedicos,
                            nestedRoutes:[
                                {
                                    path: "/crear/centroMedico",
                                    navbarName: "Crear CentroMedico",
                                    component: CrearCentroMedico
                                },
                                {
                                    path: "/editar/centroMedico/:id",
                                    navbarName: "Editar CentroMedico",
                                    component: CrearCentroMedico
                                },
                            ]
                        },
                        {
                            path: "/paises",
                            sidebarName: "paises",
                            navbarName: "Paises",
                            component: Paises,
                            nestedRoutes:[
                                {
                                    path: "/paises/:paiId",
                                    navbarName: "Provincias",
                                    component: Paises
                                },
                                {
                                    path: "/paises/:paiId/:proId",
                                    navbarName: "Municipios",
                                    component: Paises
                                },
                                {
                                    path: "/paises/:paiId/:proId/:munId",
                                    navbarName: "Sectores",
                                    component: Paises
                                },
                            ]
                        },
                        {
                            path: "/UsosMultiples",
                            sidebarName: "usosMultiples",
                            navbarName: "Usos Multiples",
                            restricted: !hasPermissionTo(PERSMISOS.usosMultiples),
                            component: UsosMultiples
                        },
                    ]
                },
                {
                    sidebarName: "seguridad",
                    navbarName: "seguridad",
                    icon: AccountLockOutline,
                    nestedRoutes: [
                        {
                            path: "/usuarios",
                            sidebarName: "usuarios",
                            navbarName: "Usuarios",
                            component: Usuarios
                        },
                        {
                            path: "/roles",
                            sidebarName: "rolesPermisos",
                            navbarName: "Roles y Permisos",
                            component: Roles
                        }
                    ]
                },
                {
                    path: "/configuracion",
                    sidebarName: "configuracion",
                    navbarName: "configuracion",
                    icon: TuneVariant,
                    //   restricted: !hasPermissionTo(PERSMISOS.autorizaciones),
                    component: Configuracion
                },
            ] : [
                {
                    path: "/editar/paciente/:id",
                    navbarName: "Editar Paciente",
                    component: EditarPaciente
                },
                {
                    path: "/editar/medico/:id",
                    navbarName: "Editar Medico",
                    component: CrearMedico
                },
                {
                    path: "/editar/farmacia/:id",
                    navbarName: "Editar Farmacia",
                    component: CrearFarmacia
                },
            ]),
        ...(hasPermissionTo(PERSMISOS.autorizaciones) ?
            [
                {
                    path: "/autorizaciones",
                    sidebarName: "autorizaciones",
                    navbarName: "autorizaciones",
                    icon: ShieldKeyOutline,
                    component: Autorizaciones
                }
            ]: []),
        {
            redirect:true,
            to: "/inicio"
        },

    ]
    return obj
}
export default getRoutes;
