import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogContent, Grid, List, ListItem, ListItemText, Theme, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {IReceta} from "../../redux/types/IReceta";
import Strings from "../../assets/strings";
import {mostrarRecetaById} from "../../redux/reducers/recetasReducers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import Divider from "@mui/material/Divider";
import pdfIcon from "../../assets/img/pdf.svg";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme:Theme) => ({
    groupImg:{
        "display": "flex",
        overflow: "hidden",
        "&>div":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #e9ecef",
            position: "relative",
            width: "50px",
            height: "50px",
            padding: "5px",
            margin: "5px",
            "& img":{
                width:"100%",
            },
            "&.pdf img":{
                maxWidth: 66,
            }

        }
    },
    top:{
        display:"flex",
        justifyContent: "space-between",
        color:theme.palette.secondary.main,
        background:theme.palette.grey[200],
        alignItems: "center",
        marginTop:theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
}));

export default function DetalleReceta() {
    const classes = useStyles();
    const {byId,status} = useAppSelector(state => state.recetas);

    const {state:urlState}:{state:any} = useLocation();
    const navigate =useNavigate();
    const Dispatch = useAppDispatch()

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState<IReceta|null>(null);

    useEffect(() => {
        if(!!urlState && urlState.receta) {
            setState(state => ({...state, ...(nullToEmptyString(urlState.receta) as IReceta)}));
        }else {
            setState(null);
        }
    }, [setState, urlState]);


    useEffect(()=>{
        if(urlState?.receta && urlState.receta.id !== byId?.id){
            Dispatch(mostrarRecetaById(Number(urlState.receta.id)));
        }else if(byId){
            setState(state => ({...state, ...nullToEmptyString(byId) as IReceta}));
        }
    },[byId,setState,urlState,Dispatch])

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("receta"))} onClose={goBack} maxWidth={"md"} fullWidth={true}>
                <TopBarDialog title={Strings.receta} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0.5}}>
                    {status === "loading" && <LoadingProgress />}
                    {!!state &&
                        <>
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fechaCreacion}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.recFechaVencimiento)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fechaVencimiento}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.paciente}</Typography>
                                        <Typography variant={"caption"}>{Strings.paciente}</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.centroMedico || "-"}</Typography>
                                        <Typography variant={"caption"}>{Strings.centroMedico}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.centroMedicoCiudad || "-"}</Typography>
                                        <Typography variant={"caption"}>Ciudad</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.medico}</Typography>
                                        <Typography variant={"caption"}>{Strings.medico}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.especialidad || "-"}</Typography>
                                        <Typography variant={"caption"}>{Strings.especialidad}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.recCodigo || "-"}</Typography>
                                        <Typography variant={"caption"}>{Strings.codigo}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.recEstadoString || "-"}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                            {state.detalle &&
                                <>
                                    <div className={classes.top}>
                                        <Typography noWrap>{state.detalle?.length} {Strings.articulos}</Typography>
                                    </div>
                                    <div>
                                        <List dense>
                                            {state.detalle?.map((p)=>
                                                <React.Fragment key={p.articuloId}>
                                                    <ListItem>
                                                        <ListItemText
                                                            secondary={
                                                                (p.recCantidad? Strings.cantidad+": "+p.recCantidad:"") +
                                                                (p.recCantidadEntregada? " / "+Strings.cantidad+" entregada: "+p.recCantidadEntregada:"")
                                                            }
                                                        >
                                                            {p.articulo?.replace(/(.{35})..+/, "$1…")}
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </div>
                                </>
                            }
                            {state.adjuntos &&
                                <>
                                    <div className={classes.top}>
                                        <Typography noWrap>{Strings.adjuntos}</Typography>
                                    </div>
                                    <div>
                                        <div className={classes.groupImg}>
                                            {state.adjuntos.map((adjunto, i) =>
                                                <div key={i} className={adjunto.url?.indexOf("pdf") !== -1 ? 'pdf' : 'img'}>
                                                    <a href={adjunto.url} target="_blank" rel="noopener noreferrer">
                                                        <img src={adjunto.url?.indexOf("pdf") !== -1 ? pdfIcon : adjunto.url} alt=""  />
                                                    </a>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    <br/>
                </DialogContent>
            </Dialog>
        </>
    );

}

