import React, {useEffect, useState} from 'react';

import {Box, Checkbox, TableBody, TableCell, TableFooter, TableRow, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress, TableSort} from '../../components';

import Strings from "../../assets/strings";
import {formatDate, formatPeso} from "../../helpers";
import {useAppDispatch} from "../../redux/hooks";
import {IPushMoney} from "../../redux/types/IPushMoney";
import {getValueString, VENTA_ESTADO} from "../../helpers/CONSTANTES";
import {getReposicionPushmoney} from "../../services";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:0,
  },
  contList:{
    border: "1px solid "+theme.palette.grey[200],
  }
}));

function PushMoneyDetalle({farmaciaId, onSelect, selected}:{farmaciaId:number, onSelect:(pushMoney:IPushMoney[])=>void, selected:IPushMoney[]}){
  const Dispatch = useAppDispatch();

  const classes = useStyles();
  const [list, setList] = useState<IPushMoney[]>([]);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    setLoading(true)
    getReposicionPushmoney(farmaciaId).then(({data})=>{
      setList(data)
    }).finally(()=>{
      setLoading(false)
    })
  }, [Dispatch,farmaciaId,setLoading,setList]);

  return <>

    <section className={classes.root} style={loading?{opacity:.8}:{opacity:1}}>
      {loading && <LoadingProgress vh/>}
      <Box className={classes.contList}>
        <TableSort data={[
          {pro:"fechaCreacion",text:""},
          {pro:"id",text:"ID"},
          {pro:"fechaCreacion",text:Strings.fecha},
          {pro:"psmEstado",text:Strings.estado},
          {pro:"farmacia",text:Strings.farmacia},
          {pro:"psmNumeroDoc",text:Strings.numeroFactura},
          {pro:"psmMonto",text:"PushMoney"},
        ]} onSort={()=>{}} lessVh={155}>
          <TableBody>
            {selected.filter(s=> !(list ?? []).find(v=>v.id===s.id))?.map((psm:IPushMoney) =>
                <TableRow key={psm.id}
                          onClick={()=> {
                            let isSelected = selected.find(v=>v.id===psm.id);
                            if(isSelected){
                              onSelect(selected.filter(v=>v.id!==psm.id))
                            }else{
                              onSelect([psm, ...selected])
                            }
                          }}
                >
                  <TableCell className="actionCell" sx={{width:100}} >
                    <Checkbox checked={!!selected.find(v=>v.id===psm.id)} />
                  </TableCell>
                  <TableCell>{psm.id}</TableCell>
                  <TableCell>{formatDate(psm.fechaCreacion)}</TableCell>
                  <TableCell>
                      {getValueString(VENTA_ESTADO,psm.psmEstado)}
                  </TableCell>
                  <TableCell>{psm.farmacia}</TableCell>
                  <TableCell>{psm.psmNumeroDoc}</TableCell>
                  <TableCell>{formatPeso(psm.psmMonto)}</TableCell>
                </TableRow>
            )}

            {list.map((psm:IPushMoney) =>
                <TableRow key={psm.id}
                          onClick={()=> {
                            let isSelected = selected.find(v=>v.id===psm.id);
                            if(isSelected){
                              onSelect(selected.filter(v=>v.id!==psm.id))
                            }else{
                              onSelect([psm, ...selected])
                            }
                          }}
                >
                  <TableCell className="actionCell" sx={{width:100}} >
                    {/*onClick={e=>e.stopPropagation()}*/}
                    <Checkbox checked={!!selected.find(v=>v.id===psm.id)} />
                  </TableCell>
                  <TableCell>{psm.id}</TableCell>
                  <TableCell>{formatDate(psm.fechaCreacion)}</TableCell>
                  <TableCell>
                      {getValueString(VENTA_ESTADO,psm.psmEstado)}
                  </TableCell>
                  <TableCell>{psm.farmacia}</TableCell>
                  <TableCell>{psm.psmNumeroDoc}</TableCell>
                  <TableCell>{formatPeso(psm.psmMonto)}</TableCell>
                </TableRow>
            )}
            <TableFooter>
              <TableRow >
                <TableCell colSpan={6}/>
                <TableCell>{formatPeso(selected?.reduce((pre, cur) => pre + (cur.psmMonto ?? 0), 0))}</TableCell>
              </TableRow>
            </TableFooter>
          </TableBody>
        </TableSort>
      </Box>

    </section>

  </>;
}

export default PushMoneyDetalle;

