import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useNavigate} from "react-router-dom";
import Strings from "../assets/strings"

export default function ConfirmDialog({onConfirm,replace, children, title=Strings.confirmacion}:{onConfirm:Function,replace?:string,children?:any,title?:string}) {
    let navigate = useNavigate();
   // const { state }:{state:any} = useLocation()

    const confirm = () => {
        if(onConfirm) onConfirm();
        if(replace){
            navigate(replace,{replace:true})
        }else {
            navigate(-1)
        }
    };

    return (
        <div>
            <Dialog
                open={true} //Boolean(state?.confirmDialog >= 0)
                onClose={()=>navigate(-1)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {children?(
                            <>{children}</>
                        ):(
                            <>{Strings.confirmEliminar}</>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>navigate(-1)}>
                        {Strings.cancelar}
                    </Button>
                    <Box style={{color:"red"}}>
                        <Button onClick={confirm} color="inherit" >
                            {Strings.proceder}
                        </Button>
                    </Box>

                </DialogActions>
            </Dialog>
        </div>
    );
}
