import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {IUsuario} from "../../redux/types/IUsuarios";
import {delUsuario, getUsuariosFarmacia} from "../../services";
import Strings from "../../assets/strings";
import {HtmlTooltip, TopBarDialog} from "../../components";
import CrearUsuario from "../Usuarios/CrearUsuario";
import {hasPermissionTo, PERSMISOS} from '../../helpers';
import {Close} from "@mui/icons-material";
import ConfirmDialog from "../../components/ConfirmDialog";


export default function UsuariosFarmacia({farId, onClose}:{farId?:number, onClose:()=>void}) {

    const {state:urlState,pathname}:{state:any, pathname:string} = useLocation();
    const navigate =useNavigate();

    const goBack = () => {
        onClose()
    }
    const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
    useEffect(()=>{
        if(farId){
            getUsuariosFarmacia(farId).then(res=>{
                setUsuarios(res.data)
            })
        }
    },[setUsuarios,farId])

    const onUpdate = (data:IUsuario) => {
        let found = usuarios?.find(item=>item.id === data.id);

        if(found){
            setUsuarios(prevState => prevState.map(item=>{
                if(item.id === data.id){
                    return data
                }else{
                    return item
                }
            }))
        }else{
            setUsuarios(prevState => ([data, ...prevState]))
        }
    }
    const eliminar = (id:number) => {
        delUsuario(id).then(()=>{
            setUsuarios(prevState => prevState.filter(item=>item.id !== id))
        })
    }


    return (
        <>
            <Dialog open={!!farId} maxWidth={"md"} fullWidth={true}>
                <TopBarDialog title={"Usuarios"} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0}}>
                    <TableContainer>
                        <Table className={"hasOnclick"}>
                            <TableHead>
                                <TableCell/>
                                <TableCell> {Strings.nombreDeUsuario}</TableCell>
                                <TableCell> {Strings.primerNombre}</TableCell>
                                <TableCell> {Strings.email}</TableCell>
                                <TableCell> {Strings.rol}</TableCell>
                                <TableCell> {Strings.tipo}</TableCell>
                            </TableHead>
                            <TableBody>
                                {usuarios?.map(item =>
                                    <TableRow key={item.id} className={item.usuInactivo ? "text-red-deep":""} onClick={()=>navigate(pathname, {state: {usuario:item}})}>
                                        <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                                            <HtmlTooltip title={Strings.anular}>
                                                <IconButton size={"small"}
                                                            disabled={!item.id}
                                                            onClick={(e)=> {
                                                                navigate(pathname, {state: {eliminarUsuario: item.id}})
                                                            }}>
                                                    <Close fontSize={"small"} className={"text-red"}/>
                                                </IconButton>
                                            </HtmlTooltip>
                                        </TableCell>
                                        <TableCell> {item.usuInicioSesion }</TableCell>
                                        <TableCell> {item.usuNombre}</TableCell>
                                        <TableCell> {item.usuEmail}</TableCell>
                                        <TableCell> {item.rol}</TableCell>
                                        <TableCell> {!!item.usuTipoString && (Strings as any)[item.usuTipoString!]}</TableCell>

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    {hasPermissionTo(PERSMISOS.usuariosCrear) &&
                        <Button color={"inherit"} onClick={()=>navigate(pathname,{state: {usuario:{id:""}}})}>Crear usuario</Button>
                    }
                </DialogActions>
            </Dialog>

            <CrearUsuario usuariosFarmacia={farId} onUpdate={onUpdate}/>
            {!!urlState?.hasOwnProperty("eliminarUsuario") &&
                <ConfirmDialog onConfirm={() => eliminar(urlState.eliminarUsuario)}/>
            }
        </>
    );

}

