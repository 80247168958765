import React from 'react';
import {Box, Button, ButtonGroup, Theme} from '@mui/material';
import {toggleViewTable} from "../redux/reducers/themeReducer";
import {FormatListBulleted, ListAlt} from "@mui/icons-material";
import Strings from "../assets/strings";
import makeStyles from '@mui/styles/makeStyles';
import {useAppDispatch, useAppSelector} from "../redux/hooks";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        width: "100%",
        height: "40px",
        margin: "0",
        display: "flex",
        padding: "0px 8px",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#faf5ec",
        borderBottom:"1px solid #fefefe"
        // [theme.breakpoints.up('md')]: {
        //     marginTop: "15px",
        // }
    }
}));


function ListViewMode({lg,noView,newBtnText = Strings.crear+" "+Strings.nuevo, onNew,children}:{lg?:boolean,newBtnText?:string,noView?:boolean,onNew?:any,children?:any}){
    const viewTableList = useAppSelector(state=>state.theme.viewTableList);
    const dispatch = useAppDispatch();

    const classes = useStyles();

    return(
        <div className={classes.root}>
            {onNew ? (
                <Button onClick={onNew} color={"primary"}>{newBtnText}</Button>
            ):(<div/>)}
            {/*<Typography variant={"body2"}>{!!pageRows && <>{pageRows} {Strings.de} {pageTotalRows}</>}</Typography>*/}
           <Box alignItems={"center"} display={"flex"}>
               {children}
               {!noView &&
                   <Box sx={  !lg? { display: { xs: 'block', md: 'none' }  }:{} } >
                       <ButtonGroup size="small" aria-label="small outlined button group">
                           <Button style={{marginLeft: 5}} color={viewTableList ? "primary":"inherit"}  onClick={()=> !viewTableList && dispatch(toggleViewTable())}><FormatListBulleted fontSize={"small"}/></Button>
                           <Button color={!viewTableList ? "primary":"inherit"}  onClick={()=>viewTableList && dispatch(toggleViewTable())} ><ListAlt fontSize={"small"}/></Button>
                       </ButtonGroup>
                   </Box>
               }

           </Box>
        </div>)
}
export default ListViewMode;

