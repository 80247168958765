import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {IReposicion} from "../../redux/types/IReposicion";
import Strings from "../../assets/strings";
import {mostrarReposicionById} from "../../redux/reducers/reposicionesReducers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";


export default function DetalleReposicion() {
    const {byId,status} = useAppSelector(state => state.reposiciones);

    const {state:urlState}:{state:any} = useLocation();
    const navigate =useNavigate();
    const Dispatch = useAppDispatch()

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState<IReposicion|null>(null);

    useEffect(() => {
        if(!!urlState && urlState.reposicion) {
            setState(state => ({...state, ...(nullToEmptyString(urlState.reposicion) as IReposicion)}));
        }else {
            setState(null);
        }
    }, [setState, urlState]);


    useEffect(()=>{
        if(urlState?.reposicion && urlState.reposicion.id !== byId?.id){
            Dispatch(mostrarReposicionById(Number(urlState.reposicion.id)));
        }else if(byId){
            setState(state => ({...state, ...nullToEmptyString(byId) as IReposicion}));
        }
    },[byId,setState,urlState,Dispatch])

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("reposicion"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={Strings.reposicion} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0.5}}>
                    {status === "loading" && <LoadingProgress />}
                    {!!state &&
                        <>
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.farmacia}</Typography>
                                        <Typography variant={"caption"}>{Strings.farmacia}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.repMonto}</Typography>
                                        <Typography variant={"caption"}>{Strings.monto}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{!!state.repInactivo ? Strings.inactivo : Strings.activo}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    }
                    <br/>
                </DialogContent>
            </Dialog>
        </>
    );

}

