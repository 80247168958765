import React, {useCallback, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography
} from "@mui/material";
import {LoadingProgress, TopBarDialog} from "../../../components";
import ListPreVenta from "./ListPreVenta";
import TopAppBar from "../../../components/TopAppBar";

import Strings from '../../../assets/strings'
import {getArticulosByFarmacia, getOfertasDisponible, getRecetaByCode} from "../../../services";
import {Visibility} from "@mui/icons-material";
import SelectSearchFarmacia from "../../../components/selects/SelectSearchFarmacia";
import {IVenta, IVentaDetalle} from "../../../redux/types/IVenta";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {agregarEditarVenta, mostrarVentaById} from "../../../redux/reducers/ventasReducers";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {formatPeso, nullToEmptyString, TIPO_USUARIO} from "../../../helpers";
import ConfirmDialog from "../../../components/ConfirmDialog";
import {IOferta, IOfertaDetalle} from "../../../redux/types/IOferta";
import ListOferta from "./ModalOfertas";
import {TextValidatorCustom, ValidatorFormCustom} from "../../../helpers/form-validator";
import {getTokenInfo} from "../../../services/auth";
import {IReceta} from "../../../redux/types/IReceta";
import {IArticulo} from "../../../redux/types/IArticulo";
import AddAdjuntos from "../../../components/AddAdjuntos";
import DetalleReceta from "../../Recetas/DetalleReceta";
import Notifications from "react-notification-system-redux";
import {mostrarParametros} from "../../../redux/reducers/parametrosReducers";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
       // paddingTop: theme.mixins.toolbar.minHeight,
        "& .topSearch":{
            background: theme.palette.grey[200],
          //  padding:`25px 0px 0px 8px`
            padding: "25px 10px 0px 10px",
            position: "sticky",
            top: "-206px",
            [theme.breakpoints.down('sm')]: {
                top: "-266px",
            }
        },
        "& .left":{
            height: "calc(100vh - 50px)",
            overflow: "auto",
        },
        "& .contAdjuntos":{
            "& .img, & .pdf":{
                width: 50,
                height: 50,
                "& .delete":{
                    width: 20,
                    height: 20,
                }
            },

        }
    },
    rightCont:{
        height: "25%",
        [theme.breakpoints.down('md')]: {
            position: "fixed",
            width: "100%",
            height: "131px",
            //height: "100%",
            bottom: 0,
            zIndex: 3000,
            transition: "height .4s",
            "&.opened":{
                height: "100%",
            }
        }
    },
    search:{
        marginTop:theme.spacing(1),
        "& input":{
            padding: theme.spacing(1),
            width: "100%",
            boxSizing: "border-box",
            marginBottom: 10,
            border: "1px solid #d4d4d4",
        }
    },
    itemsCont:{
    //    height: "calc(100vh - 263px)",
        background: theme.palette.common.white,
    },
    preVenta:{
        position:"relative",
        borderLeft: "1px solid " +theme.palette.grey[200],
        paddingTop: 14,
        height: "100%",
        background: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            paddingTop:0,
        }
    },
    tabs:{
        display:"flex",
        height: Number(theme.mixins.toolbar.minHeight) -13,
        background: theme.palette.grey[300],
        justifyContent:"space-between",
        "& ul":{
            display:"flex",
            padding:0,
            "& li":{
                borderTop:"2px solid " +theme.palette.grey[400],
                borderRight: "1px solid " +theme.palette.grey[400],
                "&:first-child":{
                    borderTop:"2px solid " +theme.palette.primary.main,
                    background: theme.palette.common.white,
                }
            }
        }

    },
    item:{
        cursor: "pointer",
    }
}));
const initialState:IVenta = {
    id: undefined,
    farmaciaId: undefined,
    farmacia: "",
    venDependiente: "",
    recetaIdHash: "",
    recetaId:undefined,
    venNumeroDoc: "",
    venLineas: undefined,
    venIndicadorVentaLibre: false,
    venNota: "",
    venPuntos:0,
    detalle:[],
    adjuntos:[],
}

export default function CrearVenta() {
    const classes = useStyles();
    const navigate = useNavigate();
    let { id:urlComId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const user = useAppSelector(state=>state.auth.user)
    const {list: parametros} = useAppSelector(state=>state.parametros)
    // const [porcientoDescuento, setPorcientoDescuento] =  React.useState(0);

    // useEffect(()=>{
    //     if(parametros?.length){
    //         let found = parametros.find(p=>p.parTipo === 3);
    //         if(found){
    //             setPorcientoDescuento(parseFloat(found.parValor || "0"))
    //         }
    //     }
    // },[parametros,setPorcientoDescuento])


    const Dispatch = useAppDispatch()
    const [state, setState] =  React.useState(initialState);
    const [loading, setLoading] =  React.useState<boolean>(false);
    const [loadingReceta, setLoadingReceta] =  React.useState<boolean>(false);
    const [articulos, setArticulos] =  React.useState<IArticulo[]>();
    const [ofertas, setOfertas] =  React.useState<IOferta[]>();
    const artEnOfertas:IOfertaDetalle[] = ofertas?.reduce((a:IOfertaDetalle[],b)=> a.concat([...b.detalle]),[]) ?? []
    const [viewCart, setViewCart] =  React.useState(false);
    const [receta, setReceta] = useState<IReceta>();
    useEffect(()=>{
        if(receta && !parametros?.length){
           Dispatch(mostrarParametros())
        }
    },[receta,Dispatch, parametros])

    // const [filterArticulos, setFilterArticulos] =  React.useState<IArticulo[]>();

    const [editingItem, setEditingItem] = React.useState<IVentaDetalle|null>(null);
    const {byId:venta,status} = useAppSelector(state => state.ventas);

    const [filter, setFilter] = useState({name:'', categoriaId:''});



    useEffect(()=>{
        if(urlComId) Dispatch(mostrarVentaById(Number(urlComId)));
    },[urlComId,Dispatch])
    useEffect(()=>{
        setReceta(undefined);
        if(!!urlComId && venta?.id === parseInt(urlComId)) {
            setState({...initialState, ...nullToEmptyString(venta)});
        }else{
            setState(initialState)
        }
    },[urlComId,setReceta, venta])


    let fetchProducts = useCallback((id:number, name?:string)=>{
        getArticulosByFarmacia(id, name).then(res=>{
            setArticulos(res.data.results)
        })
    },[setArticulos])

    useEffect(()=>{

        // let filtered = articulos?.filter((p)=>
        //     noTilde(p.artNombre).indexOf(noTilde(filter.name??"")) !== -1 ||
        //     noTilde(p.artCodigo).indexOf(noTilde(filter.name??"")) !== -1 ||
        //     noTilde(p.artCodigoBarra).indexOf(noTilde(filter.name??"")) !== -1
        // )
        //
        // setFilterArticulos(filtered)
        if(state.farmaciaId){
            fetchProducts(state.farmaciaId, filter.name)
        }

    },[state.farmaciaId, filter, fetchProducts])

    useEffect(()=>{
        if(state.farmaciaId){
            setLoading(true)
            getOfertasDisponible(state.farmaciaId).then(({data})=>{
                setOfertas(data);
                setState(prevState => ({...prevState, detalle:prevState.detalle?.filter(d=>!d.ofertaId)}))

            }).finally(()=>setLoading(false))
            setState(prevState => ({...prevState,detalle:prevState.detalle?.filter(d=>!d.ofertaId)??[]}))

            fetchProducts(state.farmaciaId)

        }else{
            setArticulos([])
        }
    },[state.farmaciaId,setOfertas, setState,fetchProducts,setArticulos])

    useEffect(()=>{
        if(user?.usuTipo === TIPO_USUARIO.farmacia){
            setState(prevState => ({...prevState,farmaciaId:(getTokenInfo() as any)["RelatedId"],farmacia:user.usuNombre}))
        }else if(user?.usuTipo === TIPO_USUARIO.paciente){
            setState(prevState => ({...prevState,pacienteId:(getTokenInfo() as any)["RelatedId"], paciente:user.usuNombre}))
        }
    },[user, setState])

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value, type, checked} = e.target
        return setState({...state,[name]: type === "checkbox"?checked: value});
    }

    const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFilter({...filter,[name]:value})
    };
    const onAddItem = async ()=>{
        let list = [...(state.detalle ?? [])];
        if(editingItem){
           // let venPuntos = 0;
            // if(!editingItem.ofertaId){
            //     // await validarPuntos({
            //     //     pacienteId: state.pacienteId,
            //     //     articuloId: editingItem.articuloId,
            //     //     cantidad: editingItem.venCantidad,
            //     //     cantidadComprimidos: editingItem.venCantidadDetalle??0
            //     // }).then(({data})=>venPuntos=data)
            // }

            if(list?.length){
                let found = list?.find(s=>(s.articuloId === editingItem.articuloId && s.ofertaId === editingItem.ofertaId));
                if(found) {
                    list = list.map(l => {
                        if (l.articuloId === editingItem.articuloId && l.ofertaId === editingItem.ofertaId)
                            return {...editingItem, accion: editingItem.accion === 3 ? 2 : editingItem.accion};

                        return l
                    })
                    setState(prev=>({...prev, detalle: list}))

                }else{
                    setState(prev=>({...prev, detalle: [{...editingItem},...list]}))
                }

            }else{
                setState(prev=>({...prev, detalle: [{...editingItem}]}))
            }
        }
        setEditingItem(null)

    }
    const deleteItem = () => {
        let list = [...(state.detalle ?? [])];
        let index =  list.findIndex(l=>l.articuloId===editingItem?.articuloId && l.ofertaId === editingItem?.ofertaId)


        if(index === -1) return false;

        if(list[index].accion === 1){
            list.splice(index,1)
        }else if(list[index].accion === 3){
            list.splice(index,1,{...list[index],accion:2})

        }else{
            list.splice(index,1,{...list[index],accion:3})
        }
        setEditingItem(null)
        setState(prev=>({...prev, detalle: list}))
    }

    const handleEditingItem = ({id:articuloId, artNombre: articulo,artIndicadorDesc,artPrecio}:IArticulo)=>{
        let list = [...(state.detalle ?? [])];
        let found = list?.find(item=>item.articuloId === articuloId && !item.ofertaId);

        if(found){
            setEditingItem({...found,accion:(found.accion === 0 || found.accion === 3)?2:found.accion})
        }else{
            let item:IVentaDetalle = {
                accion: 1,
                articuloId,
                articulo,
                venDescuento: artPrecio, //(!!receta && !!artIndicadorDesc) ? (artPrecio||0) * (porcientoDescuento/100):0,
                venCantidad: 1,
                artPrecio,
            }
            if(urlComId) item.ventaId = parseInt(urlComId);

            setEditingItem(item)
        }
    }

    const submit = () =>{
        if(!state.adjuntos?.length) return Dispatch(Notifications.error({ title: 'Debes adjuntar foto de factura despachada.', autoDismiss: 4}));
        if(state.recetaIdHash && !receta) {
            return Dispatch(Notifications.error({ title: 'Receta no encontrada.', autoDismiss: 4}));
        }
        Dispatch(agregarEditarVenta(state))
    }
    let selectedArt = articulos?.find(a=>a.id === editingItem?.articuloId);
    const searchReceta = () => {
        if(state.recetaIdHash){
            setLoadingReceta(true)
            getRecetaByCode(state.recetaIdHash).then(({data})=>{
                setState(prev=>({...prev,recetaId:data.id ,detalle:[]}))
                setReceta(data);
             //   fetchProducts({indicadorDescuento:1})
            }).catch(()=> {
                Dispatch(Notifications.error({ title: 'Receta no encontrada.', autoDismiss: 4}));
                setState(prev=>({...prev,detalle:[], recetaIdHash:"", recetaId:undefined}));
                setReceta(undefined);
              //  fetchProducts({indicadorDescuento:0})
            })
                .finally(()=>setLoadingReceta(false))
        }else{
            setState(prev=>({...prev,recetaId:undefined,detalle:[]}))
            setReceta(undefined);
          //  fetchProducts({indicadorDescuento:0})
        }
    }


    return (
        <>
            <TopAppBar />

            <div className={classes.root}>
                {(loading || status === "loading") && <LoadingProgress vh/>}
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8}>
                        <div className={"left"}>
                            <div className="topSearch">
                                <ValidatorFormCustom onSubmit={submit} id={"formVenta"}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>
                                        <SelectSearchFarmacia required //disabled={user?.usuTipo === 5}
                                            disabled={user?.usuTipo === TIPO_USUARIO.farmacia}
                                            value={{
                                                farmaciaId: (state.farmaciaId ?? "").toString(),
                                                farmacia: state.farmacia ?? ""
                                            }}
                                            onChange={(v)=> setState(prev=>({...prev,
                                                farmacia: v.farmacia,
                                                farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextValidatorCustom
                                            name={"venNumeroDoc"}
                                            label={Strings.numeroFactura}
                                            value={state.venNumeroDoc}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 20}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={4}>
                                        <TextField fullWidth label={"Dependiente"} name={"venDependiente"} value={state.venDependiente}
                                                   onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField fullWidth label={"ID Autorización Paciente"} onBlur={searchReceta} name={"recetaIdHash"}
                                                   value={state.recetaIdHash}
                                                   disabled={!!state.detalle?.length}
                                                   onClick={()=>{
                                                       if(!!state.detalle?.length){
                                                           navigate(pathname, {state:{clearProductList:true}})
                                                       }
                                                   }}
                                                  // disabled={()=>setState({...state, detalle:[]})}
                                                   onChange={handleChange}
                                                   onKeyDown={(e:any)=>{
                                                       e.stopPropagation();
                                                       if(e.key === 'Enter'){
                                                           searchReceta()
                                                       }
                                                   }}

                                                   InputProps={{
                                                       endAdornment:
                                                           <InputAdornment position="end">
                                                               {!!receta ? (
                                                                   <IconButton
                                                                       aria-label="Ver receta"
                                                                       onClick={() => navigate(pathname, {state: {receta}})}
                                                                       edge="end"
                                                                   >
                                                                       <Visibility/>
                                                                   </IconButton>) :
                                                                   (<>{loadingReceta && <CircularProgress size={15}/>}</>)
                                                               }
                                                           </InputAdornment>
                                                   }}
                                        />
                                    </Grid>


                                        {/*<Typography sx={{lineHeight: 1.2}}>Adjunto (imagen o pdf) *</Typography>*/}
                                        {/*<SelectFiles showImg={false} src={state.comAdjunto??""} label={"Adjunto (imagen o pdf)"} required={true}*/}
                                        {/*             accept="image/*,.pdf"*/}
                                        {/*             onChange={(comAdjunto:string)=>setState(prevState => ({...prevState,comAdjunto}))}/>*/}
                                        {/*<div style={{height:0,width:0, overflow:"hidden"}}>*/}
                                        {/*    <input value={state.comAdjunto??""} required onChange={()=>{}}/>*/}
                                        {/*</div>*/}
                                    <Grid item xs={12} sm={user?.usuTipo === TIPO_USUARIO.paciente?12: 8}>
                                        <TextField
                                                   name={"venNota"}
                                                   label={Strings.comentario}
                                                   value={state.venNota}
                                                   onChange={handleChange}
                                                   inputProps={{maxLength: 100}}
                                                   fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box bgcolor={"common.white"} padding={"4px"}>
                                            <AddAdjuntos
                                                label={"Debes adjuntar foto de factura despachada."}
                                                adjuntos={state.adjuntos?.map(adj=>({
                                                    arcPath:adj.url,
                                                    arcBase64:adj.base64,
                                                    arcExtension: (adj.url || adj.base64)?.indexOf("pdf") !== -1?"pdf":"jpg"
                                                })) ?? []}
                                                onChange={(val)=>{
                                                    setState(prevState => ({...prevState, adjuntos:val.map(v=>({
                                                            url: v.arcPath,
                                                            base64: v.arcBase64,
                                                        }))}))}}
                                            />
                                        </Box>

                                    </Grid>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <TopSearch onFilter={handleFilter} filter={filter}/>*/}
                                    {/*</Grid>*/}
                                </Grid>
                                </ValidatorFormCustom>

                                {!!state.farmaciaId && !!receta && <>
                                    <Box className={classes.search}>
                                        <input type="rearch" placeholder={Strings.buscar+" "+Strings.articulos} name="name" value={filter.name} onChange={handleFilter}/>
                                    </Box>
                                    <div className={classes.itemsCont}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{Strings.codigo}</TableCell>
                                                        <TableCell>{Strings.nombre}</TableCell>
                                                        <TableCell>Código de barra</TableCell>
                                                        <TableCell>Descuento</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {articulos?.map((art:IArticulo, index) =>
                                                        <TableRow key={index} onClick={()=>handleEditingItem(art)}>
                                                            <TableCell>{art.artCodigo}</TableCell>
                                                            <TableCell >
                                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                                    <div>{art.artNombre}</div>
                                                                    {artEnOfertas.findIndex(o=>o.articuloId === art.id) !== -1 &&
                                                                        <Chip label={Strings.oferta} size="small" variant={"outlined"} color="secondary" />
                                                                    }
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>{art.artCodigoBarra}</TableCell>
                                                            <TableCell>{formatPeso(art.artPrecio)}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </>}
                            </div>

                        </div>
                        {!!ofertas?.length &&
                            <ListOferta list={ofertas} selected={state.detalle ?? []} onChage={(detalle)=>setState({...state,detalle})}/>
                        }
                    </Grid>
                        <Grid item xs={12} md={4} className={classes.rightCont + " "+(viewCart?"opened":"")} onClick={()=>setViewCart(!viewCart)}>
                            {/*sx={{ display: { xs: 'none', md: 'block' } }}*/}
                            <div className={classes.preVenta}>
                                <ListPreVenta
                                    list={state.detalle??[]}
                                    onClick={setEditingItem}
                                    listOfertas={artEnOfertas}
                                />
                            </div>
                        </Grid>
                </Grid>
            </div>

            <Dialog open={!!editingItem} onClose={()=>setEditingItem(null)} fullWidth maxWidth={"xs"}>
                <TopBarDialog onClose={() => setEditingItem(null)} nofullScreen
                              title={editingItem?.articulo?.replace(/(.{25})..+/, "$1…") ?? ""}/>
                <DialogContent>
                    <ValidatorFormCustom onSubmit={onAddItem} id={"formArticulo"}>
                        <Grid container spacing={1}>
                                {!!receta &&
                                <Grid item xs={12}>
                                    <TextValidatorCustom
                                        name={"venCantidadReceta"}
                                        type={"number"}
                                        label={"Cant. Recetada"}
                                        disabled={receta.recEstado !== 2}
                                        value={editingItem?.venCantidadReceta??""}
                                        onChange={({target}:any)=>setEditingItem(prev=> {
                                            return {...prev, venCantidadReceta: target.value ? parseInt(target.value) : undefined}
                                        })}
                                        validators={['required']}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                }
                                <Grid item xs={12}>
                                    <TextField
                                        type={"number"}
                                        label={"Cantidad Despachada"}
                                        value={editingItem?.venCantidad??""}
                                        onChange={({target})=>setEditingItem(prev=> {
                                            let venCantidad = target.value ? parseInt(target.value) : undefined;
                                            let venDescuento = 0;

                                          //  (venCantidad ?? 0) * ((prev?.venDescuento??0)/(prev?.venCantidad??0));
                                            if(selectedArt?.artPrecio && venCantidad){
                                                venDescuento = (selectedArt.artPrecio! * venCantidad);
                                            }

                                            return {...prev!, venCantidad,  venDescuento}
                                        })}
                                        required
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                {/*{!!receta && !!editingItem?.venDescuento &&*/}
                                    <>
                                        {/*<Grid item xs={12}>*/}
                                        {/*    <TextField*/}
                                        {/*        type={"number"}*/}
                                        {/*        label={Strings.precio}*/}
                                        {/*        value={editingItem?.artPrecio??""}*/}
                                        {/*        fullWidth*/}
                                        {/*        onChange={()=>{}}*/}
                                        {/*        margin="dense"*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={12}>
                                            <Typography>Descuento: {formatPeso(editingItem?.venDescuento)}</Typography>
                                        </Grid>
                                    </>
                                {/*// }*/}
                        </Grid>

                        <div>

                        </div>
                    </ValidatorFormCustom>

                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"}
                            disabled={state.detalle?.findIndex(l=>l.articuloId===editingItem?.articuloId) === -1}
                            onClick={deleteItem}
                    >
                        {editingItem?.accion===3?Strings.agregar:Strings.eliminar}
                    </Button>
                    <Button type={"submit"} form={"formArticulo"} disabled={!editingItem?.venCantidad}>{Strings.guardar}</Button>
                </DialogActions>
            </Dialog>
            {!!urlState?.hasOwnProperty("receta") &&
                <DetalleReceta/>
            }

            {urlState?.hasOwnProperty("clearProductList") &&
                <ConfirmDialog title={"Atención"} onConfirm={()=>setState({...state, detalle:[]})}>
                    <Typography>Si cambias el código se eliminarán los artículos agregados.</Typography>
                </ConfirmDialog>
            }
        </>
    )
}


