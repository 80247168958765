import React, {useEffect} from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import Strings from "../../assets/strings";
import {mostrarPaises} from "../../redux/reducers/paisesReducers";
import {IIdNameAccion} from "../../redux/types/Icommon";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {errorMessagesC, SelectValidatorCustom} from "../../helpers/form-validator";

type TProps = {
    label?:string,
    value:IIdNameAccion,
    onChange: (val:IIdNameAccion)=>void
    required?:boolean,
}

let emptyValue:IIdNameAccion = {
    name:""
}

export default function SelectPais({ value=emptyValue,label=Strings.pais, onChange, required}:TProps) {
    let {list,status} = useAppSelector((state=>state.paises))
    let Dispatch = useAppDispatch()

    useEffect(()=>{
        if(!list?.length){
            Dispatch(mostrarPaises())
        }
    },[list,Dispatch])
    const handleChange = (e:SelectChangeEvent)=>{
        const {value} = e.target
        let selected = list?.find(l=>l.id===parseInt(value));
        onChange(selected?selected:emptyValue)
    }

    const validators=required?['required']:[]

    return (
        <SelectValidatorCustom
            {...{value:value?.id? value.id.toString():"", validators,loading:status==="loading",name:"", errorMessages:errorMessagesC(validators), label, onChange:handleChange}}
        >
            {/*{value?.id && !list?.find(l=>l.id===value.id) &&*/}
                <MenuItem value={""} >{Strings.seleccione}</MenuItem>
            {/*// }*/}
            {list?.map(item=>
                <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
            )}
        </SelectValidatorCustom>
    )
}
