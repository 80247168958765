import React from 'react';
import Strings from "../../assets/strings";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ITransaccion} from "../../redux/types/ITransaccion";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate} from "../../helpers";

type Iprops = {
    list?:ITransaccion[],
}

export default  function ListTransacciones({list}:Iprops){
    let navigate =useNavigate();
    let location =useLocation();

    return (
        <div>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"3.5px 5px"}>
                <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    {Strings.transacciones}
                </Typography>
            </Box>
                {!!list?.length ?(
                    <>
                        <TableContainer>
                            <Table className={"hasOnclick"} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{Strings.fecha}</TableCell>
                                        <TableCell>{Strings.tipo}</TableCell>
                                        <TableCell>{Strings.medico}</TableCell>
                                        <TableCell>{Strings.farmacia}</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map((item) =>
                                        <TableRow key={item.id} onClick={()=> {
                                            if(item.traTipo === 1){
                                                navigate(location.pathname, {state: {compra:{id:item.referenciaId}}})
                                            }else if(item.traTipo === 2){
                                                navigate(location.pathname, {state: {canje:{id:item.referenciaId}}})
                                            }
                                        }}>
                                            <TableCell>{formatDate(item.fechaCreacion)}</TableCell>
                                            <TableCell>{item.traTipoString}</TableCell>
                                            <TableCell>{item.medico}</TableCell>
                                            <TableCell>{item.farmacia}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ):(
                    <Box p={1}>
                        {Strings.emptyMsj}
                    </Box>
                )}
        </div>
    )
}
