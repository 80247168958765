import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {IconButton, InputBase, useMediaQuery} from '@mui/material';
import {Search,Close} from "@mui/icons-material";
import Strings from "../assets/strings";

const useStyles = makeStyles(theme => ({
  search: {
    zIndex:theme.zIndex.modal,
    border: '1px solid #ced4da',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      position: "fixed",
      top: 0,
      padding: "7px 0",
      left: 0,
      background: theme.palette.primary.main,
      border: "none",
      borderRadius: 0,
    },


  },
  inputRoot: {
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.shape.borderRadius,
      background: "white",
      margin: "4px 12px",
      width: "calc(100% - 24px)",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),
    transition: theme.transitions.create('width'),
    width: '100%',
    color: "black",
    [theme.breakpoints.up('md')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  searchIcon:{
    marginRight: theme.spacing(1),
  },
  closeIcon:{
    position: "absolute",
    top: 7,
    right: 5,
    fontSize: "1.3rem",
    cursor: "pointer",
    [theme.breakpoints.down('md')]: {
      top: 18,
      right: 20,
      fill: "black",
    },
  }
}));
var searchTimeout = null;

export default function SearchAppBar({value, onChange, name = "search"}){
  const theme = useTheme();
  const smVewport = useMediaQuery(theme.breakpoints.down('md'));

      const [showSearch, setShowSearch] = useState(false);
      const classes = useStyles();

      const handleShow = () => {
        if(showSearch && value) onChange({target:{value:"",name}}); //borrar busqueda
        setShowSearch(!showSearch)
      };

    const handleChange = e => {
      const {name, value} = e.target;
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=> onChange({target:{value,name}}), 1000);
    };


      return (
          <>
            {showSearch &&
              <div className={classes.search}>
                <InputBase
                    placeholder={Strings.buscar}
                    defaultValue={value}
                    onChange={handleChange}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search', "autoFocus":true,'name':name }}
                />
                <Close className={classes.closeIcon} onClick={handleShow}/>
              </div>
            }
            {(!showSearch || (showSearch && smVewport)) &&
              <IconButton className={classes.searchIcon} color="inherit" size={"small"} onClick={()=>setShowSearch(!showSearch)}>
                <Search />
              </IconButton>
            }
          </>
      );
}