import { createSlice,createAsyncThunk,PayloadAction } from '@reduxjs/toolkit'
import { getPerfil,ediPerfil} from '../../services';
import {IUser, IUserState} from '../types/IUser';
import history from "../../helpers/history";
import jwt_decode from "jwt-decode";
import Notifications from "react-notification-system-redux";
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


const initialState:IUserState = {
    status:"idle",
    isAuthenticated: false,
    user: {
        rol: "",
        token: "",
        usuAviso: 0,
        usuEmail: "",
        usuInicioSesion: "",
        usuNombre: "",
        usuTipoPlan: 0,
    },
}

export const mostrarPerfil = createAsyncThunk(
    'mostrarPerfil', async (user:IUser|undefined, thunkAPI ) => {

        // if(user?.accessToken){
        //     if(user.genLocalidad) setLSLang(null,user.genLocalidad);
        //     return user
        // }

        try {
            const response = await getPerfil()
            return response.data as IUser;

//            setLSLang(null,re?.value.data.genLocalidad)

        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)
export const editarPerfil = createAsyncThunk(
    'editarPerfil', async (user:IUser, thunkAPI ) => {

        const response = await ediPerfil(user);
        if (response.status === 200) thunkAPI.dispatch(Notifications.success({ title: 'Guardado con éxito', message: '', autoDismiss: 5, }));

        return user;
    }
)


const slice = createSlice({
    name: 'authReducer',
    initialState,
    reducers: {
        setCurrentUserInfo(state:IUserState, action: PayloadAction<string>) {
            const decoded = jwt_decode(action.payload);
            state.user = decoded as IUser;
            console.log(action.payload)

        },
        userLogout(state:IUserState) {
            state.isAuthenticated = false;
            state.user = initialState.user;

            localStorage.removeItem('_token');

            if(iOS){
                window.location.href = "/login"
            }else{
                history.push("/login")
            }
        },
    },
    extraReducers: builder => {
        //---mostrarPerfil
        builder.addCase(mostrarPerfil.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(mostrarPerfil.fulfilled, (state, action) => {
            state.status = "idle"
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        builder.addCase(mostrarPerfil.rejected, (state, action) => {
            state.status = "idle"
        })

        //---editarPerfil
        builder.addCase(editarPerfil.pending, (state, action) => {
            state.status = "loading"
        })
        builder.addCase(editarPerfil.fulfilled, (state, action) => {
            state.user = {...state.user,...action.payload}
        })
        builder.addCase(editarPerfil.rejected, (state, action) => {
            state.status = "idle"
        })
    }

});



export const {userLogout,setCurrentUserInfo} = slice.actions;
export const authReducer = slice.reducer;
