import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import Strings from "../../assets/strings";
import {IIdNameAccion} from "../../redux/types/Icommon";
import SelectPais from "./SelectPais";
import SelectList from "./SelectList";
import {getMunicipiosPorProvincias, getProvinciasPorPais, getSectoresPorMunicipios} from "../../services";

type TProps = {
    label?:string,
    value:IObj,
    onChange: (val:IObj)=>void,
    required?:boolean,

}


interface IObj {
    pais?:string,
    paisId?:number,
    provincia?:string,
    provinciaId?:number,
    municipio?:string,
    municipioId?:number,
    sector?:string,
    sectorId?:number,
}
let initialState:IObj={
    pais:"",
    paisId:undefined,
    provincia:"",
    provinciaId:undefined,
    municipio:"",
    municipioId:undefined,
    sector:"",
    sectorId:undefined,
}
export default function SelectPaiProMun({ value=initialState, onChange, required}:TProps) {
    //lista de provincia y municipios
    const [loading, setLoading] = useState<boolean>(false)
    const [listPro, setListPro] = useState<IIdNameAccion[]>([])
    const [listMun, setListMun] = useState<IIdNameAccion[]>([])
    const [listSec, setListSec] = useState<IIdNameAccion[]>([])


    useEffect(()=>{
        if(value.paisId){
            setLoading(true)
            getProvinciasPorPais(value.paisId).then((res)=>{
                setListPro(res.data)
            }).finally(()=>setLoading(false))
        }else {
            setListPro([])
        }
    },[value.paisId,setLoading,setListPro])
    useEffect(()=>{
        if(value.provinciaId){
            setLoading(true)
            getMunicipiosPorProvincias(value.provinciaId).then((res)=>{
                setListMun(res.data)
            }).finally(()=>setLoading(false))
        }else {
            setListMun([])
        }
    },[value.provinciaId,setLoading,setListMun])
    useEffect(()=>{
        if(value.municipioId){
            setLoading(true)
            getSectoresPorMunicipios(value.municipioId).then((res)=>{
                setListSec(res.data)
            }).finally(()=>setLoading(false))
        }else {
            setListSec([])
        }
    },[value.municipioId,setLoading,setListSec])
    return (
        <>
            <Grid item xs={6} sm={3}>
                <SelectPais
                    required={required}
                    value={{
                        id: value.paisId ?? undefined,
                        name: value.pais ?? ""
                    }}
                    onChange={(v)=>
                        onChange({
                            pais:v.name,
                            paisId:v.id,
                            provincia:"",
                            provinciaId:undefined,
                            municipio:"",
                            municipioId:undefined,
                            sector:"",
                            sectorId:undefined
                        })}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <SelectList
                    required={required}
                    loading={loading}
                    label={Strings.provincia}
                    list={listPro}
                    disabled={!listPro.length}
                    value={{
                        id: value.provinciaId ?? undefined,
                        name: value.provincia ?? ""
                    }}
                    onChange={(v)=>
                        onChange({
                            provincia:v.name,
                            provinciaId:v.id,
                            municipio:"",
                            municipioId:undefined,
                            sector:"",
                            sectorId:undefined
                        })
                    }
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <SelectList
                    required={required}
                    list={listMun}
                    loading={loading}
                    label={Strings.municipio}
                    disabled={!listMun.length}
                    value={{
                        id: value.municipioId ?? undefined,
                        name: value.municipio ?? ""
                    }}
                    onChange={(v)=>{
                        onChange({
                            municipio:v.name,
                            municipioId:v.id,
                            sector:"",
                            sectorId:undefined
                        })
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <SelectList
                    required={required}
                    list={listSec}
                    loading={loading}
                    label={Strings.sector}
                    disabled={!listSec.length}
                    value={{
                        id: value.sectorId ?? undefined,
                        name: value.sector ?? ""
                    }}
                    onChange={(v)=>{
                        onChange({
                            sector:v.name,
                            sectorId:v.id
                        })
                    }}
                />
            </Grid>
        </>
    );
}
