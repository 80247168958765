import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getArticulosMarcas,addEditArticulosMarca,delArticulosMarca} from '../../services';
import {IArticulosMarcaState, IArticulosMarcaRes, IArticulosMarca} from "../types/IArticulosMarcas";
import historyHelper from "../../helpers/history";
const initialState: IArticulosMarcaState = {
    status: "idle"
}

export const mostrarArticulosMarcas = createAsyncThunk(
    'mostrarArticulosMarcas', async (params?:object|undefined ) => {
        const response = await getArticulosMarcas(params)
        return {data:response.data as IArticulosMarcaRes, params: response.config.params}
    }
)
export const agregarEditarArticuloMarca = createAsyncThunk(
    'articulosMarcas/agregarEditar', async (articulosMarca: IArticulosMarca) => {
        const response = await addEditArticulosMarca(articulosMarca);

        if (response.status === 200) historyHelper.back();
        return {data: response.data, edit:!!articulosMarca.id};
    }
);
export const eliminarArticuloMarca = createAsyncThunk(
    'articulosMarcas/eliminar', async (id: number) => {
        await delArticulosMarca(id);
        return id;
    }
);
const slice = createSlice({
    name: 'mostrarArticulosMarcas',
    initialState,
    reducers: {
        changeStatus: (state,{payload}) => {
            state.status = payload;
        },
    },
    extraReducers: builder => {
        //--mostrarArticulosMarcas
        builder.addCase(mostrarArticulosMarcas.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarArticulosMarcas.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarArticulosMarcas.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarArticuloMarca
        builder.addCase(agregarEditarArticuloMarca.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarArticuloMarca.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarArticuloMarca.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarArticuloMarca
        builder.addCase(eliminarArticuloMarca.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarArticuloMarca.fulfilled, (state,action ) => {
            state.status = "idle";

            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarArticuloMarca.rejected, (state ) => {
            state.status = "idle";
        })
    }

});

export const { changeStatus } = slice.actions;



export default slice.reducer;
