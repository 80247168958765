import React, {useEffect, useState} from 'react';

import {Box, Checkbox, TableBody, TableCell, TableFooter, TableRow, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress, TableSort} from '../../components';

import Strings from "../../assets/strings";
import {formatDate, formatPeso} from "../../helpers";
import {useAppDispatch} from "../../redux/hooks";
import {IVenta} from "../../redux/types/IVenta";
import {getValueString, VENTA_ESTADO} from "../../helpers/CONSTANTES";
import {getReposicionVentas} from "../../services";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:0,
  },
  contList:{
    border: "1px solid "+theme.palette.grey[200],
  }
}));

function VentasDetalle({farmaciaId, onSelect, selected}:{farmaciaId:number, onSelect:(ventas:IVenta[])=>void, selected:IVenta[]}){
  const Dispatch = useAppDispatch();

  const classes = useStyles();
  const [list, setList] = useState<IVenta[]>([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    getReposicionVentas(farmaciaId).then(({data})=>{
      setList(data)
    }).finally(()=>{
      setLoading(false)
    })
  }, [Dispatch,farmaciaId,setLoading,setList]);



  return <>

    <section className={classes.root} style={loading?{opacity:.8}:{opacity:1}}>
      {loading && <LoadingProgress vh/>}
      <Box className={classes.contList}>
        <TableSort data={[
          {pro:"fechaCreacion",text:""},
          {pro:"id",text:"ID"},
          {pro:"fechaCreacion",text:Strings.fecha},
          {pro:"venEstado",text:Strings.estado},
          {pro:"farmacia",text:Strings.farmacia},
          {pro:"venNumeroDoc",text:Strings.numeroFactura},
          {pro:"venDependiente",text:"Dependiente"},
          {pro:"venDescuentoTotal",text:"Desc. Reembosable"},
        ]} onSort={()=>{}} lessVh={155}>
          <TableBody>
            {selected.filter(s=> !(list ?? []).find(v=>v.id===s.id))?.map((ven:IVenta) =>
                <TableRow key={ven.id}
                          onClick={()=> {
                            let isSelected = selected.find(v=>v.id===ven.id);
                            if(isSelected){
                              onSelect(selected.filter(v=>v.id!==ven.id))
                            }else{
                              onSelect([ven, ...selected])
                            }
                          }}
                >
                  <TableCell className="actionCell" sx={{width:100}} >
                    {/*onClick={e=>e.stopPropagation()}*/}
                    <Checkbox checked={!!selected.find(v=>v.id===ven.id)} />
                  </TableCell>
                  <TableCell>{ven.id}</TableCell>
                  <TableCell>{formatDate(ven.fechaCreacion)}</TableCell>
                  <TableCell>
                    <span className= {(() => {
                      switch (ven.venEstado) {
                        case 2: return "text-red";
                        case 3:   return "text-blue";
                      }
                    })()}>
                      {getValueString(VENTA_ESTADO,ven.venEstado)}
                    </span>
                  </TableCell>
                  <TableCell>{ven.farmacia}</TableCell>
                  <TableCell>{ven.venNumeroDoc}</TableCell>
                  <TableCell>{ven.venDependiente}</TableCell>
                  {/*<TableCell>{formatPeso(ven.venPrecioTotal)}</TableCell>*/}
                  <TableCell>{formatPeso(ven.venDescuentoTotal)}</TableCell>
                </TableRow>
            )}

            {list?.map((ven:IVenta) =>
                <TableRow key={ven.id}
                          onClick={()=> {
                            let isSelected = selected.find(v=>v.id===ven.id);
                            if(isSelected){
                              onSelect(selected.filter(v=>v.id!==ven.id))
                            }else{
                              onSelect([ven, ...selected])
                            }
                          }}
                >
                  <TableCell className="actionCell" sx={{width:100}} >
                    {/*onClick={e=>e.stopPropagation()}*/}
                    <Checkbox checked={!!selected.find(v=>v.id===ven.id)} />
                  </TableCell>
                  <TableCell>{ven.id}</TableCell>
                  <TableCell>{formatDate(ven.fechaCreacion)}</TableCell>
                  <TableCell>
                      {getValueString(VENTA_ESTADO,ven.venEstado)}
                  </TableCell>
                  <TableCell>{ven.farmacia}</TableCell>
                  <TableCell>{ven.venNumeroDoc}</TableCell>
                  <TableCell>{ven.venDependiente}</TableCell>
                  {/*<TableCell>{formatPeso(ven.venPrecioTotal)}</TableCell>*/}
                  <TableCell>{formatPeso(ven.venDescuentoTotal)}</TableCell>
                </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow >
              <TableCell colSpan={7}/>
              <TableCell>{formatPeso(selected?.reduce((pre, cur) => pre + (cur.venDescuentoTotal ?? 0), 0))}</TableCell>
            </TableRow>
          </TableFooter>
        </TableSort>
      </Box>

    </section>

  </>;
}

export default VentasDetalle;

