import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {IconButton, Theme, Toolbar, Typography} from '@mui/material';
import {ArrowBack, Close} from '@mui/icons-material';
import {AppBar,Box} from '@mui/material';
const closeBtnStyle = {
      order: 1,
      background: "rgba(0, 0, 0, 0.05)",
      borderRadius: 0,
      marginLeft: "0px !important",
}
const useStyles = makeStyles((theme:Theme) => ({
  root:{
    "& .MuiIconButton-sizeSmall:not(:first-child)":{
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      height: "40px !important",
      "& >div":{
        height: "40px !important",
        minHeight: "auto",
        padding: "0 "+theme.spacing(1),
      }
    },
  },
  children:{
    position:"relative",
    [theme.breakpoints.up('sm')]: {
      "&:after":{
        content: "''",
        borderRight: "1px solid rgba(255, 255, 255, .3)",
        position: "absolute",
        top: 3,
        right: -2,
        height: "78%"
      }
    }
  },
  closeBtn:{
     ...closeBtnStyle,
  },
  closeBtn_upSm:{
    [theme.breakpoints.up('sm')]: {
      ...closeBtnStyle
    },
    "& svg":{
      paddingTop: "4px"
     }
  },
  title: {
    flex: 1,
    fontSize:"1.2rem",
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  }
}));
type Iprops = {
    nofullScreen?:boolean,
    title:string,
    onClose?: ()=>void;
    children?:any
    style?:any
}
export default function TopBarDialog({nofullScreen,title,onClose,children, style}:Iprops){
    const classes = useStyles();
    return (
      <AppBar position="static" elevation={0} className={"d-print-none "+classes.root} style={style} >
        <Toolbar style={nofullScreen ? {minHeight: 40,padding: "0 5px"}:{}}>
          <IconButton size={"small"} edge="start" color="inherit" onClick={onClose} className={nofullScreen ? classes.closeBtn :classes.closeBtn_upSm}>
            {nofullScreen ?(
                <Close/>
            ):(
              <>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}><Close/></Box>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}><ArrowBack/></Box>
              </>
            )}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {!!children && <div className={classes.children}>
            {children}
          </div>}
        </Toolbar>
      </AppBar>
    );
}
