import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getPlanes, addEditPlan, getPlanById, delPlan} from '../../services';
import {IPlanState, IPlan} from "../types/IPlanes";
import historyHelper from "../../helpers/history";
const initialState: IPlanState = {
    status: "idle"
}

export const mostrarPlanes = createAsyncThunk(
    'mostrarPlanes', async (params:object|undefined ) => {
        const response = await getPlanes(params)
        return {data: response.data as IPlan[], params: response.config.params}
    }
)
export const mostrarPlanById = createAsyncThunk(
    'mostrarPlanById', async (id:number ) => {

        const response = await getPlanById(id)
        return response.data as IPlan
    }
)

export const agregarEditarPlan = createAsyncThunk(
    'Planes/agregarEditar', async (Plan: IPlan) => {
        const response = await addEditPlan(Plan);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!Plan.id};
    }
);
export const eliminarPlan = createAsyncThunk(
    'plan/eliminar', async (id: number) => {
        const response = await delPlan(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarPlanes',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarPlanes
        builder.addCase(mostrarPlanes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPlanes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPlanes.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarPlan
        builder.addCase(agregarEditarPlan.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPlan.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list = state.list?.filter(e => e.id !== action.payload.data.id);
                state.list.unshift(action.payload.data);
                // if(!action.payload.edit){
                //     ++state.list.pageSize
                //     ++state.list.totalRecordCount
                // }

            }
        })
        builder.addCase(agregarEditarPlan.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarPlanById
        builder.addCase(mostrarPlanById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPlanById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarPlanById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarPlan
        builder.addCase(eliminarPlan.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarPlan.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list){
                state.list = state.list?.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarPlan.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
