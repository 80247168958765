import React, {useEffect, useState} from 'react';
import {agregarEditarVisita, eliminarVisita, mostrarVisitaById} from "../../redux/reducers/visitasReducers";
import {Box, Button, Dialog, DialogActions, DialogContent,} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, hasPermissionTo, nullToEmptyString, PERSMISOS} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";
import {IVisita} from "../../redux/types/IVisita";
import ArticuloDetalles from "./ArticuloDetalles";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {addEditVisita, getVisitaAbierta} from "../../services";
import ConfirmDialog from "../../components/ConfirmDialog";
import {mostrarMedicos} from "../../redux/reducers/medicosReducers";

const initialState:IVisita = {
    id: undefined,
    visComentario: "",
    medico: "",
    medicoId: undefined,
    entregas: []
};
export default function CrearVisita() {
    const Dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {pathname, state: urlState}:{state:any,pathname:string}  = useLocation();

    const {byId: visita, status} = useAppSelector(state => state.visitas)
    const [state, setState] = useState(initialState);


    useEffect(() => {
        if(!!urlState?.crearVisita) {
            setState(prev => ({...prev, ...nullToEmptyString(urlState.crearVisita)}));
            if(urlState?.crearVisita.id){
                Dispatch(mostrarVisitaById(+urlState?.crearVisita.id))
            }else if(urlState?.crearVisita.medicoId){
                getVisitaAbierta(urlState?.crearVisita.medicoId).then(({data}) => {
                    if(data){
                        setState(prev => ({...prev, ...nullToEmptyString(data)}));
                    }else{
                        addEditVisita(urlState?.crearVisita).then(({data})=>{
                            setState(prev => ({...nullToEmptyString(data), medico: prev?.medico!}));
                        })
                    }
                })

            }
        }else {
            setState(initialState);
        }
    }, [setState, urlState?.crearVisita, Dispatch]);

    useEffect(()=>{
        if(!!urlState?.crearVisita?.id && visita?.id === parseInt(urlState?.crearVisita.id)) {
            setState({...initialState, ...nullToEmptyString(visita)});
        }else{
            setState(initialState)
        }
    },[urlState?.crearVisita?.id,visita])

    const handleSubmit = () => {
        // let params = {id:state.id,name:state.marNombre,accion:1}
        // if(params.id) params.accion = 2;
        Dispatch(agregarEditarVisita(state)).then(res=>{
            if(res.payload){
                if(formatDate(state.fechaCreacion) === formatDate(new Date())){
                    Dispatch(mostrarMedicos())
                }
            }
        });
    };

    return (
        <>
            <Dialog open={Boolean(urlState?.hasOwnProperty("crearVisita"))} onClose={()=>navigate(-1)} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={Strings.visita} onClose={()=>navigate(-1)} nofullScreen/>


                <DialogContent>

                    {(status === "loading") && <LoadingProgress />}
                    <Box bgcolor={"#dfdfdf"} px={1} py={1}>
                        <table className={"noHover"}>
                            <tbody>
                            <tr>
                                <td width={"128px"}><Box fontWeight={500}>Médico</Box></td>
                                <td>: {state.medico}</td>
                            </tr>
                            <tr>
                                <td><Box fontWeight={500}>Fecha Entrada</Box></td>
                                <td>: {formatDate(state.fechaCreacion,"DD/MM/YYYY h:m A")}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td><Box fontWeight={500}>Fecha Salida</Box></td>*/}
                            {/*    <td>: {formatDate(state.fechaSalida,"DD/MM/YYYY h:m A")}</td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td><Box fontWeight={500}>Tiempo</Box></td>*/}
                            {/*    <td>:*/}
                            {/*        {!!state.fechaSalida && <>*/}
                            {/*            {moment(state.fechaSalida).diff(moment(state.fechaCreacion), 'minutes')} Minutos*/}
                            {/*        </>}*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td><Box fontWeight={500}>Comentario</Box></td>*/}
                            {/*    <td>: {state.visComentario}</td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                    </Box>

                    <br/>
                    <ValidatorFormCustom onSubmit={handleSubmit} id="formVisita">
                        <TextValidatorCustom
                            name={"visComentario"}
                            label={Strings.comentario}
                            value={state.visComentario}
                            onChange={({target}:any)=>setState(prevState => ({...prevState, visComentario: target.value}))}
                            inputProps={{maxLength: 200}}
                            validators={!state.entregas?.length ? ['required']:[]}
                            multiline
                            rows={2}
                            fullWidth
                        />
                    </ValidatorFormCustom>
                    <br/>
                    <ArticuloDetalles visitaState={state} onChange={(articulos)=>setState({...state, entregas:articulos})}/>


                </DialogContent>
                <DialogActions>
                    {hasPermissionTo(PERSMISOS.visitasEliminar) &&
                        <Button onClick={()=>{
                            navigate(pathname, {state: {...urlState, delVisita:state.id}})
                        }} sx={{color:"#f84f4f"}}>{Strings.eliminar}</Button>
                    }
                    {/*<Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>*/}
                    <Button type="submit" form={"formVisita"} color="primary" disabled={status === "loading"}>
                        {Strings.guardar}
                    </Button>
                </DialogActions>
            </Dialog>
            {!!urlState?.delVisita &&
                <ConfirmDialog onConfirm={() => Dispatch(eliminarVisita(urlState.delVisita))}/>
            }
        </>
    );

}
