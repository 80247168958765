import React, {useCallback, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme
} from "@mui/material";
import {LoadingProgress, TopBarDialog} from "../../../components";
import ListPrePushMoney from "./ListPrePushMoney";
import TopAppBar from "../../../components/TopAppBar";

import Strings from '../../../assets/strings'
import {getArticulos} from "../../../services";
import SelectSearchFarmacia from "../../../components/selects/SelectSearchFarmacia";
import {IPushMoney, IPushMoneyDetalle} from "../../../redux/types/IPushMoney";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {agregarEditarPushMoney, mostrarPushMoneyById} from "../../../redux/reducers/pushMoneyReducers";
import {useParams} from "react-router-dom";
import {noTilde, nullToEmptyString, TIPO_USUARIO} from "../../../helpers";
import {TextValidatorCustom, ValidatorFormCustom} from "../../../helpers/form-validator";
import {getTokenInfo} from "../../../services/auth";
import {IArticulo} from "../../../redux/types/IArticulo";
import {SelectFiles} from "../../../components/selects";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
       // paddingTop: theme.mixins.toolbar.minHeight,
        "& .topSearch":{
            background: theme.palette.grey[200],
          //  padding:`25px 0px 0px 8px`
            padding: "25px 10px 0px 10px",
            position: "sticky",
            top: "-206px",
            [theme.breakpoints.down('sm')]: {
                top: "-266px",
            }
        },
        "& .left":{
            height: "calc(100vh - 50px)",
            overflow: "auto",
        },
        "& .contAdjuntos":{
            "& .img, & .pdf":{
                width: 50,
                height: 50,
                "& .delete":{
                    width: 20,
                    height: 20,
                }
            },

        }
    },
    rightCont:{
        height: "25%",
        [theme.breakpoints.down('md')]: {
            position: "fixed",
            width: "100%",
            height: "131px",
            //height: "100%",
            bottom: 0,
            zIndex: 3000,
            transition: "height .4s",
            "&.opened":{
                height: "100%",
            }
        }
    },
    search:{
        marginTop:theme.spacing(1),
        "& input":{
            padding: theme.spacing(1),
            width: "100%",
            boxSizing: "border-box",
            marginBottom: 10,
            border: "1px solid #d4d4d4",
        }
    },
    itemsCont:{
    //    height: "calc(100vh - 263px)",
        background: theme.palette.common.white,
    },
    prePushMoney:{
        position:"relative",
        borderLeft: "1px solid " +theme.palette.grey[200],
        paddingTop: 14,
        height: "100%",
        background: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            paddingTop:0,
        }
    },
    tabs:{
        display:"flex",
        height: Number(theme.mixins.toolbar.minHeight) -13,
        background: theme.palette.grey[300],
        justifyContent:"space-between",
        "& ul":{
            display:"flex",
            padding:0,
            "& li":{
                borderTop:"2px solid " +theme.palette.grey[400],
                borderRight: "1px solid " +theme.palette.grey[400],
                "&:first-child":{
                    borderTop:"2px solid " +theme.palette.primary.main,
                    background: theme.palette.common.white,
                }
            }
        }

    },
    item:{
        cursor: "pointer",
    }
}));
const initialState:IPushMoney = {
    id: undefined,
    farmaciaId: undefined,
    farmacia: "",
    psmNumeroDoc: "",
    psmDependiente: "",
    psmNota: "",
    detalle:[],
    psmPathAdjunto:"",
}

export default function CrearPushMoney() {
    const classes = useStyles();
    let { id:urlComId} = useParams()
    const user = useAppSelector(state=>state.auth.user)

    const Dispatch = useAppDispatch()
    const [state, setState] =  React.useState(initialState);
    const [articulos, setArticulos] =  React.useState<IArticulo[]>();
    const [viewCart, setViewCart] =  React.useState(false);


    const [filterArticulos, setFilterArticulos] =  React.useState<IArticulo[]>();

    const [editingItem, setEditingItem] = React.useState<IPushMoneyDetalle|null>(null);
    const {byId:pushMoney,status} = useAppSelector(state => state.pushMoney);

    const [filter, setFilter] = useState({name:'', categoriaId:''});


    useEffect(()=>{

        let filtered = articulos?.filter((p)=>
            noTilde(p.artNombre).indexOf(noTilde(filter.name??"")) !== -1 ||
            noTilde(p.artCodigo).indexOf(noTilde(filter.name??"")) !== -1 ||
            noTilde(p.artCodigoBarra).indexOf(noTilde(filter.name??"")) !== -1
        )

        setFilterArticulos(filtered)
    },[filter,articulos,setFilterArticulos])

    useEffect(()=>{
        if(urlComId) Dispatch(mostrarPushMoneyById(Number(urlComId)));
    },[urlComId,Dispatch])
    useEffect(()=>{
        if(!!urlComId && pushMoney?.id === parseInt(urlComId)) {
            setState({...initialState, ...nullToEmptyString(pushMoney)});
        }else{
            setState(initialState)
        }
    },[urlComId, pushMoney])


    let fetchProducts = useCallback((params:any)=>{
        getArticulos(params).then(res=>{
            setArticulos(res.data.results)
        })
    },[setArticulos])


    useEffect(()=>{
        fetchProducts({indicadorPushmoney:1})
    },[fetchProducts])

    useEffect(()=>{
        if(user?.usuTipo === TIPO_USUARIO.farmacia){
            setState(prevState => ({...prevState,farmaciaId:(getTokenInfo() as any)["RelatedId"],farmacia:user.usuNombre}))
        }else if(user?.usuTipo === TIPO_USUARIO.paciente){
            setState(prevState => ({...prevState,pacienteId:(getTokenInfo() as any)["RelatedId"], paciente:user.usuNombre}))
        }
    },[user, setState])

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value, type, checked} = e.target
        return setState({...state,[name]: type === "checkbox"?checked: value});
    }

    const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFilter({...filter,[name]:value})
    };
    const onAddItem = async ()=>{
        let list = [...(state.detalle ?? [])];
        if(editingItem){
           // let venPuntos = 0;
            // if(!editingItem.ofertaId){
            //     // await validarPuntos({
            //     //     pacienteId: state.pacienteId,
            //     //     articuloId: editingItem.articuloId,
            //     //     cantidad: editingItem.psmCantidad,
            //     //     cantidadComprimidos: editingItem.psmCantidadDetalle??0
            //     // }).then(({data})=>venPuntos=data)
            // }

            if(list?.length){
                let found = list?.find(s=>(s.articuloId === editingItem.articuloId));
                if(found) {
                    list = list.map(l => {
                        if (l.articuloId === editingItem.articuloId)
                            return {...editingItem, accion: editingItem.accion === 3 ? 2 : editingItem.accion};

                        return l
                    })
                    setState(prev=>({...prev, detalle: list}))

                }else{
                    setState(prev=>({...prev, detalle: [{...editingItem},...list]}))
                }

            }else{
                setState(prev=>({...prev, detalle: [{...editingItem}]}))
            }
        }
        setEditingItem(null)

    }
    const deleteItem = () => {
        let list = [...(state.detalle ?? [])];
        let index =  list.findIndex(l=>l.articuloId===editingItem?.articuloId)


        if(index === -1) return false;

        if(list[index].accion === 1){
            list.splice(index,1)
        }else if(list[index].accion === 3){
            list.splice(index,1,{...list[index],accion:2})

        }else{
            list.splice(index,1,{...list[index],accion:3})
        }
        setEditingItem(null)
        setState(prev=>({...prev, detalle: list}))
    }

    const handleEditingItem = ({id:articuloId, artNombre: articulo,artIndicadorDesc,artPrecio}:IArticulo)=>{
        let list = [...(state.detalle ?? [])];
        let found = list?.find(item=>item.articuloId === articuloId);

        if(found){
            setEditingItem({...found,accion:(found.accion === 0 || found.accion === 3)?2:found.accion})
        }else{
            let item:IPushMoneyDetalle = {
                accion: 1,
                articuloId,
                articulo,
                psmCantidad: 1,
                psmPrecio:artPrecio,
            }
            if(urlComId) item.pushMoneyId = parseInt(urlComId);

            setEditingItem(item)
        }
    }

    const submit = () =>{
       // if(!state.adjuntos?.length) return Dispatch(Notifications.error({ title: 'Debes adjuntar foto de factura despachada.', autoDismiss: 4}));

        Dispatch(agregarEditarPushMoney(state))
    }

    return (
        <>
            <TopAppBar />

            <div className={classes.root}>
                {(status === "loading") && <LoadingProgress vh/>}
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8}>
                        <div className={"left"}>
                            <div className="topSearch">
                                <ValidatorFormCustom onSubmit={submit} id={"formPushMoney"}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>
                                        <SelectSearchFarmacia required //disabled={user?.usuTipo === 5}
                                            disabled={user?.usuTipo === TIPO_USUARIO.farmacia}
                                            value={{
                                                farmaciaId: (state.farmaciaId ?? "").toString(),
                                                farmacia: state.farmacia ?? ""
                                            }}
                                            onChange={(v)=> setState(prev=>({...prev,
                                                farmacia: v.farmacia,
                                                farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextValidatorCustom
                                            name={"psmNumeroDoc"}
                                            label={Strings.numeroFactura}
                                            value={state.psmNumeroDoc}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 20}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField fullWidth label={"Dependiente"} name={"psmDependiente"} value={state.psmDependiente}
                                                   onChange={handleChange}
                                        />
                                    </Grid>
                                        {/*<Typography sx={{lineHeight: 1.2}}>Adjunto (imagen o pdf) *</Typography>*/}
                                        {/*<SelectFiles showImg={false} src={state.comAdjunto??""} label={"Adjunto (imagen o pdf)"} required={true}*/}
                                        {/*             accept="image/*,.pdf"*/}
                                        {/*             onChange={(comAdjunto:string)=>setState(prevState => ({...prevState,comAdjunto}))}/>*/}
                                        {/*<div style={{height:0,width:0, overflow:"hidden"}}>*/}
                                        {/*    <input value={state.comAdjunto??""} required onChange={()=>{}}/>*/}
                                        {/*</div>*/}


                                    <Grid item xs={12} sm={4}>
                                        {/*<Typography sx={{lineHeight: 1.2}}>Adjunto (imagen o pdf) *</Typography>*/}
                                        <SelectFiles showImg={false} src={state.psmPathAdjunto??""} label={"Adjunto (imagen o pdf)"} required={true}
                                                     accept="image/*,.pdf"
                                                     onChange={(adjunto:string)=>setState(prevState => ({...prevState,psmPathAdjunto:adjunto}))}/>
                                        {/*<div style={{height:0,width:0, overflow:"hidden"}}>*/}
                                        {/*    <input value={state.comAdjunto??""} required onChange={()=>{}}/>*/}
                                        {/*</div>*/}
                                    </Grid>


                                    <Grid item xs={12} sm={user?.usuTipo === TIPO_USUARIO.paciente?12: 8}>
                                        <TextField
                                                   name={"psmNota"}
                                                   label={Strings.comentario}
                                                   value={state.psmNota}
                                                   onChange={handleChange}
                                                   inputProps={{maxLength: 100}}
                                                   fullWidth
                                        />
                                    </Grid>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Box bgcolor={"common.white"} padding={"4px"}>*/}
                                    {/*        <AddAdjuntos*/}
                                    {/*            label={"Debes adjuntar foto de factura despachada."}*/}
                                    {/*            adjuntos={state.adjuntos?.map(adj=>({*/}
                                    {/*                arcPath:adj.url,*/}
                                    {/*                arcBase64:adj.base64,*/}
                                    {/*                arcExtension: (adj.url || adj.base64)?.indexOf("pdf") !== -1?"pdf":"jpg"*/}
                                    {/*            })) ?? []}*/}
                                    {/*            onChange={(val)=>{*/}
                                    {/*                setState(prevState => ({...prevState, adjuntos:val.map(v=>({*/}
                                    {/*                        url: v.arcPath,*/}
                                    {/*                        base64: v.arcBase64,*/}
                                    {/*                    }))}))}}*/}
                                    {/*        />*/}
                                    {/*    </Box>*/}

                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <TopSearch onFilter={handleFilter} filter={filter}/>*/}
                                    {/*</Grid>*/}
                                </Grid>
                                </ValidatorFormCustom>

                                {!!articulos?.length &&
                                    <Box className={classes.search}>
                                        <input type="rearch" placeholder={Strings.buscar+" "+Strings.articulos} name="name" value={filter.name} onChange={handleFilter}/>
                                    </Box>
                                }
                            </div>
                            <div className={classes.itemsCont}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{Strings.codigo}</TableCell>
                                                <TableCell>{Strings.nombre}</TableCell>
                                                <TableCell>Código de barra</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filterArticulos?.map((art:IArticulo, index) =>
                                                <TableRow key={index} onClick={()=>handleEditingItem(art)}>
                                                    <TableCell>{art.artCodigo}</TableCell>
                                                    <TableCell >
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <div>{art.artNombre}</div>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>{art.artCodigoBarra}</TableCell>

                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.rightCont + " "+(viewCart?"opened":"")} onClick={()=>setViewCart(!viewCart)}>
                        {/*sx={{ display: { xs: 'none', md: 'block' } }}*/}
                        <div className={classes.prePushMoney}>
                            <ListPrePushMoney
                                list={state.detalle??[]}
                                onClick={setEditingItem}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Dialog open={!!editingItem} onClose={()=>setEditingItem(null)} fullWidth maxWidth={"xs"}>
                <TopBarDialog onClose={() => setEditingItem(null)} nofullScreen
                              title={editingItem?.articulo?.replace(/(.{25})..+/, "$1…") ?? ""}/>
                <DialogContent>
                    <ValidatorFormCustom onSubmit={onAddItem} id={"formArticulo"}>
                        <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        type={"number"}
                                        label={"Cantidad Despachada"}
                                        value={editingItem?.psmCantidad??""}
                                        onChange={({target})=>setEditingItem(prev=> {
                                            let psmCantidad = target.value ? parseInt(target.value) : undefined;
                                            return {...prev!, psmCantidad}
                                        })}
                                        required
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>

                        </Grid>

                    </ValidatorFormCustom>

                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"}
                            disabled={state.detalle?.findIndex(l=>l.articuloId===editingItem?.articuloId) === -1}
                            onClick={deleteItem}
                    >
                        {editingItem?.accion===3?Strings.agregar:Strings.eliminar}
                    </Button>
                    <Button type={"submit"} form={"formArticulo"} disabled={!editingItem?.psmCantidad}>{Strings.guardar}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


