import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarReceta, mostrarRecetas} from "../../redux/reducers/recetasReducers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonResponsive,
  FilteredAlert,
  HtmlTooltip,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';
import {Close, FilterList} from '@mui/icons-material';
import {DateInput, SelectSmart} from "../../components/CustomTextField";

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS, getUsuTipo, TIPO_USUARIO } from "../../helpers";

import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IReceta} from "../../redux/types/IReceta";
import ConfirmDialog from "../../components/ConfirmDialog";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchPaciente from '../../components/selects/SelectSearchPaciente';
import DetalleReceta from "./DetalleReceta";
import TopAppBar from "../../components/TopAppBar";
import SelectSearchMedico from "../../components/selects/SelectSearchMedico";
import {getValueString, objToKeyValue, RECETA_ESTADO} from "../../helpers/CONSTANTES";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  medicoId: '',
  medico:'',
  pacienteId:'',
  paciente:'',
  estado: "",
  fechaDesde:'',
  fechaHasta:'',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};

function Recetas(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.recetas)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarRecetas());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarRecetas({
        ...filter,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarRecetas({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarRecetas(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarRecetas({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };


  return <>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.estado}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.estado ?? ""}
                  onChange={handleFilter}
                  name="estado"
                  opciones={[
                    {key: '', value: Strings.todo},
                    ...objToKeyValue(RECETA_ESTADO)
                  ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaDesde"}
                    label={Strings.fechaDesde}
                    value={filter.fechaDesde}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaHasta"}
                    label={Strings.fechaHasta}
                    value={filter.fechaHasta}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchMedico
                  value={{
                    medico:filter.medico,
                    medicoId: (filter.medicoId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    medicoId:v.medicoId?v.medicoId:"",
                    medico: v.medico
                  })}
              />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchPaciente
                    label={Strings.paciente}
                    value={{
                        id: filter.pacienteId ? parseInt(filter.pacienteId):undefined,
                        pacNombres: filter.paciente ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        paciente:v.pacNombres??"",
                        pacienteId:v.id?.toString() ?? ""
                    }))}
                />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <TopAppBar/>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}

      <div>

        <ListViewMode  noView onNew={hasPermissionTo(PERSMISOS.recetasCrear) ? ()=>window.open("/recetaForm","_blank") : undefined}>
          <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
          <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
          <FilteredAlert onClose={resetFilter} filter={filtered}/>
        </ListViewMode>
        <TableSort data={[
          {pro:"",text:""},
          {pro:"id",text:"ID"},
          {pro:"recCodigo",text:Strings.codigo},
          {pro:"recEstado",text:Strings.estado},
          {pro:"fechaCreacion",text:Strings.fechaCreacion},
          {pro:"recFechaVencimiento",text:Strings.fechaVencimiento},
          ...((getUsuTipo() !== TIPO_USUARIO.paciente) ?
              [
                {pro:"paciente",text:Strings.paciente}
              ] : []),
          {pro:"centroMedico",text:Strings.centroMedico},
          {pro:"centroMedicoCiudad",text:"Ciudad"},
          {pro:"medico",text:Strings.medico},
          {pro:"especialidad",text:Strings.especialidad},
        ]} onSort={tableSortProps} lessVh={155}>
          <TableBody>
            {!isEmpty && list?.results.map((can:IReceta) =>
                <TableRow key={can.id} onClick={()=> {
                  navigate(location.pathname, {state: {receta: can}})
                }}>
                  <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                    {can.recEstado && hasPermissionTo(PERSMISOS.recetasEliminar) &&
                        <HtmlTooltip title={Strings.anular}>
                          <IconButton size={"small"}
                                      onClick={(e)=> {
                                        navigate(location.pathname, {state: {confirmDialog: can.id}})
                                      }}>
                            <Close fontSize={"small"} className="text-red"/>
                          </IconButton>
                        </HtmlTooltip>
                    }
                  </TableCell>
                  <TableCell>{can.id}</TableCell>
                  <TableCell>{can.recCodigo}</TableCell>
                  <TableCell>
                      {getValueString(RECETA_ESTADO,can.recEstado)}
                  </TableCell>
                  <TableCell>{formatDate(can.fechaCreacion)}</TableCell>
                  <TableCell>{formatDate(can.recFechaVencimiento)}</TableCell>

                  {(getUsuTipo() !== TIPO_USUARIO.paciente) && <TableCell>{can.paciente}</TableCell>}
                  <TableCell>{can.centroMedico}</TableCell>
                  <TableCell>{can.centroMedicoCiudad}</TableCell>
                  <TableCell>{can.medico}</TableCell>
                  <TableCell>{can.especialidad}</TableCell>

                </TableRow>
            )}
          </TableBody>
        </TableSort>

        <Pagination
            empty={Boolean(isEmpty && status !== "loading")}
            count={list?.totalRecordCount || 0}
            rowsPerPage={Number(filter.pageSize)}
            page={(list?.pageNo ?? 0) -1}
            onPageChange={(_,page:number)=>Dispatch(mostrarRecetas({...filter,page:(page + 1).toString()}))}
            onRowsPerPageChange={handleFilter}
        />
      </div>

    </section>



    <DetalleReceta/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={() => Dispatch(eliminarReceta(urlState.confirmDialog))}/>
    }
  </>;
}

export default Recetas;

