import React, {useState, useEffect} from 'react';
import { Alert } from '@mui/material';
import Collapse from "@mui/material/Collapse";
import {IErrorRes} from "../redux/types/Icommon";

// import PropTypes from 'prop-types';

export function MostrarError({ errors, action,onClose, fixed=true}:{ errors?:IErrorRes, action?:any,onClose?:Function, fixed?:boolean}) {
    const [mensaje, setMensaje] = useState<string>();
    // const [errorsList, setErrorsList] = useState<string[]>();
    useEffect(() => {
        if (typeof errors === "string"){
            setMensaje(errors)
        }else{
            setMensaje(errors?.title??"");
           // setErrorsList(errors?.list??[]);
        }
    }, [setMensaje, errors]);

    const handleClose = () => {
        if(onClose) {
          return  onClose();
        }
        setMensaje("");
     //   setErrorsList([]);
    };

        return (
            <Collapse in={Boolean(mensaje?.length)}>
                <Alert severity="warning" action={action} onClose={handleClose}  className={fixed?"fixed":""}>
                    {mensaje}
                    {/*{Object.keys(errorsList).length > 0 &&*/}
                    {/*    <ul>*/}
                    {/*        {Object.keys(errorsList).map(key=>*/}
                    {/*            <li key={key}>{key} : {(typeof (errorsList as any)[key] === "object") ? (errorsList as any)[key].join(" / ") : (errorsList as any)[key]}</li>*/}
                    {/*        )}*/}
                    {/*    </ul>*/}
                    {/*}*/}
                </Alert>
            </Collapse>
        );
}

// MostrarError.propTypes = {
//     errors: PropTypes.ojb,
// }
