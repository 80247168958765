import React, {useEffect, useState} from 'react';
import {Button, Grid, TextField, Theme,} from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


import Strings from "../../assets/strings";

// import {colors} from "../../assets/colors";
// import {TextFieldPhone} from "../../components/CustomTextField";
import {editarPerfil} from "../../redux/reducers/authReducer";
import {nullToEmptyString} from "../../helpers";
import {MostrarError} from "../../components/MostrarError";
import {useLocation, useNavigate} from "react-router-dom";

import ChangePassword from "./ChangePassword";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import TopAppBar from "../../components/TopAppBar";

const useStyles = makeStyles((theme:Theme) => ({

    root:{
        padding:theme.spacing(2),
    },
    divColor:{
        width:"100%",
        padding: "1px 7px",
        color: "white",
    }
}));

export default function MiPerfil(){
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => ({
        perfil: state.auth.user,
        status: state.auth.status,
        errors: state.auth.error,
    }));

    const classes = useStyles();
    const [state, setState] = useState({
        usuId:"",
        usuInicioSesion: "",
        usuClave: "",
        usuNombre: "",
        usuEmail: "",
        //usuUIcolor: "",
    })
    const {perfil} = data
    useEffect(()=>{
            setState(prevState =>  ({...prevState,...nullToEmptyString(perfil)}))
    },[setState,perfil])
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});
    }
    // const handleSelectChange = (e: SelectChangeEvent)=>{
    //     const {name, value} = e.target
    //     setState({...state, [name]: value});
    // }

    const {errors,status} = data;

    return(
        <>

            <TopAppBar/>
            <div className={"contentInner "+classes.root}>
                <MostrarError errors={errors}/>
                <form id="form1" onSubmit={(e)=>{e.preventDefault();dispatch(editarPerfil(state))}}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuNombre"
                                label={Strings.primerNombre}
                                value={state.usuNombre}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuEmail"
                                label={Strings.email}
                                type="email"
                                value={state.usuEmail}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        {/*<Grid item xs={12} sm={3}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <InputLabel id="usuUIcolor">UI Color</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            labelId="usuUIcolor"*/}
                        {/*            name="usuUIcolor"*/}
                        {/*            value={state.usuUIcolor}*/}
                        {/*            onChange={handleSelectChange}*/}
                        {/*        >*/}
                        {/*            {colors.map(c=>*/}

                        {/*                <MenuItem key={c.primary.main} value={c.primary.main}>*/}
                        {/*                    <div className={classes.divColor} style={{background: c.primary.main}}>{c.primary.main}</div>*/}
                        {/*                </MenuItem>*/}
                        {/*            )}*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={3}>*/}
                        {/*    <SelectCaja label={Strings.caja+" "+Strings.porDefecto} name="cajId" value={state.cajId} onChange={handleChange}/>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuInicioSesion"
                                label={Strings.nombreDeUsuario}
                                value={state.usuInicioSesion}
                                onChange={handleChange}
                                disabled
                                inputProps={{readOnly: true,}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label={Strings.cambiar+" "+Strings.contrasena}
                                type="password"
                                defaultValue={"45654"}
                                inputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                onClick={()=> navigate(location.pathname,{state:{modalChangePassword:state.usuInicioSesion}})}
                            />
                        </Grid>
                    </Grid>
                </form>
                <div>
                    <Button color="inherit" disabled={status==="loading"} type="submit" form="form1">{Strings.guardar}</Button>
                </div>
            </div>
            <ChangePassword/>
        </>
    );
}
