import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Avatar,
  Box,
  Chip, Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import Strings from "../assets/strings";

import {ArrowBack, ExitToApp, Menu as MenuIcon, Person, PersonOutline} from '@mui/icons-material';
import {toggleDrawer} from "../redux/reducers/themeReducer";
import {useAppDispatch, useAppSelector} from '../redux/hooks';

import AppBar from '@mui/material/AppBar';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Routes from "../routes";
import {userLogout} from "../redux/reducers/authReducer";
import {translateString} from "../helpers";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: "100%",
    boxShadow: theme.shadows[1],
  },
  toolbar:{
    padding: "0px "+theme.spacing(2),
  },
  under:{
    [theme.breakpoints.up('md')]: {
      //top:50,
      padding: "7px 4px",
      width: "inherit",
      backgroundColor: "#f2f2f2",
      //borderBottom: "1px solid rgba(0,0,0,0.1)",
      color: "inherit",
      "& + .contentInner":{
        marginTop: 15
      },
    },
    [theme.breakpoints.down('md')]: {
      "& *":{
        outline: "none",
        border:"none",
        color: theme.palette.common.white,
      },
      "& option":{
        color: theme.palette.common.black,

      }
    },

  },
  toggleBtn:{
    marginRight:theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      cursor:"pointer"
      //display: "none",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    fontSize:"1.25rem",
    fontWeight: 400,
    textTransform:"capitalize",
    "& button":{
      marginRight: theme.spacing(1),
    }
  }
}));

export default function TopAppBar({backUrl=""}){
  const classes = useStyles();
  const user = useAppSelector(state => state.auth.user)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {pathname,...location} = useLocation();
  const [title, setTitle] = useState("");
  const urlParams = useParams();
  const Dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null); //<Element|null>

  const handleClose = () => {
    setAnchorEl(null);
  };



  useEffect(()=>{
    let routePath = pathname;

    for (const [key, value] of Object.entries(urlParams)) {
      if(key !== "*")
        routePath = routePath.replace(value,":"+key);
    }
    let tt = ""

    let routesArray = Routes();
    routesArray.forEach(e=>{
      if(!!e.path && e.path.toUpperCase() === routePath.toUpperCase()){
        tt = e.navbarName;
      }else if(e.nestedRoutes?.length){
        let found = e.nestedRoutes.find(n=>n.path.toUpperCase() === routePath.toUpperCase());
        if(found){
          tt = found.navbarName;
        } else{
          e.nestedRoutes?.forEach(nestedRoute=>{
            let found = nestedRoute.nestedRoutes?.find(n=>n.path.toUpperCase() === routePath.toUpperCase());
            if(found) tt = found.navbarName;
          })
        }
      }
    })

    setTitle( translateString(tt)??"")

  },[pathname,urlParams, setTitle])

  const getBackUrl = () => {

    if(backUrl) return {path:backUrl};

    let topRoute = null;
    let routePath = pathname;
    for (const [key, value] of Object.entries(urlParams)) {
      if(key !== "*")
        routePath = routePath.replace(value,":"+key);
    }

    let routesArray = Routes();
    routesArray.forEach(e=>{
      if(e.nestedRoutes?.length){
        let found = e.nestedRoutes.find(n=>n.path.toUpperCase() === routePath.toUpperCase());
        if(found && e.path){
          topRoute = e
        }else{
          e.nestedRoutes.forEach(nestedRoute=>{
            if(nestedRoute.nestedRoutes?.length){
              let found = nestedRoute.nestedRoutes.find(n=>n.path.toUpperCase() === routePath.toUpperCase());
              if(found && nestedRoute.path) topRoute = nestedRoute;
            }
          })
        }
      }
    })


    return topRoute;

  };
  const topRoute = getBackUrl();

      return (
        <AppBar className={classes.root + " d-print-none " + (title?classes.under: "")} >
          <Toolbar className={classes.toolbar}>
            <IconButton size="small"
                        color={topRoute?"primary":"default"}
                        className={classes.toggleBtn}
                        onClick={()=>{
                          if(topRoute){
                            navigate(topRoute.path, { replace: true })
                          }else{
                            dispatch(toggleDrawer())
                          }
                        }}
            >

              {topRoute ? <ArrowBack /> : <MenuIcon color={"primary"} />}
            </IconButton>

            <Typography variant="h1" color={title?"primary":"inherit"} noWrap className={classes.title}>
              {(title)?(
                  <>
                    {/*{topRoute &&*/}
                    {/*  <IconButton size="small" color={"secondary"} onClick={()=>navigate(topRoute.path, { replace: true })}> <ArrowBack/></IconButton>*/}
                    {/*}*/}
                    {title}
                  </>
              ):(
                 <>SeguraPOS</>
              )}

            </Typography>
            <div>
              {/*<IconButton*/}
              {/*    color="inherit"*/}
              {/*    sx={{mr:1}}*/}
              {/*    component={Link}*/}
              {/*    to={"/autorizaciones"}*/}
              {/*>*/}
              {/*  <Badge badgeContent={17} color="error">*/}
              {/*    <NotificationsIcon />*/}
              {/*  </Badge>*/}
              {/*</IconButton>*/}

              <Chip
                  avatar={<Avatar alt={user?.usuNombre} >
                    <Person fontSize={"small"} />
                  </Avatar>}
                  label={<Box sx={{lineHeight: "7px", marginTop: "6px"}}>
                    {user?.usuNombre} <br/>
                    <Typography variant={"caption"}>{user?.rol}</Typography>
                  </Box>}
                  variant="outlined"
                  onClick={(e)=>setAnchorEl(e.currentTarget)}

              />
              <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}

                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onClick={handleClose}
              >
                <MenuItem component={Link} to="/perfil">
                  <ListItemIcon>
                    <PersonOutline fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{Strings.perfil}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem className='text-red-deep' onClick={()=>Dispatch(userLogout())}>
                  <ListItemIcon>
                    <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{Strings.cerrarSesion}</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      );
}
