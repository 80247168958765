import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    Box, Button,
    Typography, List, ListItem, ListItemText, Theme, Grid, DialogActions
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, formatPeso, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {IVenta} from "../../redux/types/IVenta";
import Strings from "../../assets/strings";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import {agregarEditarVenta, confirmarVenta, mostrarVentaById} from "../../redux/reducers/ventasReducers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import pdfIcon from "../../assets/img/pdf.svg";
import {getValueString, VENTA_ESTADO} from "../../helpers/CONSTANTES";
import AddAdjuntos from "../../components/AddAdjuntos";
import Notifications from "react-notification-system-redux";
import ConfirmDialog from "../../components/ConfirmDialog";
const useStyles = makeStyles((theme:Theme) => ({
    adjuntosCont:{
        "& .contAdjuntos":{
            "& .img, & .pdf":{
                width: 50,
                height: 50,
                "& .delete":{
                    width: 20,
                    height: 20,
                }
            },

        }
    },
    groupImg:{
        "display": "flex",
        overflow: "hidden",
        "&>div":{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #e9ecef",
            position: "relative",
            width: "50px",
            height: "50px",
            padding: "5px",
            margin: "5px",
            "& img":{
                width:"100%",
            },
            "&.pdf img":{
                maxWidth: 66,
            }

        }
    },
    top:{
        display:"flex",
        justifyContent: "space-between",
        color:theme.palette.secondary.main,
        background:theme.palette.grey[200],
        alignItems: "center",
        marginTop:theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
    clienteCont:{
        borderBottom:"1px solid "+theme.palette.grey[300],
        display:"flex",
        justifyContent:"space-between",
        margin:theme.spacing(0.5),
        padding:theme.spacing(1),
        "& .inputCli":{
            width:"100%",
            marginRight:theme.spacing(1),
        },
        "& .btnNew":{
            padding: "0px 15px",
            fontSize: "0.8em",
            "& svg":{
                marginLeft:5
            }
        }
    },
    total:{
        position:"absolute",
        bottom:0,
        width:"100%",
        background:theme.palette.grey[100],
        padding: 0,
    }
}));



export default function DetalleVenta() {
    const {byId,status} = useAppSelector(state => state.ventas);

    const {state:urlState, pathname}:{state:any,pathname:string} = useLocation();
    const navigate =useNavigate();
    const classes = useStyles();
    const Dispatch = useAppDispatch()

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState<IVenta|null>(null);

    useEffect(() => {
        if(!!urlState && urlState.detalleVenta) {
            setState(state => ({...state, ...(nullToEmptyString(urlState.detalleVenta) as IVenta)}));
        }else {
            setState(null);
        }
    }, [setState, urlState]);


    useEffect(()=>{
        if(urlState?.detalleVenta && urlState.detalleVenta?.id !== byId?.id){
            Dispatch(mostrarVentaById(Number(urlState.detalleVenta.id)));
        }else if(byId){
            setState(state => ({...state, ...nullToEmptyString(byId) as IVenta}));
        }
    },[byId,setState,urlState,Dispatch])

    const actualizar = () =>{
        if(state){
            if(!state.adjuntos?.length) return Dispatch(Notifications.error({ title: 'Debes adjuntar foto de factura despachada.', autoDismiss: 4}));
            Dispatch(agregarEditarVenta(state))
        }
    }
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("detalleVenta"))} onClose={goBack} maxWidth={"md"} fullWidth={true}>
                <TopBarDialog title={Strings.venta} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0.5}}>
                    {status === "loading" && <LoadingProgress />}
                    {!!state &&
                        <>
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.farmacia}</Typography>
                                        <Typography variant={"caption"}>{Strings.farmacia}</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.venNumeroDoc}</Typography>
                                        <Typography variant={"caption"}>{Strings.numeroFactura}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatPeso(state.venPrecioTotal)}</Typography>
                                        <Typography variant={"caption"}>PushMoney</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatPeso(state.venDescuentoTotal)}</Typography>
                                        <Typography variant={"caption"}>Desc. Reembosable</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.venDependiente}</Typography>
                                        <Typography variant={"caption"}>Dependiente</Typography>
                                    </Grid>
                                    {!!state.recetaId && <Grid item xs={6} sx={{cursor: "pointer"}}
                                           className={"text-blue"}
                                           onClick={() => navigate(pathname, {state: {receta: {id: state.recetaId}}})}
                                    >
                                        <Typography variant="body2">{state.recetaId}</Typography>
                                        <Typography variant={"caption"}>ID {Strings.receta}</Typography>
                                    </Grid>}
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{ getValueString(VENTA_ESTADO,state.venEstado)}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>
                                    {state.venNota &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{state.venNota}</Typography>
                                            <Typography variant={"caption"}>{Strings.comentario}</Typography>
                                        </Grid>
                                    }
                                    {state.venEstado === 1 &&
                                        <Grid item xs={12}>
                                            <Button variant={"outlined"} onClick={()=>navigate(pathname, {state:{confirmarVenta:true, detalleVenta: urlState.detalleVenta}})} fullWidth>Comfirmar</Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            {state.detalle &&
                                <>
                                    <div className={classes.top}>
                                        <Typography noWrap>{state.detalle?.length} {Strings.articulos}</Typography>
                                    </div>
                                    <div>
                                        <List dense>
                                            {state.detalle?.map((p)=>
                                                <React.Fragment key={p.articuloId}>
                                                    <ListItem>
                                                        <ListItemText
                                                            secondary={p.venCantidad? Strings.cantidad+": "+p.venCantidad:""}
                                                        >
                                                            {p.articulo?.replace(/(.{35})..+/, "$1…")}
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </div>
                                </>
                            }
                            {state.adjuntos &&
                                <>
                                    {state.venEstado !== 1 ?(<>
                                        <div className={classes.top}>
                                            <Typography noWrap>{Strings.adjuntos}</Typography>
                                        </div>
                                        <div>
                                            <div className={classes.groupImg}>
                                                {state.adjuntos.map((adjunto, i) =>
                                                    <div key={i} className={adjunto.url?.indexOf("pdf") !== -1 ? 'pdf' : 'img'}>
                                                        <a href={adjunto.url} target="_blank" rel="noopener noreferrer">
                                                            <img src={adjunto.url?.indexOf("pdf") !== -1 ? pdfIcon : adjunto.url} alt=""  />
                                                        </a>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    </>):(
                                        <Box className={classes.adjuntosCont}>
                                            <AddAdjuntos
                                                //         label={"Debes adjuntar foto de factura despachada."}
                                                adjuntos={state.adjuntos?.map(adj=>({
                                                    arcPath:adj.url,
                                                    arcBase64:adj.base64,
                                                    arcExtension: (adj.url || adj.base64)?.indexOf("pdf") !== -1?"pdf":"jpg"
                                                })) ?? []}
                                                onChange={(val)=>{
                                                    setState(prevState => ({...prevState, adjuntos:val.map(v=>({
                                                            url: v.arcPath,
                                                            base64: v.arcBase64,
                                                        }))}))}}
                                            />
                                        </Box>
                                    )}

                                </>
                            }
                        </>
                    }
                    <br/>
                </DialogContent>
                {state?.venEstado === 1 &&
                    <DialogActions>
                        <Button onClick={actualizar}>{Strings.actualizar}</Button>
                    </DialogActions>
                }
            </Dialog>

            {!!urlState?.hasOwnProperty("confirmarVenta") && state?.id &&
                <ConfirmDialog onConfirm={()=>Dispatch(confirmarVenta(state.id!))}>
                    <Typography>Seguro que deseas confirmar esta Incentivo.</Typography>
                </ConfirmDialog>
            }
        </>
    );

}

