import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getFarmacias,generateUsuarioFarmacia, addEditFarmacia, getFarmaciaById, delFarmacia} from '../../services';
import {IFarmaciaState, IFarmacia, IFarmaciaRes} from "../types/IFarmacia";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
const initialState: IFarmaciaState = {
    status: "idle"
}

export const mostrarFarmacias = createAsyncThunk(
    'mostrarFarmacias', async (params:object|undefined ) => {
        const response = await getFarmacias(params)
        return {data: response.data as IFarmaciaRes, params: response.config.params}
    }
)
export const mostrarFarmaciaById = createAsyncThunk(
    'mostrarFarmaciaById', async (id:number ) => {

        const response = await getFarmaciaById(id)
        return response.data as IFarmacia
    }
)

export const agregarEditarFarmacia = createAsyncThunk(
    'Farmacias/agregarEditar', async (farmacia: IFarmacia) => {
        const response = await addEditFarmacia(farmacia);

        if (response.status === 200) {
            if(!farmacia.id){
                store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Este regitro esta pendiente a autorización por un administrador.", autoDismiss: 8,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!farmacia.id};
    }
);
export const generarUsuarioFarmacia = createAsyncThunk(
    'farmacia/generarUsuario', async (id: number) => {
        const response = await generateUsuarioFarmacia(id);
        if (response.status === 200 || response.status === 204) {
            historyHelper.push(historyHelper.location.pathname,response.data);
        }

        return {data: response.data};
    }
);
export const eliminarFarmacia = createAsyncThunk(
    'farmacia/eliminar', async (id: number) => {
        const response = await delFarmacia(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarFarmacias',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarFarmacias
        builder.addCase(mostrarFarmacias.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarFarmacias.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarFarmacias.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarFarmacia
        builder.addCase(agregarEditarFarmacia.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarFarmacia.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarFarmacia.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarFarmaciaById
        builder.addCase(mostrarFarmaciaById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarFarmaciaById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarFarmaciaById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarFarmacia
        builder.addCase(eliminarFarmacia.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarFarmacia.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarFarmacia.rejected, (state ) => {
            state.status = "idle";
        })
        //--generar Usuario
        builder.addCase(generarUsuarioFarmacia.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(generarUsuarioFarmacia.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = {...state.byId, usuarioId:action.payload.data.id}
        })
        builder.addCase(generarUsuarioFarmacia.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
