import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';
import {LoadingProgress} from '../../components';
import Grid from "@mui/material/Grid";
import Strings from "../../assets/strings";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ValidatorFormCustom} from '../../helpers/form-validator';
import {eliminarVenta} from "../../redux/reducers/ventasReducers";


export default function ConfirnarAnularVenta(){
  let navigate = useNavigate();

  const {state:urlState}:{state: any,pathname:string} = useLocation();

  const Dispatch = useAppDispatch();
  const [note, setNote] = useState("");

  const { status} = useAppSelector(state => state.ventas)

  const handleSubmit = () => {
      Dispatch(eliminarVenta({id:urlState?.confirnarAnularVenta, motivo: note}))
  }

  return <>
    <Dialog open={Boolean(urlState?.confirnarAnularVenta)} onClose={()=> {
      navigate(-1);
      setNote("")
    }} maxWidth={"sm"} fullWidth={true}>
      <TopBarDialog
          style={{backgroundColor: "#ff5722"}}
          title={"Anular venta"} nofullScreen onClose={()=>navigate(-1)}/>
      <DialogContent>
        {status === "loading" && <LoadingProgress />}
        <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  multiline
                  value={note}
                  onChange={({target})=>setNote(target.value)}
                  label={Strings.comentario}
                  fullWidth
              />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>navigate(-1)} color="primary">Cancelar</Button>
        <Button type="submit" form={"form1"} color="primary" disabled={status === "loading"}>
          {Strings.anular}
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

