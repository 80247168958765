import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getReposiciones, addEditReposicion, getReposicionById, delReposicion} from '../../services';
import {IReposicionState, IReposicion, IReposicionRes} from "../types/IReposicion";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
const initialState: IReposicionState = {
    status: "idle"
}

export const mostrarReposiciones = createAsyncThunk(
    'mostrarReposiciones', async (params:object|undefined ) => {
        const response = await getReposiciones(params)
        return {data: response.data as IReposicionRes, params: response.config.params}
    }
)
export const mostrarReposicionById = createAsyncThunk(
    'mostrarReposicionById', async (id:number ) => {

        const response = await getReposicionById(id)
        return response.data as IReposicion
    }
)

export const agregarEditarReposicion = createAsyncThunk(
    'Reposiciones/agregarEditar', async (reposicion: IReposicion) => {
        const response = await addEditReposicion(reposicion);

        if (response.status === 200) {
            if(!reposicion.id){
                store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Esta reposicion esta pendiente a autorización por un administrador.", autoDismiss: 8,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!reposicion.id};
    }
);
export const eliminarReposicion = createAsyncThunk(
    'reposicion/eliminar', async (id: number) => {
        await delReposicion(id);
        //  if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarReposiciones',
    initialState,
    reducers: {},
    extraReducers: builder => {
        //--mostrarReposiciones
        builder.addCase(mostrarReposiciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarReposiciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarReposiciones.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarReposicion
        builder.addCase(agregarEditarReposicion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarReposicion.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarReposicion.rejected, (state ) => {
            state.status = "idle";
        })


        //---mostrarReposicionById
        builder.addCase(mostrarReposicionById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarReposicionById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarReposicionById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarReposicion
        builder.addCase(eliminarReposicion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarReposicion.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results?.filter(r=>r.id!==id)
            }
        })
        builder.addCase(eliminarReposicion.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
