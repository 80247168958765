import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ApproveAutorizacion, getAutorizaciones, RejectAutorizacion, getAutorizacionesNumber} from '../../services';
import {IAutorizacion, IAutorizacionState} from "../types/IAutorizacion";
import historyHelper from "../../helpers/history";
import Notifications from "react-notification-system-redux";
import {setAutorizacionNumber} from "./themeReducer";
import { TIPO_AUTORIZACION } from '../../helpers/CONSTANTES';
import {ICanje} from "../types/ICanje";
import {updateCanjeRow} from "./canjesReducers";

const initialState: IAutorizacionState = {
    status: "idle"
}

export const mostrarAutorizacionesNumber = createAsyncThunk(
    'mostrarAutorizacionesNumber', async (undefined,thunkAPI) => {
        const response = await getAutorizacionesNumber()
        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(setAutorizacionNumber(response.data))
        }
        return {data: response.data}
    }
)
export const mostrarAutorizaciones = createAsyncThunk(
    'mostrarAutorizaciones', async (params:object|undefined ) => {
        const response = await getAutorizaciones(params)
        return {data: response.data as IAutorizacion[], params: response.config.params}
    }
)
export const aprobarAutorizacion = createAsyncThunk(
    'autorizacion/aprobar', async (params:any, thunkAPI) => {
        const response = await ApproveAutorizacion(params);
        if (response.status === 200 || response.status === 204) {
            let data = response.data as IAutorizacion;
            let res = {id:data.autTransacionId, autorizacionEstado: 2,autorizacionEstadoString:"Aprobada", autorizadoPorNombre:data.actualizadoPorNombre};

            switch (data.autTipo) {
                case TIPO_AUTORIZACION.canje:
                    thunkAPI.dispatch(updateCanjeRow(res as ICanje))
                    break
            }


            //callback message
            thunkAPI.dispatch(mostrarAutorizacionesNumber())

            thunkAPI.dispatch(Notifications.success({ title: 'Autorización aprobada correctamente.', autoDismiss: 4}));
            historyHelper.back();
        }
        return params;
    }
);
export const rechazarAutorizacion = createAsyncThunk(
    'autorizacion/rechazar', async (params:any, thunkAPI) => {
        const response = await RejectAutorizacion(params);
        if (response.status === 200 || response.status === 204) {
            let data = response.data as IAutorizacion;
            let res = {id:data.autTransacionId, autorizacionEstado: 3,autorizacionEstadoString:"Rechazada", autorizadoPorNombre:data.actualizadoPorNombre};
            switch (data.autTipo) {
                case TIPO_AUTORIZACION.canje:
                    thunkAPI.dispatch(updateCanjeRow(res as ICanje))
                    break
            }

            thunkAPI.dispatch(mostrarAutorizacionesNumber())

            //callback message
            thunkAPI.dispatch(Notifications.warning({ title: 'Autorización rechazada.', autoDismiss: 4}));
            historyHelper.back();
        }
        return response.data;
    }
);
const slice = createSlice({
    name: 'autorizaciones',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarAutorizaciones
        builder.addCase(mostrarAutorizaciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAutorizaciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAutorizaciones.rejected, (state ) => {
            state.status = "idle";
        })

        //--aprobarAutorizacion
        builder.addCase(aprobarAutorizacion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(aprobarAutorizacion.fulfilled, (state,action ) => {
            state.status = "idle";
            const {id,value} = action.payload;
            state.list = state.list?.map(l=>(l.id === id?{...l,autEstado:2,autComentario:value}:l))
        })
        builder.addCase(aprobarAutorizacion.rejected, (state ) => {
            state.status = "idle";
        })
        //--rechazarAutorizacion
        builder.addCase(rechazarAutorizacion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(rechazarAutorizacion.fulfilled, (state,action ) => {
            state.status = "idle";
            const data = action.payload;
            state.list = state.list?.map(l=>(l.id === data.id?data:l))

        })
        builder.addCase(rechazarAutorizacion.rejected, (state ) => {
            state.status = "idle";
        })
    }
});


export default slice.reducer;
