import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getMedicos, addEditMedico,generateUsuarioMedico, getMedicoById, delMedico} from '../../services';
import {IMedicoState, IMedico, IMedicoRes} from "../types/IMedico";
import historyHelper from "../../helpers/history";
const initialState: IMedicoState = {
    status: "idle"
}

export const mostrarMedicos = createAsyncThunk(
    'mostrarMedicos', async (params:object|undefined ) => {
        const response = await getMedicos(params)
        return {data: response.data as IMedicoRes, params: response.config.params}
    }
)
export const mostrarMedicoById = createAsyncThunk(
    'mostrarMedicoById', async (id:number ) => {

        const response = await getMedicoById(id)
        return response.data as IMedico
    }
)

export const agregarEditarMedico = createAsyncThunk(
    'Medicos/agregarEditar', async (Medico: IMedico) => {
        const response = await addEditMedico(Medico);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!Medico.id};
    }
);
export const generarUsuarioMedico = createAsyncThunk(
    'medico/generarUsuario', async (id: number) => {
        const response = await generateUsuarioMedico(id);
        if (response.status === 200 || response.status === 204) {
            historyHelper.push(historyHelper.location.pathname,response.data);
        }

        return {data: response.data};
    }
);
export const eliminarMedico = createAsyncThunk(
    'medico/eliminar', async (id: number) => {
        const response = await delMedico(id);
        // if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarMedicos',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarMedicos
        builder.addCase(mostrarMedicos.pending, (state ) => {
            state.status = "loading";
            state.byId = undefined;
        })
        builder.addCase(mostrarMedicos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarMedicos.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarMedico
        builder.addCase(agregarEditarMedico.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarMedico.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarMedico.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarMedicoById
        builder.addCase(mostrarMedicoById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarMedicoById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarMedicoById.rejected, (state ) => {
            state.status = "idle";
        })

        //--generar Usuario
        builder.addCase(generarUsuarioMedico.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(generarUsuarioMedico.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = {...state.byId, usuarioId:action.payload.data.id}
        })
        builder.addCase(generarUsuarioMedico.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarMedico
        builder.addCase(eliminarMedico.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarMedico.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarMedico.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
