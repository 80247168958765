export const colors = [
    {primary: { main: "#0b109f"}, secondary: { main:"#43c7e7"}},
]


export function getColor(color) {

    let foundColor = colors.find(c => c.primary.main === color);
    if(foundColor) return foundColor;

    return colors[0]
}
