import React from 'react';
import {Box, Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  btn: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 39,
    }
  }
}));
export default function ButtonResponsive({icon,text,onClick,...props}){
  const classes = useStyles();
  return (
    <Button onClick={onClick} {...props} className={classes.btn}>
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>{text}</Box>
      <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>{icon}</Box>
    </Button >
  );
}

