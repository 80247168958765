import React, {useEffect, useState} from 'react';
import {Grid, Theme, useMediaQuery} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CounterItem from "./CounterItem";
import {getDashboard} from "../../services";
import {translateString} from "../../helpers";

const useStyles = makeStyles((theme:Theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
         marginBottom: theme.spacing(1)
        },
    }
}));

type listProps = {
    key?:string,
    value?:string
}

export default function HomeCounter() {
    const classes = useStyles();
    const xsVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('md'));
    const [list, setList] = useState<listProps[]>([]);

    useEffect(()=>{
        getDashboard().then(res=>{
            setList(res.data)
        })
    },[setList])

    return (
        <Grid container spacing={xsVewport?1:2} className={"d-print-none " + classes.root}>
            {list?.map(item=>
                <Grid key={item.key} item xs={6} md={3}>
                    <CounterItem title={translateString(item.key)} subTitle={item.value}/>
                </Grid>
            )}
        </Grid>
    )
}

