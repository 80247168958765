import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getUsuTipo, hasPermissionTo, nullToEmptyString, PERSMISOS, TIPO_USUARIO} from '../../helpers';
import {Box, Button, CircularProgress, Grid, Paper, TextField, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {agregarEditarMedico, generarUsuarioMedico, mostrarMedicoById} from "../../redux/reducers/medicosReducers";
import {IMedico} from "../../redux/types/IMedico";
import SelectUsoMultiplesMul from "../../components/selects/SelectUsoMultiplesMul";
import {PhoneTextField} from "../../components/CustomTextField";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";
import TopAppBar from "../../components/TopAppBar";
import {getUsuarioById} from "../../services";
import CrearUsuario from "../Usuarios/CrearUsuario";
import ArticuloDetalles from "../Medicos/ArticuloDetalles";
import SendSMS from "../../components/SendSMS";
import SelectSearchUsuario from "../../components/selects/SelectSearchUsuario";
import SelectSearchCentroMedico from "../../components/selects/SelectSearchCentroMedico";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:IMedico = {
    medNombre: "",
    medDireccion: "",
    medCodigo:"",
    medNumLicencia: "",
    medTelefono: "",
    medTelefono2: "",
    medEmail:"",
    visitador: "",
    visitadorId: undefined,
    centroMedicoId: undefined,
    centroMedico: "",
    especialidades: [],
    articulos:[]
}
function CrearMedico(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlMedId} = useParams()
    const {pathname}:{state:any,pathname:string} = useLocation();
    const {byId:medico,status} = useAppSelector(state => state.medicos);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlMedId) Dispatch(mostrarMedicoById(Number(urlMedId)));
    },[urlMedId,Dispatch])
    useEffect(()=>{
        if(!!urlMedId && medico?.id === parseInt(urlMedId)) {
            setState({...initialState, ...nullToEmptyString(medico)});
        }else{
            setState(initialState)
        }
    },[urlMedId,medico])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = () =>{
        Dispatch(agregarEditarMedico(state));
    }
    const verUsuario = (id:number) => {
        getUsuarioById(id).then(({data})=>{
            navigate(pathname, {state: {usuario:data}})
        })
    }
    return(
        <Fragment>
            <TopAppBar/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}

                    <Box  sx={{marginBottom:1}}>
                        {!!state.id &&
                            <Box bgcolor={"#ececec"}>
                                {(getUsuTipo() === TIPO_USUARIO.admin || hasPermissionTo(PERSMISOS.seguridad)) &&
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            if (state.usuarioId) {
                                                verUsuario(state.usuarioId)
                                            } else if (hasPermissionTo(PERSMISOS.medicosGenerarUsuario)) {
                                                Dispatch(generarUsuarioMedico(state.id!))
                                            }
                                        }}
                                    >
                                        {state.usuarioId ? <>Ver</> : <>Crear</>} {Strings.usuario}
                                    </Button>
                                }


                                <Button
                                    color="primary"
                                    onClick={()=> {
                                        navigate(pathname,{state:{sendSMS:{medicoId: state.id , medico:state.medNombre}}})
                                    }}
                                >
                                    Enviar notificación
                                </Button>
                            </Box>
                        }
                    </Box>
                    {!!state.id &&
                        <SendSMS/>
                    }


                    <Paper className={classes.paper}>
                        <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                            <input autoComplete="false" name="hidden" type="text" style={{display:"none"}} />
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={5} lg={3}>
                                        <TextValidatorCustom
                                            name={"medNombre"}
                                            label={Strings.nombre}
                                            value={state.medNombre}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={3}>
                                        <TextValidatorCustom
                                            type={"number"}
                                            name={"medNumLicencia"}
                                            label={Strings.licencia}
                                            value={state.medNumLicencia}
                                            onChange={handleChange}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} lg={3}>
                                        <PhoneTextField
                                            name={"medTelefono"}
                                            label={Strings.telefono}
                                            value={state.medTelefono}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={3}>
                                        <TextField
                                            name={"medTelefono2"}
                                            label={Strings.celular}
                                            value={state.medTelefono2}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={3}>
                                        <TextField
                                            name={"medEmail"}
                                            label={Strings.email}
                                            value={state.medEmail}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={2}>
                                        <TextField
                                            name={"medCodigo"}
                                            label={Strings.codigo}
                                            value={state.medCodigo}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <SelectUsoMultiplesMul
                                            label={Strings.especialidad}
                                            values={state.especialidades??[]}
                                            oldValue={medico?.especialidades??[]}
                                            onChange={(v)=>setState(prevState => ({
                                                ...prevState,
                                                especialidades:v
                                            }))}
                                            group={"Especialidades"}
                                            defaultObj={{medicoId:urlMedId}}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <SelectSearchUsuario
                                            label={Strings.visitador}
                                            tipo={TIPO_USUARIO.visitador}
                                            value={{
                                                nombre:state.visitador ?? "",
                                                id: (state.visitadorId ?? "").toString(),
                                            }}
                                            onChange={(v)=>setState({
                                                ...state,
                                                visitadorId: v.id?+v.id:undefined,
                                                visitador: v.nombre
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <SelectSearchCentroMedico
                                            required
                                            value={{
                                                centroMedicoId: (state.centroMedicoId ?? "").toString(),
                                                centroMedico: state.centroMedico ?? ""
                                            }}
                                            onChange={(v)=>setState(prevState => ({
                                                ...prevState,
                                                centroMedico:v.centroMedico,
                                                centroMedicoId:v.centroMedicoId?parseInt(v.centroMedicoId):undefined
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <TextField
                                            name={"medDireccion"}
                                            label={Strings.direccion}
                                            value={state.medDireccion}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                        </ValidatorFormCustom>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ArticuloDetalles medicoState={state} onChange={(articulos)=>setState({...state, articulos})}/>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                        <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                        <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{Strings.guardar}</span>
                            )}
                        </Button>
                    </Box>
                </div>
            <br/>
            </div>

            {state.id && (getUsuTipo() === TIPO_USUARIO.admin || hasPermissionTo(PERSMISOS.seguridad)) &&
                <CrearUsuario/>
            }


        </Fragment>
    );

}
export default CrearMedico;
