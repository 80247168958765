import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getCentrosMedicos, addEditCentroMedico, getCentroMedicoById, delCentroMedico} from '../../services';
import {ICentroMedicoState, ICentroMedico, ICentroMedicoRes} from "../types/ICentroMedico";
import historyHelper from "../../helpers/history";
const initialState: ICentroMedicoState = {
    status: "idle"
}

export const mostrarCentrosMedicos = createAsyncThunk(
    'mostrarCentrosMedicos', async (params:object|undefined ) => {
        const response = await getCentrosMedicos(params)
        return {data: response.data as ICentroMedicoRes, params: response.config.params}
    }
)
export const mostrarCentroMedicoById = createAsyncThunk(
    'mostrarCentroMedicoById', async (id:number ) => {

        const response = await getCentroMedicoById(id)
        return response.data as ICentroMedico
    }
)

export const agregarEditarCentroMedico = createAsyncThunk(
    'CentrosMedicos/agregarEditar', async (CentroMedico: ICentroMedico) => {
        const response = await addEditCentroMedico(CentroMedico);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!CentroMedico.id};
    }
);
export const eliminarCentroMedico = createAsyncThunk(
    'centroMedico/eliminar', async (id: number) => {
        const response = await delCentroMedico(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarCentrosMedicos',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarCentrosMedicos
        builder.addCase(mostrarCentrosMedicos.pending, (state ) => {
            state.status = "loading";
            state.byId = undefined;
        })
        builder.addCase(mostrarCentrosMedicos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarCentrosMedicos.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarCentroMedico
        builder.addCase(agregarEditarCentroMedico.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarCentroMedico.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarCentroMedico.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarCentroMedicoById
        builder.addCase(mostrarCentroMedicoById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCentroMedicoById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarCentroMedicoById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarCentroMedico
        builder.addCase(eliminarCentroMedico.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarCentroMedico.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarCentroMedico.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export default slice.reducer;
