import React, {useEffect, useState} from 'react';
import { mostrarUsosMultiples } from '../../redux/reducers/usosMultiplesReducers';
import {FormControl, InputLabel, Select, SelectChangeEvent,MenuItem,Checkbox,ListItemText} from "@mui/material";
import _ from "lodash";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IUsoMultiple} from "../../redux/types/IUsosMultiples";
import {IEspecialidad} from "../../redux/types/IMedico";

type keyValue = { key?:number,value:string }
interface Iprops{
    emptyOption?: keyValue,
    required?:boolean,
    disabled?:boolean,
    values:IEspecialidad[],
    oldValue:IEspecialidad[],
    label:string,
    onChange: (val:IEspecialidad[])=>void
    group:string,
    defaultObj?:any;
}

function SelectUsoMultiplesMul({emptyOption,required,disabled,values=[],label="", onChange, group,defaultObj,oldValue}:Iprops) {

    const {status,list={}} = useAppSelector(state => state.usosMultiples);
    const Dispatch = useAppDispatch();
    const [selected, setSelected] = useState<IEspecialidad[]>([])

    //cargar usos multiples
    useEffect(() => {
        if(!Object.keys(list).length ){
            Dispatch(mostrarUsosMultiples())
        }
    }, [list, Dispatch]);


    useEffect(() => {
        setSelected(values)
    }, [values]);



    const lista = (list as any)[group] ? (list as any)[group] : [];


    var options:keyValue[] = lista.map((opc:IUsoMultiple)=>({key:opc.id,value: (Strings as any)[_.camelCase(opc.usoDescripcion)] || opc.usoDescripcion}))
   // if(emptyOption) options.unshift(emptyOption)


    const handleChange = (e: SelectChangeEvent<(number | undefined)[]>)=>{
        const {value} = e.target
        let selectedObjs:IEspecialidad[] = [];

        if (typeof value !== "string") {
            value.forEach((v) => {
                let selectedOption = options?.find(l=>l.key===v);
                let oldSelected = oldValue?.find(item=>item.especialidadId === v);
                if(selectedOption){
                    let toAdd = {
                        ...defaultObj,
                        especialidadId: selectedOption.key,
                        especialidad: selectedOption.value,
                        accion:1
                    }
                    if(oldSelected && oldSelected.accion === 0){
                        toAdd.accion = 0;
                    }
                    selectedObjs.push(toAdd)
                }
            })

            oldValue?.forEach(v=>{
                if(!value.includes(v.especialidadId)){
                    selectedObjs.push({...v, accion: 3})
                }
            })

        }



       // let selected = options?.find(l=>l.key===parseInt(value));
        onChange(selectedObjs)
    }
    return (
        <FormControl fullWidth disabled={disabled} required={required}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple={true}
                value={selected.length? selected.filter(s=>s.accion!==3).map(v=>v.especialidadId):[]}
                renderValue={(selected) => selected.map(s=>options.find(o=>o.key===s)?.value).join(', ')}
                onChange={handleChange}
            >
                <MenuItem aria-label="None" value="" >{status==="loading"?Strings.cargando+"...":Strings.seleccione}</MenuItem>
                {/*{value && !options.find(l=>l.key?.toString()===value) &&*/}
                {/*    <option value={value.id.toString()} >{value.name}</option>*/}
                {/*}*/}
                {options.map(item=>
                    <MenuItem value={item.key} key={item.key}>
                        <Checkbox checked={selected.filter(s=>s.accion!==3).map(v=>v.especialidadId).indexOf(item.key) > -1} />
                        <ListItemText primary={item.value} />
                    </MenuItem>
                )}
            </Select>
        </FormControl>
        // <FormControl fullWidth required={required} margin={margin} >
        //     <InputLabel shrink>{label}</InputLabel>
        //     <SelectSmart
        //         value={value}
        //         onChange={onChange}
        //         name={name}
        //         loading={status === "loading"}
        //         required={required}
        //         disabled={disabled}
        //         opciones={options}
        //     />
        // </FormControl>
    );
}

export default SelectUsoMultiplesMul
