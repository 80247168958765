import {Box, Button, CircularProgress, Grid, MenuItem, Paper, TextField, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Strings from "../../assets/strings";
import {LoadingProgress} from "../../components";
import {CedulaTextField, DateInput, PhoneTextField} from "../../components/CustomTextField";
import SelectPaiProMun from "../../components/selects/SelectPaiProMun";
import {getUsuTipo, nullToEmptyString, removeSpecialCaracter, TIPO_USUARIO} from '../../helpers';
import {SEXO, TIPO_DOCUMENTO} from "../../helpers/CONSTANTES";
import {
    errorMessagesC,
    SelectValidatorCustom,
    TextValidatorCustom,
    ValidatorFormCustom
} from "../../helpers/form-validator";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {agregarEditarPaciente, mostrarPacienteById} from "../../redux/reducers/pacientesReducers";
import {IPacienteDetalle} from "../../redux/types/IPacientes";
import TopAppBar from "../../components/TopAppBar";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(2)}`,
        marginBottom:theme.spacing(1),
        "& h6":{
            marginTop:-10,
            marginBottom:10
        }
    }
}));

let initialState:IPacienteDetalle = {
    pacNombres: "",
    pacApellidos: "",
    pacTipoDocumento: 1,
    pacTipoDocumentoString: "",
    pacDocIdentidad: "",
    pacEmail: "",
    pacTelefono: "",
    pacTelefono2: "",
    paisId: 0,
    provinciaId: 0,
    municipioId: 0,
    sector:"",
    sectorId:undefined,
    pacDireccion: "",
    pacComentario: "",
    pacFechaNacimiento: "",
    pacSexo: "",
    usuarioId: 0,
    pacInactivo: true,
}

export default function CrearPaciente(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlpacId} = useParams()
    const {byId:paciente,status} = useAppSelector(state => state.pacientes);

    const [state, setState] = useState(initialState);


    useEffect(()=>{
        if(urlpacId) Dispatch(mostrarPacienteById(Number(urlpacId)));
    },[urlpacId,Dispatch])
    useEffect(()=>{
        if(!!urlpacId && paciente?.id === parseInt(urlpacId)) {
            setState({...initialState, ...nullToEmptyString(paciente)});
        }else{
            setState(initialState)
        }
    },[urlpacId,paciente])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        // if(name === "pacDocIdentidad"){
        //     return setState({...state,pacDocIdentidad: value.replace(/\D/g,'') });
        //
        // }
        return setState({...state,[name]:  typeof value === 'string'? removeSpecialCaracter(value):value});
    }

    const submit = () =>{
        let data = {...state}
        delete data.transacciones;

        Dispatch(agregarEditarPaciente(data));
    }


    return(
        <Fragment>
            <TopAppBar/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}

                    <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                        <Paper  className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}><TextValidatorCustom name={"pacNombres"} label={Strings.nombre} value={state.pacNombres} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                                <Grid item xs={6} sm={3}><TextValidatorCustom name={"pacApellidos"} label={Strings.apellidos} value={state.pacApellidos} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                                <Grid item xs={6} sm={4}>
                                    <Box display={"flex"}>
                                        <Box sx={{width: 170, mr: "-1px"}}>
                                            <SelectValidatorCustom
                                                disabled={getUsuTipo() !== TIPO_USUARIO.admin}
                                                value={state.pacTipoDocumento}
                                                validators={!!state.pacDocIdentidad?['required']:[]}
                                                name="pacTipoDocumento"
                                                errorMessages={errorMessagesC(['required'])}
                                                label={Strings.tipo}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                                                {Object.keys(TIPO_DOCUMENTO).map((pro,i)=>
                                                    <MenuItem key={i} value={(TIPO_DOCUMENTO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                                )}
                                            </SelectValidatorCustom>
                                        </Box>
                                        <Box width={"100%"}>
                                            <CedulaTextField noMasked={state.pacTipoDocumento!==1} name={"pacDocIdentidad"} label={Strings.noIdentificacion}
                                                             disabled={getUsuTipo() !== TIPO_USUARIO.admin}
                                                             value={state.pacDocIdentidad}
                                                             onChange={handleChange} inputProps={{maxLength: 20}}
                                                             validators={(!!state.pacFechaNacimiento && moment().diff(state.pacFechaNacimiento, 'years') >= 18)? ["required"]:[]}
                                                             fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <SelectValidatorCustom
                                        value={state.pacSexo}
                                        validators={['required']}
                                        name="pacSexo"
                                        errorMessages={errorMessagesC(['required'])}
                                        label={Strings.sexo}
                                        onChange={handleChange}
                                    >
                                        {Object.keys(SEXO).map((pro,i)=>
                                            <MenuItem key={i} value={(SEXO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                        )}
                                    </SelectValidatorCustom>
                                </Grid>
                                <Grid item xs={6} sm={3}><TextValidatorCustom type={"email"}  name={"pacEmail"}  validators={['isEmail']} label={Strings.email} value={state.pacEmail} onChange={handleChange} inputProps={{maxLength: 30}}  fullWidth /></Grid>
                                <Grid item xs={6} sm={3}><PhoneTextField name={"pacTelefono"} validators={['required']} label={Strings.telefono} value={state.pacTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                                <Grid item xs={6} sm={3}><PhoneTextField name={"pacTelefono2"} label={Strings.celular} value={state.pacTelefono2} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                                <Grid item xs={6} sm={3}>
                                    <DateInput
                                        maxDate={moment().add(-18,"years")}
                                        name={"pacFechaNacimiento"} label={Strings.fechaNacimiento}
                                        value={state.pacFechaNacimiento} onChange={handleChange} validators={['required']} fullWidth />
                                </Grid>
                                <SelectPaiProMun required value={{
                                    pais:state.pais,
                                    paisId:state.paisId,
                                    provincia:state.provincia,
                                    provinciaId:state.provinciaId,
                                    municipio:state.municipio,
                                    municipioId:state.municipioId,
                                    sector:state.sector,
                                    sectorId:state.sectorId,
                                }} onChange={(val)=>setState(prev=>({...prev,...val}))} />
                                <Grid item xs={12} sm={6}><TextValidatorCustom name={"pacDireccion"} validators={['required']} label={Strings.direccion} value={state.pacDireccion} onChange={handleChange} inputProps={{maxLength: 100}}  fullWidth /></Grid>
                                <Grid item xs={12} sm={6}><TextField name={"pacComentario"} label={Strings.comentario} value={state.pacComentario} onChange={handleChange} inputProps={{maxLength: 200}}  fullWidth /></Grid>

                            </Grid>
                        </Paper>

                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{!!state.id ? Strings.actualizar : Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>
                    </ValidatorFormCustom>

                </div>
            <br/>
            </div>
        </Fragment>
    );

}
