import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Grid, MenuItem, Theme, Typography} from '@mui/material';
import Strings from "../../../assets/strings";

import makeStyles from '@mui/styles/makeStyles';
import {useNavigate, useParams} from "react-router-dom";
import Notifications from 'react-notification-system-redux';
import {useAppSelector} from '../../../redux/hooks';
import {ArrowBack} from '@mui/icons-material';
import {IRecetaRegistro} from "../../../redux/types/IReceta";
import {errorToNotifObj, removeSpecialCaracter} from "../../../helpers";
import {
    errorMessagesC,
    SelectValidatorCustom,
    TextValidatorCustom,
    ValidatorFormCustom
} from "../../../helpers/form-validator";
import {TIPO_DOCUMENTO} from "../../../helpers/CONSTANTES";
import {CedulaTextField, PhoneTextField} from "../../../components/CustomTextField";
import SelectSearchCentroMedico from "../../../components/selects/SelectSearchCentroMedico";
import AddAdjuntos from "../../../components/AddAdjuntos";
import SelectList from "../../../components/selects/SelectList";
import {IEspecialidad, IMedico} from "../../../redux/types/IMedico";
import {addRecetaPublic, getMedicoByCode, getMedicoById, getPacienteByCedula} from "../../../services";
import {IPaciente} from "../../../redux/types/IPacientes";
import {LoadingProgress} from '../../../components';
import {store} from "../../../redux/store";
import SelectSearchMedico from "../../../components/selects/SelectSearchMedico";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        boxShadow: "#5d5d5d 0px 0px 12px",
        maxWidth: 615,
        margin: "-110px auto 30px auto",
        boxSizing: "border-box",
        background:"#fff",
        [theme.breakpoints.down('md')]: {
            marginTop:-71,
            "& h1":{
                marginTop: "-64px !important",
                color: "white  !important",
                marginBottom: "39px !important",
            }
        },
        "& #html5qr-code-full-region>div:first-child":{
            display:"none",
        },
        "& .contAdjuntos":{
            "& .img, & .pdf":{
                width: 50,
                height: 50,
                "& .delete":{
                    width: 20,
                    height: 20,
                }
            },

        }
    },
    topHead: {
        position:"relative",
        height: "103px",
        padding: theme.spacing(2),
        borderBottom: "1px solid #ddd",
        "& .info":{
            display: 'flex',
            alignItems: "center",
        },
        "& h1":{
            color:theme.palette.secondary.main,
            fontWeight: "normal",
            margin: "0",
            marginTop: 36,
            fontSize: "1.1rem",
            minWidth: 254,
            textAlign: "left",
            textTransform: "uppercase",

        },
        "& h2":{
            color:theme.palette.primary.main,
            margin: "0",
            fontSize: "1rem",
            fontWeight: 100,
            [theme.breakpoints.down('md')]: {
                paddingTop: 20,
                fontSize: "1.1rem",
                textAlign: "center",
            }
        },
        "& .logo":{
            marginLeft: 10,
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: 99,
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".9rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            height: "auto",
            "& .info":{
                flexDirection: "column-reverse",
                textAlign: "center",
            },
            "& h1":{
                textAlign: "center",
            }
        }
    },
    lang:{
        textAlign: "center",
        marginBottom: theme.spacing(3),
        "& select":{
            padding: 5,
            fontSize: "1em",
        }
    },
    title:{
        borderBottom: "1px solid "+theme.palette.grey[500],
        marginBottom: theme.spacing(2),
        fontSize: 15,
        fontWeight: 500,
        padding: "6px 0",
    }
}));
const style = {
    NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
            margin: '10px 5px 2px 1px',
            borderRadius: '0px',
            boxShadow:"none",
        },
    },
    Dismiss: {
        DefaultStyle: {
            backgroundColor: "none",
            color: '#bbbbbb',
        },
    }
};
const initialState:IRecetaRegistro = {

    pacNombres: "",
    pacApellidos: "",
    pacTipoDocumento: 1,
    pacDocIdentidad: "",
    pacEmail: "",
    pacTelefono: "",
    medicoId: undefined,
    medicoEspecialidadId: undefined,
    centroMedico: "",
    centroMedicoCiudad: "",
    adjuntos: [],
}
function PublicRecetaForm(){
    const classes = useStyles();
    let navigate =useNavigate();
    let { medCodigo } = useParams()


    const notificaciones = useAppSelector(state=>state.notifications);
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [especialidad, setEspecialidad] = useState<IEspecialidad[]>([]);

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: typeof value === 'string'? removeSpecialCaracter(value):value});
    }

    //setear especialidades por el codigo del medico
    useEffect(()=>{
        if(medCodigo){
            setLoading(true)
            getMedicoByCode(medCodigo).then(({data})=>{
                let medico = data as IMedico;
                setState(prev => ({...prev,
                    medicoId:medico.id,
                    medico:medico.medNombre,
                    medicoEspecialidadId: medico.especialidades?.length === 1? medico.especialidades[0].especialidadId:undefined
                }))
                setEspecialidad(medico.especialidades??[])
            }).finally(()=>setLoading(false))
        }else{
            setEspecialidad([])
        }
    },[medCodigo,setEspecialidad,setState])

    //setear especialidades por el ID del medico
    useEffect(()=>{
        if(!medCodigo && state.medicoId){
            setLoading(true)
            getMedicoById(state.medicoId).then(({data})=>{
                let medico = data as IMedico;
                setState(prev => ({...prev,
                    medicoEspecialidadId: medico.especialidades?.length === 1? medico.especialidades[0].especialidadId:undefined
                }))
                setEspecialidad(medico.especialidades??[])
            }).finally(()=>setLoading(false))
        }
    },[medCodigo,setEspecialidad,state.medicoId, setState])

    const buscarPaciente = () =>{
       if(state.pacTipoDocumento === TIPO_DOCUMENTO.cedula && state.pacDocIdentidad){
           setLoading(true)
           getPacienteByCedula(state.pacDocIdentidad).then(({data})=>{
               let res = data as IPaciente;
               if(res.id){
                   const {pacApellidos, pacNombres, pacEmail, pacTelefono} = res;
                   setState(prevState => ({...prevState, pacApellidos, pacNombres, pacEmail, pacTelefono}))
               }
           }).catch(()=>{

               //setState(prevState => ({...prevState, pacApellidos:"", pacNombres:"", pacEmail:"", pacTelefono:""}))

           }).finally(()=>setLoading(false))
       }
    }
    const submit = () =>{
        setLoading(true)
        addRecetaPublic(state).then(()=>{
            store.dispatch(Notifications.success({
                title:"Registrado con éxito",
                message:"Le hemos enviado más información de su registro por correo electrónico.",
                position: 'tc',
                autoDismiss: 10,
                action: {
                    label: 'Ir al Inicio',
                    callback: () => navigate("/login")
                }
            }))
            setState(initialState)
        }).catch(error=>{
            store.dispatch(Notifications.error(errorToNotifObj(error.response?.data)));
        }).finally(()=>setLoading(false))
    }
    return(
        <>
            <Box bgcolor="primary.main" width={"100%"} height={138} />
            <div className={classes.root}>
                <>
                    <div className={classes.topHead}>
                        <Button onClick={()=>navigate("/login")}
                                sx={{
                                    position: "absolute",
                                    top: 5,
                                    left: 9,
                                }}
                                startIcon={<ArrowBack/>}>Iniciar sesión</Button>
                        <h1>En ProLeal estamos a su servicio.</h1>
                        <h2>Digite los datos en el formulario entrante para tener acceso a
                            descuentos en nuestras farmacias afiliadas.</h2>
                    </div>
                </>

                    <Box sx={{p:2}}>
                        {loading && <LoadingProgress />}

                        <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                            <Typography className={classes.title}>Datos del Médico</Typography>
                            <Grid container spacing={2} >


                                <Grid item xs={12} sm={6} sx={{mb:2}}>
                                    <SelectSearchMedico
                                        required
                                        disabled={!!medCodigo}
                                        value={{
                                            medicoId: (state.medicoId ?? "").toString(),
                                            medico: state.medico ?? ""
                                        }}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            medico:v.medico,
                                            medicoId:v.medicoId?parseInt(v.medicoId):undefined
                                        }))}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{mb:2}}>
                                    <SelectList
                                        required
                                        loading={loading}
                                        label={Strings.especialidad}
                                        list={especialidad?.map(e=>({id:e.especialidadId!,name:e.especialidad!})) ?? []}
                                        disabled={!especialidad.length}
                                        value={{
                                            id: state.medicoEspecialidadId ?? undefined,
                                            name: state.medicoEspecialidad ?? ""
                                        }}
                                        onChange={(v)=>
                                            setState(pre=>({...pre,
                                                medicoEspecialidad:v.name,
                                                medicoEspecialidadId:v.id,
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{mb:2}}>
                                    <SelectSearchCentroMedico
                                        required
                                        value={{
                                            centroMedicoId: (state.centroMedicoId ?? "").toString(),
                                            centroMedico: state.centroMedico ?? ""
                                        }}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            centroMedico:v.centroMedico,
                                            centroMedicoId:v.centroMedicoId?parseInt(v.centroMedicoId):undefined
                                        }))}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextValidatorCustom name={"centroMedicoCiudad"} label={"Ciudad"} value={state.centroMedicoCiudad} onChange={handleChange}   fullWidth />
                                </Grid>

                            </Grid>

                            <Typography className={classes.title}>Datos del Paciente</Typography>
                            <Grid container spacing={2} >

                                <Grid item xs={6}>
                                    <SelectValidatorCustom
                                        value={state.pacTipoDocumento}
                                        validators={!!state.pacDocIdentidad?['required']:[]}
                                        name="pacTipoDocumento"
                                        errorMessages={errorMessagesC(['required'])}
                                        label={Strings.tipo}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                                        {Object.keys(TIPO_DOCUMENTO).map((pro,i)=>
                                            <MenuItem key={i} value={(TIPO_DOCUMENTO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                        )}
                                    </SelectValidatorCustom>
                                </Grid>
                                <Grid item xs={6}>
                                    <CedulaTextField
                                        onBlur={buscarPaciente}
                                        onKeyDown={(e:any)=>{
                                            e.stopPropagation();
                                            if(e.key === 'Enter'){
                                                buscarPaciente()
                                            }
                                        }}
                                        noMasked={state.pacTipoDocumento!==1} name={"pacDocIdentidad"} label={Strings.cedula} value={state.pacDocIdentidad} onChange={handleChange} inputProps={{maxLength: 20}} validators={['required']}  fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidatorCustom name={"pacNombres"} label={Strings.nombres} value={state.pacNombres} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidatorCustom name={"pacApellidos"} label={Strings.apellidos} value={state.pacApellidos} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth />
                                </Grid>
                                <Grid item xs={6}><TextValidatorCustom type={"email"} name={"pacEmail"} label={Strings.email} value={state.pacEmail} onChange={handleChange} inputProps={{maxLength: 30}} validators={['isEmail']}  fullWidth /></Grid>
                                <Grid item xs={6}><PhoneTextField name={"pacTelefono"} label={Strings.telefono} value={state.pacTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>

                            </Grid>
                            <br/>
                            <AddAdjuntos
                                label={"Adjuntar foto de la receta."}
                                adjuntos={state.adjuntos?.map(adj=>({
                                    arcPath:adj.url,
                                    arcBase64:adj.base64,
                                    arcExtension: (adj.url || adj.base64)?.indexOf("pdf") !== -1?"pdf":"jpg"
                                })) ?? []}
                                onChange={(val)=>{
                                    setState(prevState => ({...prevState, adjuntos:val.map(v=>({
                                            url: v.arcPath,
                                            base64: v.arcBase64,
                                        }))}))}}
                            />
                            <Box  mt={2}>
                                {/*display={"flex"} justifyContent={"flex-end"}*/}
                                {/*<Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>*/}
                                <Button disabled={loading} fullWidth type="submit" variant={"contained"} form="form1" value="Submit">
                                    {loading? (
                                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                    ):(
                                        <span>{Strings.enviar}</span>
                                    )}
                                </Button>
                            </Box>


                        </ValidatorFormCustom>
                    </Box>

            </div>
            <Notifications notifications={notificaciones} style={style} />
        </>
    );
}

export default PublicRecetaForm;
