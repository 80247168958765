import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Strings from "../assets/strings";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme:Theme) => ({
    emptyCont:{
        margin:"0 auto",
        maxWidth:800,
        padding:theme.spacing(1),
        paddingTop:"7%",
        textAlign:"center",
        "& p":{
            marginTop:0,
        }
    }
}));
type Iprops = {
    Img?:React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>,
    title?:string,
    children?:any
}

export default function Empty({Img,title =Strings.aunNoTienes+" "+Strings.registros,children=""}:Iprops) {
    const classes = useStyles();
    return (
        <div className={classes.emptyCont}>
            {Img && <Img width={140} height={140}/>}
            <p>{title}</p>
            {!!children && children}
        </div>
    );
}